import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ModelError_Item } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { camelCase } from 'lodash';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { FormStateChangeProps } from '~formsShared';
import { CompanyBasic, useCompany } from '~hooks/useCompany';
import { CompanyShare } from '~hooks/useCompanyShares';
import { BasicEquipment, Equipment } from '~hooks/useEquipment';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import { EquipmentHint } from '~pages/Settings/Administration/EquipmentManagement/EquipmentForm';
import { externalEquipmentFormValidationSchema } from '~pages/Settings/Administration/EquipmentManagement/equipmentFormValidationSchema';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

export type ExternalEquipmentDTO = yup.InferType<
  typeof externalEquipmentFormValidationSchema
>;

export interface ExternalEquipmentFormHandlers {
  submit: (callBack: (data: ExternalEquipmentDTO) => void) => void;
  resetForm: (callBack?: () => void) => void;
}

interface ExternalEquipmentFormProps {
  defaultEquipment: Nullable<Equipment | BasicEquipment>;
  companyShares: Nullable<Array<CompanyShare>>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onFormDirty?: (isDirty: boolean) => void;
  selectedCompanyId: Nullable<string>;
  errors?: ModelError_Item[];
}

const ExternalEquipmentForm = forwardRef(function ExternalEquipmentForm(
  {
    defaultEquipment,
    companyShares,
    onFormStateChange,
    selectedCompanyId,
    errors: errorsProp,
  }: ExternalEquipmentFormProps,
  ref: Ref<ExternalEquipmentFormHandlers>,
) {
  const [connectedCompanies, setConnectedCompanies] = useState<CompanyBasic[]>([]);
  const { userStore } = useStores();
  const {
    getAllConnectedCompaniesByCompanyId,
    getAllConnectedCompaniesByCompanyIdTypeahead,
  } = useCompany();
  const isSharedEquipment = !!defaultEquipment?.companyShare?.id;
  const selectedCompanyOwnsEquipment =
    (defaultEquipment as Equipment)?.company?.id === selectedCompanyId;
  const [isShowingAdvancedSettings, setIsShowingAdvancedSettings] = useState(false);

  const defaultValues = {
    id: defaultEquipment?.id || '',
    name: defaultEquipment?.name || '',
    externalId: defaultEquipment?.companyShare?.externalId || '',
    ticketMatchId: defaultEquipment?.companyShare?.ticketMatchId || '',
    companyShares: companyShares?.length
      ? companyShares.map((companyShare) => {
          return {
            id: companyShare.id,
            receiverCompany: {
              id: companyShare.receiverCompany.id,
              legalName: companyShare.receiverCompany.legalName,
            },
            dispatchable: {
              id: companyShare.dispatchable ? 'dispatchable' : 'view_only',
              name: companyShare.dispatchable
                ? $t('common.dispatchable')
                : $t('common.view_only'),
            },
            companyShareableId: companyShare.companyShareable.id,
          };
        })
      : [],
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(externalEquipmentFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const sharedEquipmentData = [
    {
      label: $t('administration.equipment.owner'),
      value: defaultEquipment?.companyShare?.senderCompany?.legalName,
    },
    {
      label: $t('administration.equipment.owner_truck_number_name'),
      value: defaultEquipment?.name,
    },
    {
      label: $t('administration.equipment.ext_id'),
      value: defaultEquipment?.externalId,
    },
    {
      label: $t('administration.equipment.type'),
      value:
        (defaultEquipment as Equipment)?.type?.name ||
        (defaultEquipment as BasicEquipment)?.equipmentType?.name,
    },
    {
      label: $t('administration.equipment.license_plate'),
      value: defaultEquipment?.licenseNumber,
    },
    {
      label: $t('administration.equipment.your_access_level'),
      value: defaultEquipment?.companyShare?.dispatchable
        ? $t('common.dispatchable')
        : $t('administration.equipment.view_only'),
    },
  ];

  // Set errors from props
  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const field = camelCase(error.field) as keyof ReturnType<typeof setError>;
        // Replace external ID with Equipment ID
        const message = error.message.replace('external', 'Equipment');

        if (field && message) {
          setError(field, { type: 'manual', message });
        }
      }
    }
  }, [errorsProp]);

  // Update default values once async companyShares are loaded
  useEffect(() => {
    reset(defaultValues);
  }, [companyShares]);

  const {
    fields: companySharesFields,
    append: appendCompanyShares,
    remove: removeCompanyShares,
  } = useFieldArray({
    control,
    name: 'companyShares',
  });

  useImperativeHandle(ref, () => ({
    submit(callback) {
      handleSubmit((data) => callback(data as ExternalEquipmentDTO))();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    if (selectedCompanyId || userStore?.userCompany?.id) {
      getAllConnectedCompaniesByCompanyId({
        companyId: selectedCompanyId ?? userStore.userCompany.id,
        callback: setConnectedCompanies,
      });
    }
  }, [userStore?.userCompany?.id, selectedCompanyId]);

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  return (
    <form>
      <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%', flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
            }}
          >
            <TextFormField
              control={control}
              errors={errors}
              name="externalId"
              label={`${$t('form_fields.truck_number_name')}`}
              isRequired={false}
            />
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box>
          <PseudoLink
            title={
              !isShowingAdvancedSettings
                ? $t('administration.equipment.advanced_settings')
                : $t('administration.equipment.hide_advanced_settings')
            }
            action={() => setIsShowingAdvancedSettings(!isShowingAdvancedSettings)}
            sx={{
              display: 'inline-flex',
            }}
          />
        </Box>

        <Collapse in={isShowingAdvancedSettings}>
          <Box
            sx={{ mt: 3, mb: 2, display: 'flex', flexDirection: 'column', width: '50%' }}
          >
            <TextFormField
              control={control}
              errors={errors}
              name="ticketMatchId"
              label={`${$t('form_fields.ticket_match_number')}`}
              isRequired={false}
            />
            <EquipmentHint>
              {$t('administration.equipment.ticket_match_number_hint')}
            </EquipmentHint>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box>
            <Typography
              sx={{ mt: 2, fontSize: '16px', fontWeight: 600 }}
            >{`${$t('administration.equipment.truck_details')}`}</Typography>
            <Box sx={{ mt: 2 }}>
              {sharedEquipmentData.map((item) => {
                return (
                  <Box
                    key={item.label}
                    sx={{
                      mt: 0.5,
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                    }}
                  >
                    <Typography variant={'body1'} sx={{ fontWeight: 600 }}>
                      {item.label}
                    </Typography>
                    <Typography variant={'body1'}>{item.value}</Typography>
                  </Box>
                );
              })}
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Company Shares */}
            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
              <Box sx={{ mb: 1.5 }}>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 600 }}
                >{`${$t('administration.equipment.share_with_connected_companies')}`}</Typography>
                <EquipmentHint
                  sx={{ mt: 0.5 }}
                >{`${$t('administration.equipment.share_with_connected_companies_desc')}`}</EquipmentHint>
              </Box>

              {companySharesFields.map((item, index) => {
                return (
                  <Box key={item.id} display={'flex'} sx={{ width: '100%', mt: 2 }}>
                    <Box sx={{ width: '50%', mr: 2 }}>
                      <AutocompleteAsyncFormField
                        control={control}
                        errors={errors}
                        name={`companyShares[${index}].receiverCompany`}
                        getValue={(item) => item.id}
                        getLabel={(item) => item.legalName}
                        asyncCallback={getAllConnectedCompaniesByCompanyIdTypeahead}
                        extraRequestOptions={{
                          companyId: selectedCompanyId ?? userStore.userCompany.id ?? '',
                        }}
                        label={`${$t('form_fields.share_to_company')}`}
                        disabled={
                          !!(
                            defaultValues.companyShares.length && item.receiverCompany.id
                          )
                        } // Disable existing company shares
                      />
                    </Box>
                    <Box sx={{ width: '50%' }}>
                      <AutocompleteFormField
                        control={control}
                        errors={errors}
                        name={`companyShares[${index}].dispatchable`}
                        list={[
                          {
                            id: 'dispatchable',
                            name: $t('common.dispatchable'),
                          },
                          {
                            id: 'view_only',
                            name: $t('common.view_only'),
                          },
                        ]}
                        isRequired={false}
                        getValue={(item) => item.id}
                        getLabel={(item) => item.name}
                        label={`${$t('form_fields.access_level')}`}
                        disabled={
                          isSharedEquipment &&
                          !selectedCompanyOwnsEquipment &&
                          !defaultEquipment?.companyShare?.dispatchable
                        }
                      />
                    </Box>
                    <RemoveItemButton
                      action={() => {
                        removeCompanyShares(index);
                      }}
                      sx={{
                        ml: 1,
                        mt: 2.3,
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                );
              })}
            </Box>

            <PseudoLink
              disabled={companySharesFields.length === connectedCompanies.length}
              title={`+ ${$t('administration.equipment.add_shared_company')}`}
              sx={{
                mt: 1,
                display: 'inline-flex',
                pointerEvents:
                  companySharesFields.length === connectedCompanies.length
                    ? 'none'
                    : 'auto',
              }}
              action={() => {
                companySharesFields.length !== connectedCompanies.length &&
                  appendCompanyShares({
                    id: '',
                    receiverCompany: { id: '', legalName: '' },
                    dispatchable: { id: 'view_only', name: 'View Only' },
                    companyShareableId: defaultEquipment?.id || '',
                  });
              }}
            />
          </Box>
        </Collapse>
      </Box>
    </form>
  );
});
export { ExternalEquipmentForm };
