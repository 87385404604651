import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import { SxProps } from '@mui/system';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getV1CompaniesCompanyIdOrdersDispatch,
  getV1OrdersDispatch,
  GetV1OrdersDispatchData,
} from '@treadinc/horizon-api-spec';
import axios from 'axios';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';

import {
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { Order } from '~hooks/useOrders';
import { extractPagination, Pagination } from '~services/pagination';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';

import CustomerSelector from './copy-vendor-assignments-form/CustomerSelector';
import DateRangeSelector from './copy-vendor-assignments-form/DateRangeSelector';
import SearchBar from './copy-vendor-assignments-form/SearchBar';

export const CONTROLS_HEIGHT_IN_PX = 36;

interface CopyVendorAssignmentsFormProps {
  companyId: string;
  onSelectedOrderChange: (order: Order) => void;
  orderId?: string;
}

type Filters = {
  customerAccountIds: string[];
  endDate: string;
  search: string;
  startDate: string;
};

type QueryParamsState = {
  filters: Filters;
  pagination?: Pagination;
};

const deriveQueryFromQueryParams = (queryParams: QueryParamsState) => {
  const { filters, pagination } = queryParams;

  const query: GetV1OrdersDispatchData['query'] = { 'page[limit]': 20 };

  if (pagination?.after) {
    query['page[after]'] = pagination.after;
  }

  if (filters?.search?.trim().length) {
    query['search[job][datagrid]'] = filters.search.trim();
  }

  if (filters?.startDate?.length) {
    query['filter[job][start_date]'] = filters.startDate;
  }

  if (filters?.endDate?.length) {
    query['filter[job][end_date]'] = filters.endDate;
  }

  if (filters?.customerAccountIds?.length) {
    query['filter[job][customer_account_ids]'] = filters.customerAccountIds;
  }

  return query;
};

export default function CopyVendorAssignmentsForm({
  companyId,
  onSelectedOrderChange,
  orderId,
}: CopyVendorAssignmentsFormProps) {
  const { userStore } = useStores();
  const { isCurrentCompanyActive } = userStore;
  const effectiveCompanyId = isCurrentCompanyActive ? undefined : companyId;

  const infiniteScroll = useInfiniteScroll();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    filters: {
      customerAccountIds: [],
      endDate: '',
      search: '',
      startDate: '',
    },
  });

  const { isLoading, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      'CopyVendorAssignmentsForm_getV1OrdersDispatch',
      queryParams.filters,
      effectiveCompanyId,
    ],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: ({ pageParam, signal }) => {
      const query = deriveQueryFromQueryParams({ ...queryParams, pagination: pageParam });

      const cancelTokenSource = axios.CancelToken.source();
      signal?.addEventListener('abort', () => cancelTokenSource.cancel());

      const request = effectiveCompanyId
        ? getV1CompaniesCompanyIdOrdersDispatch({
            query,
            path: { 'company-id': effectiveCompanyId },
            cancelToken: cancelTokenSource.token,
          })
        : getV1OrdersDispatch({ query, cancelToken: cancelTokenSource.token });

      return request.then((response) => {
        const orders = response.data.data.map((order) => Order.parse(order));
        const pagination = extractPagination(response);

        setQueryParams((state) => ({ ...state, pagination }));
        infiniteScroll.notify();

        return orders;
      });
    },
  });

  const orders = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const handleDateRangeChange = useCallback((startDate?: string, endDate?: string) => {
    setQueryParams((state) => ({
      filters: { ...state.filters, startDate: startDate ?? '', endDate: endDate ?? '' },
    }));
  }, []);

  const handleCustomerAccountsChange = useCallback((customerAccountIds: string[]) => {
    setQueryParams((state) => ({
      filters: { ...state.filters, customerAccountIds },
    }));
  }, []);

  const handleSearchValueChange = useCallback((search: string) => {
    setQueryParams((state) => ({ filters: { ...state.filters, search } }));
  }, []);

  useEffect(() => {
    if (infiniteScroll.endReached) {
      fetchNextPage();
    }
  }, [infiniteScroll.endReached, fetchNextPage]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        borderBottom={`solid 1px ${theme.palette.divider}`}
        display="flex"
        flex={0}
        gap={1}
        p={theme.spacing(1, 2)}
      >
        <DateRangeSelector onChange={handleDateRangeChange} />

        <CustomerSelector companyId={companyId} onChange={handleCustomerAccountsChange} />

        <SearchBar onChange={handleSearchValueChange} />
      </Box>

      <Box flex={1} overflow="auto">
        {isLoading ? (
          <Box display="flex" height="100%" alignItems="center" justifyContent="center">
            <LoadingSpinner isVisible />
          </Box>
        ) : (
          <>
            <OrdersTable
              onSelectedOrderChange={onSelectedOrderChange}
              orders={orders}
              selectedOrderId={orderId}
            />

            {orders.length > 0 && queryParams.pagination?.after && (
              <Box ref={infiniteScroll.nodeRef}>
                <LoadingSpinner
                  isVisible={isFetchingNextPage}
                  loadingIndicatorSize={16}
                  sx={{ my: 2 }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

interface OrdersTableProps {
  onSelectedOrderChange: (order: Order) => void;
  orders: Order[];
  selectedOrderId?: string;
}

function OrdersTable({
  onSelectedOrderChange,
  orders,
  selectedOrderId,
}: OrdersTableProps) {
  return (
    <Box
      alignItems="center"
      display="grid"
      gridAutoRows="32px"
      gridTemplateColumns="auto repeat(7, 1fr)"
    >
      <TableHeader label="" />
      <TableHeader label={t('order.form.dispatch_number_full')} />
      <TableHeader
        label={t('dispatch.dispatch_v2.copy_vendor_assignments.tread_order_id')}
      />
      <TableHeader label={t('common.date')} />
      <TableHeader label={t('order.form.order_name')} />
      <TableHeader label={t('common.customer')} />
      <TableHeader label={t('form_fields.pick_up')} />
      <TableHeader label={t('form_fields.material')} />

      {orders.map((order) => (
        <React.Fragment key={order.id}>
          <TableCell>
            <RadioButton
              isChecked={selectedOrderId === order.id}
              onCheckedStateChange={() => onSelectedOrderChange(order)}
            />
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.dispatchNumber}</OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.orderId}</OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>
              {dayjs.tz(order.loadAt).format('MM/DD/YYYY')}
            </OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.name}</OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>
              {order.sendingAccounts.map((i) => i.name).join(', ')}
            </OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>
              {order.waypoints?.[0]?.siteNested?.address?.streetAddress}
            </OverflowAwareText>
          </TableCell>

          <TableCell>
            <OverflowAwareText>{order.material?.name}</OverflowAwareText>
          </TableCell>
        </React.Fragment>
      ))}
    </Box>
  );
}

interface RadioButtonProps {
  isChecked?: boolean;
  onCheckedStateChange?: () => void;
}

function RadioButton({ isChecked, onCheckedStateChange }: RadioButtonProps) {
  return (
    <FormControl>
      <Radio
        checked={isChecked}
        onChange={onCheckedStateChange}
        sx={{
          '&.MuiRadio-root': { p: 0 },
          '.MuiSvgIcon-root': { width: '18px', height: '18px' },
        }}
      />
    </FormControl>
  );
}

interface TableCell {
  sx?: SxProps;
}

function TableCell({ children, sx }: React.PropsWithChildren<TableCell>) {
  return (
    <Box
      alignItems="center"
      borderTop={`solid 1px ${theme.palette.divider}`}
      display="flex"
      height="100%"
      overflow="hidden"
      p={theme.spacing(0.5, 1)}
      sx={sx}
      width="100%"
    >
      {children}
    </Box>
  );
}

interface TableHeaderProps {
  label: string;
}

function TableHeader({ label }: TableHeaderProps) {
  return (
    <TableCell sx={{ borderTop: 0 }}>
      <OverflowAwareText
        sx={{ textTransform: 'uppercase', color: theme.brandV2.colors.treadGray4 }}
      >
        {label}
      </OverflowAwareText>
    </TableCell>
  );
}
