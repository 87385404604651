import {
  Equipment_Read,
  EquipmentType_Read,
  getV1CompaniesCompanyIdEquipmentTypeahead,
  getV1CompaniesCompanyIdEquipmentTypesTypeahead,
  GetV1CompaniesCompanyIdEquipmentTypesTypeaheadData,
  getV1CompaniesIdEquipment,
  GetV1CompaniesIdEquipmentData,
  GetV1EquipmentTypeaheadData,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import _ from 'lodash';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import {
  Equipment,
  EquipmentTypeahead,
  EquipmentTypeItem,
  EquipmentTypeTypeahead,
} from '~hooks/useEquipment';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';
interface CreateEquipmentProps {
  equipment: Equipment;
  callBack?: (equipment: Equipment) => void;
}

interface CreateEquipmentTypeByCompanyProps {
  equipmentType: EquipmentTypeItem;
  companyId: string;
  callBack?: (equipmentType: EquipmentTypeItem) => void;
}
interface GetEquipmentTypesByCompanyIdProps {
  companyId: string;
  callBack?: (equipmentTypes: EquipmentTypeItem[]) => void;
  link?: PaginationLink;
}
interface GetEquipmentTypesByCompanyIdTypeaheadProps {
  companyId: string;
  callBack?: (equipmentTypes: EquipmentTypeTypeahead[]) => void;
  limit?: number;
  query?: string;
  cursor?: string;
}

interface DeleteEquipmentProps {
  equipmentId: string;
  callBack?: () => void;
}
interface DeleteEquipmentTypeProps {
  equipmentTypeId: string;
  callBack?: () => void;
}
interface UpdateEquipmentTypeProps {
  equipmentType: EquipmentTypeItem;
  callBack?: (equipmentType: EquipmentTypeItem) => void;
}

export interface GetEquipmentByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  limit?: number;
  dispatchable?: boolean;
  shared?: boolean;
  query?: string;
}

interface GetEquipmentByCompanyIdTypeaheadProps {
  companyId: string;
  limit?: number;
  query?: string;
  cursor?: string;
  dispatchable?: boolean;
  shared?: boolean;
}

export const useEquipment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { companyAssetsStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const getEquipment = (link?: PaginationLink, searchQuery?: string) => {
    setIsLoading(true);
    let params: PaginationQuery = {
      'page[limit]': companyAssetsStore.equipmentPagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && companyAssetsStore.equipmentPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.equipmentPagination[link];
    }
    return connection
      .getPaginated<Equipment_Read>(
        `${API_VERSION}/equipment`,
        { params },
        $t('error_messages.equipment.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        companyAssetsStore.setEquipment(data.map(Equipment.parse));
        companyAssetsStore.setEquipmentPagination(pagination);
        companyAssetsStore.updateEquipmentPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getEquipmentByCompanyId = async ({
    companyId,
    link,
    shared,
    limit,
    dispatchable,
    query: searchQuery,
  }: GetEquipmentByCompanyIdProps) => {
    const query: GetV1CompaniesIdEquipmentData['query'] = {
      'page[limit]': limit || companyAssetsStore.equipmentPagination.limit,
    };
    if (link && companyAssetsStore.equipmentPagination[link]) {
      query[`page[${link}]`] = companyAssetsStore.equipmentPagination[link];
    }
    if (!_.isNil(shared)) {
      query['filter[shared]'] = shared;
    }
    if (!_.isNil(dispatchable)) {
      query['filter[dispatchable]'] = dispatchable;
    }
    if (searchQuery) {
      query['search[datagrid]'] = searchQuery;
    }

    try {
      setIsLoading(true);
      const response = await getV1CompaniesIdEquipment({
        path: { 'company-id': companyId },
        query,
      });
      const parsedEquipment = response.data.data.map(Equipment.parse);
      const pagination = extractPagination(response);

      companyAssetsStore.setEquipment(parsedEquipment);
      companyAssetsStore.setEquipmentPagination(pagination);
      companyAssetsStore.updateEquipmentPageNumber(link);
      return parsedEquipment;
    } catch (e) {
      connection.handleRequestError(
        e,
        $t('error_messages.equipment.failed_to_fetch') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getEquipmentByCompanyIdTypeahead = async ({
    companyId,
    limit,
    query: searchQuery,
    cursor,
    dispatchable,
    shared,
  }: GetEquipmentByCompanyIdTypeaheadProps) => {
    const query: GetV1EquipmentTypeaheadData['query'] = {
      'page[limit]': limit || companyAssetsStore.equipmentPagination.limit,
    };

    if (searchQuery) {
      query['search[query]'] = searchQuery;
    }

    if (cursor) {
      query['page[after]'] = cursor;
    }

    if (!_.isNil(shared)) {
      query['filter[shared]'] = shared;
    }
    if (!_.isNil(dispatchable)) {
      query['filter[dispatchable]'] = dispatchable;
    }

    try {
      setIsLoading(true);
      const response = await getV1CompaniesCompanyIdEquipmentTypeahead({
        path: { 'company-id': companyId },
        query,
      });
      const parsedData = response.data.data.map(EquipmentTypeahead.parse);
      const pagination = extractPagination(response);
      return { data: parsedData, pagination };
    } catch (e) {
      connection.handleRequestError(
        e,
        $t('error_messages.equipment.failed_to_fetch') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const createEquipment = ({ equipment, callBack }: CreateEquipmentProps) => {
    setIsLoading(true);
    return connection
      .post<Equipment_Read>(
        `${API_VERSION}/equipment`,
        Equipment.deparse(equipment),
        {},
        $t('error_messages.equipment.failed_to_create') as string,
        [422],
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.addEquipment(equipment);
        callBack?.(equipment);
        return equipment;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createEquipmentTypeByCompanyId = ({
    companyId,
    equipmentType,
    callBack,
  }: CreateEquipmentTypeByCompanyProps) => {
    setIsLoading(true);
    return connection
      .post<EquipmentType_Read>(
        `${API_VERSION}/companies/${companyId}/equipment_types`,
        EquipmentTypeItem.deparse(equipmentType),
        {},
        $t('error_messages.equipment_types.failed_to_create') as string,
      )
      .then((resp) => {
        const equipmentType = EquipmentTypeItem.parse(resp);

        companyAssetsStore.addEquipmentType(equipmentType);
        callBack?.(equipmentType);
        return equipmentType;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateEquipment = ({ equipment, callBack }: CreateEquipmentProps) => {
    setIsUpdating(true);
    return connection
      .patch<Equipment_Read>(
        `${API_VERSION}/equipment/${equipment.id}`,
        Equipment.deparseUpdate(equipment),
        {},
        $t('error_messages.equipment.failed_to_update') as string,
        [422],
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.updateEquipment(equipment);
        callBack?.(equipment);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const deleteEquipment = ({ equipmentId, callBack }: DeleteEquipmentProps) => {
    setIsLoading(true);
    return connection
      .delete<Equipment_Read>(
        `${API_VERSION}/equipment/${equipmentId}`,
        {},
        $t('error_messages.equipment.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteEquipment(equipmentId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getEquipmentTypeById = (equipmentTypeId: string) => {
    try {
      setIsLoading(true);
      return connection
        .get<EquipmentType_Read>(
          `${API_VERSION}/equipment_types/${equipmentTypeId}`,
          {},
          $t('error_messages.equipment_types.failed_to_fetch') as string,
        )
        .then((resp) => {
          const equipment = EquipmentTypeItem.parse(resp);
          return equipment;
        });
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.equipment_types.failed_to_fetch') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const getEquipmentTypesByCompanyId = ({
    companyId,
    callBack,
    link,
  }: GetEquipmentTypesByCompanyIdProps) => {
    const params: PaginationQuery = {
      'page[limit]': companyAssetsStore.equipmentTypePagination.limit,
    };
    if (link && companyAssetsStore.equipmentTypePagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.equipmentTypePagination[link];
    }
    setIsLoading(true);
    return connection
      .getPaginated<EquipmentType_Read>(
        `${API_VERSION}/companies/${companyId}/equipment_types`,
        { params },
        $t('error_messages.equipment_types.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(EquipmentTypeItem.parse);
        companyAssetsStore.setEquipmentTypes(formatted);
        companyAssetsStore.setEquipmentTypePagination(pagination);
        companyAssetsStore.updateEquipmentTypePageNumber(link);
        callBack?.(formatted);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getEquipmentTypesByCompanyIdTypeahead = async (
    options: GetEquipmentTypesByCompanyIdTypeaheadProps,
  ) => {
    const params: GetV1CompaniesCompanyIdEquipmentTypesTypeaheadData['query'] = {
      'page[limit]': options.limit || companyAssetsStore.equipmentTypePagination.limit,
    };
    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);

      const response = await getV1CompaniesCompanyIdEquipmentTypesTypeahead({
        path: { 'company-id': options.companyId },
        query: params,
      });

      const formatted = response.data.data.map((equipmentType) => {
        return EquipmentTypeTypeahead.parse(equipmentType);
      });
      const pagination = extractPagination(response);

      options.callBack?.(formatted);

      return { data: formatted, pagination };
    } catch (e) {
      connection.handleRequestError(
        e,
        $t('error_messages.equipment_types.failed_to_fetch') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getEquipmentById = (equipmentId: string) => {
    try {
      setIsLoading(true);
      return connection
        .get<Equipment_Read>(
          `${API_VERSION}/equipment/${equipmentId}`,
          {},
          $t('error_messages.equipment.failed_to_fetch') as string,
        )
        .then((resp) => {
          const equipment = Equipment.parse(resp);
          return equipment;
        });
    } catch (e) {
      console.error(e);
      throw new Error($t('error_messages.equipment.failed_to_fetch') as string);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEquipmentType = ({ equipmentType, callBack }: UpdateEquipmentTypeProps) => {
    setIsUpdating(true);
    return connection
      .patch<EquipmentType_Read>(
        `${API_VERSION}/equipment_types/${equipmentType.id}`,
        EquipmentTypeItem.deparseUpdate(equipmentType),
        {},
        $t('error_messages.equipment_types.failed_to_update') as string,
      )
      .then((resp) => {
        const formatted = EquipmentTypeItem.parse(resp);
        companyAssetsStore.updateEquipmentType(equipmentType);
        callBack?.(formatted);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const deleteEquipmentType = ({
    equipmentTypeId,
    callBack,
  }: DeleteEquipmentTypeProps) => {
    setIsLoading(true);
    return connection
      .delete<EquipmentTypeItem>(
        `${API_VERSION}/equipment_types/${equipmentTypeId}`,
        {},
        $t('error_messages.equipment_types.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteEquipmentType(equipmentTypeId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const activateEquipment = (equipmentId: string) => {
    setIsLoading(true);
    return connection
      .put<Equipment_Read>(
        `${API_VERSION}/equipment/${equipmentId}/activate`,
        {},
        {},
        $t('error_messages.equipment.failed_to_activate') as string,
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.updateEquipment(equipment);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const deactivateEquipment = (equipmentId: string) => {
    setIsUpdating(true);
    return connection
      .put<Equipment_Read>(
        `${API_VERSION}/equipment/${equipmentId}/deactivate`,
        {},
        {},
        $t('error_messages.equipment.failed_to_deactivate') as string,
      )
      .then((resp) => {
        const equipment = Equipment.parse(resp);
        companyAssetsStore.updateEquipment(equipment);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return {
    isLoading,
    isUpdating,
    createEquipment,
    deleteEquipment,
    updateEquipment,
    getEquipmentTypeById,
    getEquipmentTypesByCompanyId,
    getEquipmentTypesByCompanyIdTypeahead,
    createEquipmentTypeByCompanyId,
    deleteEquipmentType,
    updateEquipmentType,
    activateEquipment,
    deactivateEquipment,
    getEquipment,
    getEquipmentById,
    getEquipmentByCompanyId,
    getEquipmentByCompanyIdTypeahead,
  };
};
