import {
  CompanyShare_Create,
  CompanyShare_Read,
  CompanyShare_Read_Nested,
  CompanyShare_Update,
  CompanyShareable_Read_Nested,
  CompanyShareableType,
} from '@treadinc/horizon-api-spec';

import { CompanyBasic } from '~hooks/useCompany/models';
import { Nullable } from '~types/Nullable';

export class CompanyShareableBasic {
  constructor(
    private _id: string,
    private _type: CompanyShareableType,
    private _name: string,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get type(): CompanyShareableType {
    return this._type;
  }

  public get name(): string {
    return this._name;
  }

  public static parse(proto: CompanyShareable_Read_Nested) {
    return new CompanyShareableBasic(proto.id, proto.type, proto.name);
  }
}
export class CompanyShareBasic {
  constructor(
    private _id: string,
    private _external_id: Nullable<string>,
    private _sender_company: CompanyBasic,
    private _dispatchable: boolean,
    private _ticket_match_id: Nullable<string>,
  ) {}

  public get id(): string {
    return this._id;
  }

  public get externalId(): Nullable<string> {
    return this._external_id;
  }

  public get senderCompany(): CompanyBasic {
    return this._sender_company;
  }

  public get dispatchable(): boolean {
    return this._dispatchable;
  }

  public get ticketMatchId(): Nullable<string> {
    return this._ticket_match_id;
  }

  public static parse(proto: CompanyShare_Read_Nested) {
    return new CompanyShareBasic(
      proto.id,
      proto.external_id,
      CompanyBasic.parse(proto.sender_company),
      proto.dispatchable,
      proto.ticket_match_id,
    );
  }
}

export class CompanyShare extends CompanyShareBasic {
  constructor(
    _id: string,
    _external_id: Nullable<string>,
    _sender_company: CompanyBasic,
    _dispatchable: boolean,
    _ticket_match_id: Nullable<string>,
    private _parent_company_share_id: Nullable<string>,
    private _receiver_company: CompanyBasic,
    private _company_shareable: CompanyShareableBasic,
  ) {
    super(_id, _external_id, _sender_company, _dispatchable, _ticket_match_id);
  }

  public get parentCompanyShareeId(): Nullable<string> {
    return this._parent_company_share_id;
  }

  public get receiverCompany(): CompanyBasic {
    return this._receiver_company;
  }

  public get companyShareable(): CompanyShareableBasic {
    return this._company_shareable;
  }

  public static parse(proto: CompanyShare_Read) {
    return new CompanyShare(
      proto.id,
      proto.external_id,
      CompanyBasic.parse(proto.sender_company),
      proto.dispatchable,
      proto.ticket_match_id,
      proto.parent_company_share_id,
      CompanyBasic.parse(proto.receiver_company),
      CompanyShareableBasic.parse(proto.company_shareable),
    );
  }

  public static deparse(proto: any): CompanyShare_Create {
    const data = {
      receiver_company_id: proto.receiver_company_id,
      company_shareable_id: proto.company_shareable_id,
      company_shareable_type: proto.company_shareable_type,
      dispatchable: proto.dispatchable,
    };

    return data as CompanyShare_Create;
  }

  public static deparseUpdate(proto: any): CompanyShare_Update {
    const data = {
      external_id: proto.external_id,
      ticket_match_id: proto.ticket_match_id,
      dispatchable: proto.dispatchable,
    };

    return data as CompanyShare_Update;
  }
}
