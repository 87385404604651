import {
  CompanyShare_Create,
  CompanyShare_Read,
  CompanyShare_Update,
  patchV1CompanySharesId,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { CompanyShare } from '~hooks/useCompanyShares/models';
import connection from '~services/connectionModule';
import { Paginated, PaginationLink } from '~services/pagination';
import { useStores } from '~store';

interface GetCompanySharesByCompanyIdParams {
  companyId: string;
  limit?: number;
  link?: PaginationLink;
  company_shareable_type?: string;
  receiver_company_id?: string;
  sender_company_id?: string;
  company_shareable_id?: string;
  between_company_id?: string;
  callBack?: (companyShares: CompanyShare[]) => void;
}

interface GetCompanySharesByCompanyIdProps {
  'page[limit]': number;
  'page[before]': string;
  'page[after]': string;
  'filter[company_shareable_type]': string;
  'filter[receiver_company_id]': string;
  'filter[sender_company_id]': string;
  'filter[company_shareable_id]': string;
  'filter[between_company_id]': string;
}

interface CreateCompanyShareByCompanyIdProps extends CompanyShare_Create {
  companyId: string;
}

interface UpdateCompanyShareProps extends CompanyShare_Update {
  companyShareId: string;
}

export const useCompanyShares = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { companyAssetsStore } = useStores();

  const getCompanySharesByCompanyId = async (
    options: GetCompanySharesByCompanyIdParams,
  ) => {
    const params: Partial<GetCompanySharesByCompanyIdProps> = {
      'page[limit]': options.limit || companyAssetsStore.companySharesPagination.limit,
    };
    if (options.link && companyAssetsStore.companySharesPagination[options.link]) {
      params[`page[${options.link}]`] =
        companyAssetsStore.companySharesPagination[options.link];
    }
    if (options.company_shareable_type) {
      params['filter[company_shareable_type]'] = options.company_shareable_type;
    }
    if (options.receiver_company_id) {
      params['filter[receiver_company_id]'] = options.receiver_company_id;
    }
    if (options.sender_company_id) {
      params['filter[sender_company_id]'] = options.sender_company_id;
    }
    if (options.company_shareable_id) {
      params['filter[company_shareable_id]'] = options.company_shareable_id;
    }
    if (options.between_company_id) {
      params['filter[between_company_id]'] = options.between_company_id;
    }

    try {
      setIsLoading(true);
      const { data, pagination }: Paginated<CompanyShare_Read> =
        await connection.getPaginated(
          `${API_VERSION}/companies/${options.companyId}/company_shares`,
          { params },
          $t('error_messages.company_shares.failed_to_fetch') as string,
        );
      companyAssetsStore.setCompanyShares(data.map(CompanyShare.parse));
      companyAssetsStore.setCompanySharesPagination(pagination);
      companyAssetsStore.updateCompanySharesPageNumber(options.link);
      options.callBack?.(data.map(CompanyShare.parse));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createCompanyShareByCompanyId = async (
    options: CreateCompanyShareByCompanyIdProps,
  ) => {
    try {
      setIsUpdating(true);
      const resp = await connection.post<CompanyShare_Read>(
        `${API_VERSION}/companies/${options.companyId}/company_shares`,
        CompanyShare.deparse(options),
        {},
        $t('error_messages.company_shares.failed_to_share') as string,
      );
      const companyShare = CompanyShare.parse(resp);
      companyAssetsStore.addCompanyShare(companyShare);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const getCompanyShareById = async (companyShareId: string) => {
    try {
      setIsLoading(true);
      const resp = await connection.get<CompanyShare_Read>(
        `${API_VERSION}/company_shares/${companyShareId}`,
        {},
        $t('error_messages.company_shares.failed_to_fetch') as string,
      );
      return CompanyShare.parse(resp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCompanyShare = async (options: UpdateCompanyShareProps) => {
    try {
      setIsUpdating(true);
      const response = await patchV1CompanySharesId({
        path: {
          id: options.companyShareId,
        },
        body: CompanyShare.deparseUpdate(options),
      });

      const companyShare = CompanyShare.parse(response.data.data);
      companyAssetsStore.updateCompanyShare(companyShare);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.company_shares.failed_to_update') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteCompanyShare = async (companyShareId: string) => {
    try {
      setIsUpdating(true);
      await connection.delete(
        `${API_VERSION}/company_shares/${companyShareId}`,
        {},
        $t('error_messages.company_shares.failed_to_unshare') as string,
      );
      companyAssetsStore.deleteCompanyShare(companyShareId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isLoading,
    isUpdating,
    getCompanySharesByCompanyId,
    createCompanyShareByCompanyId,
    getCompanyShareById,
    updateCompanyShare,
    deleteCompanyShare,
  };
};
