import { t } from 'i18next';
import * as yup from 'yup';

const companySharesSchema = yup
  .array()
  .of(
    yup.object().shape({
      id: yup.string(),
      receiverCompany: yup.object().shape({
        id: yup.string().required(),
        legalName: yup.string(),
      }),
      dispatchable: yup.object().shape({
        id: yup.string(),
        name: yup.string(),
      }),
      companyShareableId: yup.string(),
    }),
  )
  .notRequired();

export const sharedEquipmentFormValidationSchema = yup.object().shape({
  companySpecificId: yup.string(),
  companyShares: companySharesSchema,
});

export const externalEquipmentFormValidationSchema = yup.object().shape({
  name: yup.string(),
  externalId: yup.string(),
  ticketMatchId: yup.string(),
  companyShares: companySharesSchema,
});

export const equipmentFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.first_name') })}`,
    ),
  id: yup.string(),
  company: yup
    .object()
    .typeError(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    )
    .required(
      `${t('form_validation_errors.required', { field: t('form_fields.company') })}`,
    ),
  type: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string().required(),
      system: yup.boolean(),
    })
    .required(`${t('form_validation_errors.required_simple')}`),
  externalId: yup.string(),
  licenseNumber: yup.string(),
  isNotActive: yup.boolean(),
  isExternal: yup.boolean(),
  companySpecificId: yup.string(),
  companyShares: companySharesSchema,
});
