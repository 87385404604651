import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  skipToken,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  AccountType,
  Driver_Read,
  getV1CompaniesAccountsTypeahead,
  GetV1CompaniesAccountsTypeaheadData,
  getV1CompaniesCompanyIdDriversTypeahead,
  getV1CompaniesCompanyIdOrdersDispatchNumbersTypeahead,
  getV1CompaniesCompanyIdProjectsTypeahead,
  getV1CompaniesCompanyIdSitesDispatchTypeahead,
  GetV1CompaniesCompanyIdSitesDispatchTypeaheadData,
  getV1CompaniesCompanyIdUsersTypeahead,
  getV1ProjectsExternalIdsTypeahead,
  Project_Read,
  User_Me_Read,
} from '@treadinc/horizon-api-spec';
import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import {
  FilterMenuItemLoadingReason,
  FilterMenuOption,
} from '~components/Filters/FilterMenuItem';
import { AccountTypeahead } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import { Project } from '~hooks/useProjects';
import { SiteTypeahead } from '~hooks/useSites';
import { User } from '~hooks/useUsers';
import { extractPagination, Pagination } from '~services/pagination';
import { Filters } from '~store/OrdersDispatchStore';

const QUERY_PAGINATION_LIMIT = 10;

export interface OrderDispatchQuery {
  clear: () => void;
  data: FilterMenuOption[];
  fetch: (searchQuery: string) => void;
  fetchMore:
    | ((
        options?: FetchNextPageOptions | undefined,
      ) => Promise<
        InfiniteQueryObserverResult<InfiniteData<FilterMenuOption[], unknown>, Error>
      >)
    | undefined;
  loadingReason?: FilterMenuItemLoadingReason;
}

type QueryParamsState = {
  shouldFetch: boolean;
  filters: { query: string };
  pagination?: Pagination & { hasMore: boolean };
};

type SimpleQueryParams = Pick<
  NonNullable<GetV1CompaniesAccountsTypeaheadData['query']>,
  'page[after]' | 'page[limit]' | 'search[query]'
>;

function deriveSimpleQueryParams(queryParams: QueryParamsState) {
  const { filters, pagination } = queryParams;

  const query: SimpleQueryParams = { 'page[limit]': QUERY_PAGINATION_LIMIT };

  if (pagination?.after) {
    query['page[after]'] = pagination.after;
  }

  if (filters.query.trim().length) {
    query['search[query]'] = filters.query.trim();
  }

  return query;
}

/**
 * Customers and Vendors
 */
function deriveAccountTypeCommonQueryParams(
  queryParams: QueryParamsState,
  accountType: AccountType,
) {
  const query: GetV1CompaniesAccountsTypeaheadData['query'] =
    deriveSimpleQueryParams(queryParams);
  query['filter[account_types]'] = [accountType];

  return query;
}

export function useCustomersQuery(companyId: string): OrderDispatchQuery {
  const queryKey = 'OrdersDispatchFiltersQueries_customers';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveAccountTypeCommonQueryParams(
            {
              ...queryParams,
              pagination: pageParam,
            },
            AccountType.CUSTOMER,
          );

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesAccountsTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const accounts = response.data.data.map((account) => {
              const parsed = AccountTypeahead.parse(account);

              return { label: parsed.name, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return accounts;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

export function useVendorsQuery(companyId: string): OrderDispatchQuery {
  const queryKey = 'OrdersDispatchFiltersQueries_vendors';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveAccountTypeCommonQueryParams(
            {
              ...queryParams,
              pagination: pageParam,
            },
            AccountType.VENDOR,
          );

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesAccountsTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const accounts = response.data.data.map((account) => {
              const parsed = AccountTypeahead.parse(account);

              return { label: parsed.name, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return accounts;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Dispatch Numbers
 */
export function useDispatchNumbersQuery(companyId: string): OrderDispatchQuery {
  const queryKey =
    'OrdersDispatchFilters_getV1CompaniesCompanyIdOrdersDispatchNumbersTypeahead';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveSimpleQueryParams({
            ...queryParams,
            pagination: pageParam,
          });

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesCompanyIdOrdersDispatchNumbersTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const dispatchNumbers = response.data.data.map((dispatchNumber) => {
              return { label: dispatchNumber.name, value: dispatchNumber.name };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return dispatchNumbers;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Drivers
 */
export function useDriversQuery(companyId: string): OrderDispatchQuery {
  const queryKey = 'OrdersDispatchFiltersQueries_drivers';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveSimpleQueryParams({
            ...queryParams,
            pagination: pageParam,
          });

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesCompanyIdDriversTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const drivers = response.data.data.map((driver) => {
              const parsed = DriverBasic.parse(driver as Driver_Read);

              return { label: parsed.fullName, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return drivers;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Pick Up and Drop Off
 */
function deriveWaypointCommonQueryParams(
  queryParams: QueryParamsState,
  filtersInStore: Filters,
  waypointType: 'pickup' | 'drop_off',
) {
  const { filters, pagination } = queryParams;

  const query: GetV1CompaniesCompanyIdSitesDispatchTypeaheadData['query'] = {
    'page[limit]': QUERY_PAGINATION_LIMIT,
    'facet[filter][waypoint][type]': waypointType,
  };

  if (pagination?.after) {
    query['page[after]'] = pagination.after;
  }

  if (filters.query.trim().length) {
    query['facet[search][query]'] = filters.query.trim();
  }

  if (filtersInStore?.customerAccountIds?.length) {
    query['filter[job][customer_account_ids]'] = filtersInStore.customerAccountIds;
  }

  if (filtersInStore?.dispatchNumbers?.length) {
    query['filter[job][dispatch_numbers]'] = filtersInStore.dispatchNumbers;
  }

  if (filtersInStore?.driverIds?.length) {
    query['filter[job][driver_ids]'] = filtersInStore.driverIds;
  }

  if (filtersInStore?.projectsExternalIds?.length) {
    query['filter[job][external_ids]'] = filtersInStore.projectsExternalIds;
  }

  if (filtersInStore?.projectIds?.length) {
    query['filter[job][project_ids]'] = filtersInStore.projectIds;
  }

  if (filtersInStore?.vendorAccountIds?.length) {
    query['filter[job][vendor_account_ids]'] = filtersInStore.vendorAccountIds;
  }

  if (filtersInStore?.orderStates?.length) {
    query['filter[states]'] = filtersInStore.orderStates;
  }

  if (filtersInStore?.startDate?.length) {
    query['filter[job][start_date]'] = dayjs
      .tz(filtersInStore.startDate)
      .startOf('day')
      .toISOString();
  }

  if (filtersInStore?.endDate?.length) {
    query['filter[job][end_date]'] = dayjs
      .tz(filtersInStore.endDate)
      .endOf('day')
      .toISOString();
  }

  if (filtersInStore?.jobStates?.length) {
    query['filter[job][states]'] = filtersInStore.jobStates;
  }

  if (filtersInStore?.pickUpSites?.length) {
    query['filter[job][pickup_site_ids]'] = filtersInStore.pickUpSites;
  }

  if (filtersInStore?.dropoffSites?.length) {
    query['filter[job][dropoff_site_ids]'] = filtersInStore.dropoffSites;
  }

  if (filtersInStore?.requesterIds?.length) {
    query['filter[requester_ids]'] = filtersInStore.requesterIds;
  }

  if (filtersInStore?.siteTypes?.length) {
    query['filter[job][site_types]'] = filtersInStore.siteTypes;
  }

  return query;
}

export function useDropOffSitesQuery(
  companyId: string,
  filtersInStore: Filters,
): OrderDispatchQuery {
  const queryKey =
    'OrdersDispatchFilters_getV1CompaniesCompanyIdSitesDispatchTypeahead_DropOff';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveWaypointCommonQueryParams(
            {
              ...queryParams,
              pagination: pageParam,
            },
            filtersInStore,
            'drop_off',
          );

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesCompanyIdSitesDispatchTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const dropOffSites = response.data.data.map((account) => {
              const parsed = SiteTypeahead.parse(account);

              return { label: parsed.name, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return dropOffSites;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

export function usePickUpSitesQuery(
  companyId: string,
  filtersInStore: Filters,
): OrderDispatchQuery {
  const queryKey =
    'OrdersDispatchFilters_getV1CompaniesCompanyIdSitesDispatchTypeahead_PickUp';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveWaypointCommonQueryParams(
            {
              ...queryParams,
              pagination: pageParam,
            },
            filtersInStore,
            'pickup',
          );

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesCompanyIdSitesDispatchTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const dropOffSites = response.data.data.map((account) => {
              const parsed = SiteTypeahead.parse(account);

              return { label: parsed.name, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return dropOffSites;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Projects
 */
export function useProjectsQuery(companyId: string): OrderDispatchQuery {
  const queryKey = 'OrdersDispatchFiltersQueries_projects';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveSimpleQueryParams({
            ...queryParams,
            pagination: pageParam,
          });

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesCompanyIdProjectsTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const projects = response.data.data.map((project) => {
              const parsed = Project.parse(project as Project_Read);

              return { label: parsed.name, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return projects;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Project External IDs
 */
export function useProjectExternalIdsQuery(): OrderDispatchQuery {
  const queryKey = 'OrdersDispatchFiltersQueries_projectExternalIds';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveSimpleQueryParams({
            ...queryParams,
            pagination: pageParam,
          });

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1ProjectsExternalIdsTypeahead({
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const projectExternalIds = response.data.data.map((projectExternalId) => {
              const parsed = Project.parse(projectExternalId as Project_Read);

              return { label: parsed.externalId, value: parsed.externalId };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return projectExternalIds;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Requesters
 */
export function useRequestersQuery(companyId: string): OrderDispatchQuery {
  const queryKey = 'OrdersDispatchFiltersQueries_requesters';
  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    shouldFetch: false,
    filters: { query: '' },
  });

  const { isFetching, isFetchingNextPage, data, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey, queryParams.filters.query, companyId],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    queryFn: queryParams.shouldFetch
      ? ({ pageParam, signal }) => {
          const query = deriveSimpleQueryParams({
            ...queryParams,
            pagination: pageParam,
          });

          const cancelTokenSource = axios.CancelToken.source();
          signal?.addEventListener('abort', () => cancelTokenSource.cancel());

          return getV1CompaniesCompanyIdUsersTypeahead({
            path: { 'company-id': companyId },
            query,
            cancelToken: cancelTokenSource.token,
          }).then((response) => {
            const requesters = response.data.data.map((requester) => {
              const parsed = User.parse(requester as User_Me_Read);

              return { label: parsed.fullName, value: parsed.id };
            });
            const pagination = extractPagination(response);

            setQueryParams((state) => ({
              ...state,
              pagination: { ...pagination, hasMore: Boolean(pagination.after) },
            }));

            return requesters;
          });
        }
      : skipToken,
  });

  const allData = useMemo(() => (data?.pages ?? []).flat(), [data?.pages]);

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const fetch = useCallback((searchQuery: string) => {
    setQueryParams({ shouldFetch: true, filters: { query: searchQuery } });
  }, []);

  const fetchMore = useMemo(() => {
    if (queryParams.pagination?.hasMore) {
      return fetchNextPage;
    }
  }, [queryParams.pagination?.hasMore, fetchNextPage]);

  const clear = useCallback(() => {
    setQueryParams({ shouldFetch: false, filters: { query: '' } });

    queryClient.setQueriesData({ queryKey: [queryKey] }, () => ({
      pages: [],
      pageParams: [],
    }));
  }, []);

  return { clear, data: allData, fetch, fetchMore, loadingReason };
}

/**
 * Dummy hook that satifies the "OrderDispatchQuery" contract. To be used with static data.
 */
export function useDummyQuery(data: FilterMenuOption[]): OrderDispatchQuery {
  const noop = useCallback(() => {
    return _.noop;
  }, []);

  return { clear: noop, data, fetch: noop, fetchMore: undefined };
}
