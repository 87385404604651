import { JobState } from '@treadinc/horizon-api-spec';

import { Job } from '~hooks/useJob';
import { nonEditableJobStates } from '~pages/Dispatch/constants/jobStates';

// Deprecated: Use `isJobSendable` (see: src/utils/jobs/job-utils.ts)
export const canSendInvitation = (job: Job) => {
  const { editable: userCanEditJob, driver, vendorJobAssignment, status } = job;
  const jobIsEligibleToBeSent =
    !!(driver || vendorJobAssignment) &&
    ![...nonEditableJobStates, JobState.ACCEPTED, JobState.REJECTED].includes(status);
  const jobIsAssignedToVendor = !!vendorJobAssignment;

  if (!jobIsEligibleToBeSent) {
    return false;
  }

  if (userCanEditJob) {
    const userCanAccessDriver = !!driver?.id;
    if (userCanAccessDriver || jobIsAssignedToVendor) {
      return true;
    }
  }

  return false;
};
