import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { NameIdSchemaRequired } from '~constants/regexConst';
import { DeparsedOrderEstimate } from '~hooks/useOrders/models';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { QuantityType } from '../newOrderFormSchema';

export type OrderEstimateValuesState = {
  cycleTime: string | number | null;
  equipmentCapacity: string | number | null;
  jobTime: string | number | null;
  quantityType: NameIdSchemaRequired | null;
  serviceQuantity: string | number | null;
};

export default function useOrderEstimate() {
  const form = useFormContext();

  const watchedCycleTime = form.watch('cycleTime') as string | number | null;
  const watchedGrossCapacity = form.watch('grossCapacity') as string | number | null;
  const watchedJobTime = form.watch('jobTime') as string | number | null;
  const watchedQuantityType = form.watch('quantityType') as NameIdSchemaRequired | null;
  const watchedServiceQuantity = form.watch('serviceQuantity') as string | number | null;

  const [orderEstimateValues, setOrderEstimateValues] =
    useState<OrderEstimateValuesState>({
      cycleTime: null,
      equipmentCapacity: null,
      jobTime: null,
      quantityType: null,
      serviceQuantity: null,
    });

  const getOrderEstimateArgs: DeparsedOrderEstimate | null = useMemo(() => {
    const { quantityType, serviceQuantity, equipmentCapacity, jobTime, cycleTime } =
      orderEstimateValues;

    const isValidQuantityType = Boolean(quantityType?.id);

    const isValidServiceQuantity =
      nullableStringOrNumberIsValidNumber(serviceQuantity) && Number(serviceQuantity) > 0;

    const isValidEquipmentCapacity =
      nullableStringOrNumberIsValidNumber(equipmentCapacity) &&
      Number(equipmentCapacity) > 0;

    const isValidJobTime =
      nullableStringOrNumberIsValidNumber(jobTime) && Number(jobTime) > 0;

    const isValidCycleTime =
      nullableStringOrNumberIsValidNumber(cycleTime) && Number(cycleTime) > 0;

    const canEstimate =
      isValidQuantityType &&
      isValidServiceQuantity &&
      isValidEquipmentCapacity &&
      isValidJobTime &&
      isValidCycleTime;

    if (!canEstimate) {
      return null;
    }

    return {
      cycleTimeMinutes: Number(cycleTime),
      equipmentCapacity: Number(equipmentCapacity),
      jobDurationMinutes: Number(jobTime) * 60,
      quantity: Number(serviceQuantity),
      quantityType: quantityType?.id as QuantityType,
    };
  }, [JSON.stringify(orderEstimateValues)]);

  useEffect(() => {
    setOrderEstimateValues({
      cycleTime: watchedCycleTime,
      equipmentCapacity: watchedGrossCapacity,
      jobTime: watchedJobTime,
      quantityType: watchedQuantityType,
      serviceQuantity: watchedServiceQuantity,
    });
  }, [
    watchedCycleTime,
    watchedGrossCapacity,
    watchedJobTime,
    watchedQuantityType,
    watchedServiceQuantity,
  ]);

  return { getOrderEstimateArgs };
}
