import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import {
  AccountType,
  getV1CompaniesAccountsTypeahead,
  GetV1CompaniesAccountsTypeaheadData,
} from '@treadinc/horizon-api-spec';
import axios from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import FilterMenuItem, {
  FilterMenuItemLoadingReason,
} from '~components/Filters/FilterMenuItem';
import Menu from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { AccountTypeahead } from '~hooks/useAccount';
import { extractPagination, Pagination } from '~services/pagination';
import theme from '~theme/AppTheme';

import { CONTROLS_HEIGHT_IN_PX } from '../CopyVendorAssignmentsForm';

type QueryParamsState = {
  filters: { query: string };
  pagination?: Pagination & { hasMore: boolean };
};

const deriveQueryFromQueryParams = (queryParams: QueryParamsState) => {
  const { filters, pagination } = queryParams;

  const query: GetV1CompaniesAccountsTypeaheadData['query'] = {
    'page[limit]': 10,
    'filter[account_types]': [AccountType.CUSTOMER],
  };

  if (pagination?.after) {
    query['page[after]'] = pagination.after;
  }

  if (filters.query.trim().length) {
    query['search[query]'] = filters.query.trim();
  }

  return query;
};

interface CustomerSelectorProps {
  companyId: string;
  onChange: (customerAccountIds: string[]) => void;
}

export default function CustomerSelector({ companyId, onChange }: CustomerSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>();
  const [queryValue, setQueryValue] = useState('');

  const [queryParams, setQueryParams] = useState<QueryParamsState>({
    filters: { query: '' },
  });

  const { isFetchingNextPage, data, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: [
      'CopyVendorAssignmentsForm_CustomerSelector_getAccountsByCompanyIdTypeahead',
      companyId,
      queryParams.filters.query,
    ],
    initialPageParam: queryParams.pagination,
    getNextPageParam: () => queryParams.pagination,
    enabled: isOpen,
    queryFn: ({ pageParam, signal }) => {
      const query = deriveQueryFromQueryParams({
        ...queryParams,
        pagination: pageParam,
      });

      const cancelTokenSource = axios.CancelToken.source();
      signal?.addEventListener('abort', () => cancelTokenSource.cancel());

      return getV1CompaniesAccountsTypeahead({
        path: { 'company-id': companyId },
        query,
        cancelToken: cancelTokenSource.token,
      }).then((response) => {
        const accounts = response.data.data.map((account) => {
          return AccountTypeahead.parse(account);
        });
        const pagination = extractPagination(response);

        setQueryParams((state) => ({
          ...state,
          pagination: { ...pagination, hasMore: Boolean(pagination.after) },
        }));

        return accounts;
      });
    },
  });

  const loadingReason = useMemo(() => {
    if (isFetchingNextPage) {
      return FilterMenuItemLoadingReason.INFINITE_SCROLL;
    }

    if (isFetching) {
      return FilterMenuItemLoadingReason.SEARCH_VALUE;
    }
  }, [isFetchingNextPage, isFetching]);

  const accounts = useMemo(() => {
    const allAccounts = (data?.pages ?? []).flat().map((account) => ({
      label: account.name,
      value: account.id,
    }));

    return _.uniqBy(allAccounts, (account) => account.value);
  }, [data?.pages]);

  const debouncedSetQueryFilter = useRef(
    _.debounce((query: string) => {
      setQueryParams({ filters: { query } });
      setSelectedAccountIds(undefined);
    }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS),
  ).current;

  useEffect(() => {
    if (selectedAccountIds) {
      onChange(selectedAccountIds);
    }
  }, [onChange, selectedAccountIds]);

  useEffect(() => {
    debouncedSetQueryFilter(queryValue);

    return () => {
      debouncedSetQueryFilter.cancel();
    };
  }, [queryValue]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isOpen) {
      queryClient.setQueriesData(
        {
          queryKey: [
            'CopyVendorAssignmentsForm_CustomerSelector_getAccountsByCompanyIdTypeahead',
          ],
        },
        () => ({ pages: [], pageParams: [] }),
      );

      queryClient.invalidateQueries({
        queryKey: [
          'CopyVendorAssignmentsForm_CustomerSelector_getAccountsByCompanyIdTypeahead',
        ],
      });

      setQueryParams((state) => ({ ...state, pagination: undefined }));
    }
  }, [isOpen]);

  return (
    <Box flex={0}>
      <Menu
        sx={{ '& .MuiPaper-root': { width: '250px' } }}
        onOpenStateChanged={setIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            endIcon={
              <KeyboardArrowDown sx={{ color: theme.brandV2.colors.treadGray4 }} />
            }
            sx={{
              '&.MuiButton-root': {
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: theme.brandV2.borderRadius,
                fontWeight: 400,
                height: CONTROLS_HEIGHT_IN_PX,
              },
            }}
          >
            {t('form_fields.select_customer')}
          </SmallButton>
        }
      >
        <FilterMenuItem
          focusSearchFieldOnMount
          loadingReason={loadingReason}
          onFetchMore={queryParams.pagination?.hasMore ? fetchNextPage : undefined}
          onSearchValueChange={setQueryValue}
          onSelectedOptionsChange={setSelectedAccountIds}
          options={accounts}
          searchValue={queryValue}
          selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.customers`) })}`}
          selectedOptions={selectedAccountIds ?? []}
        />
      </Menu>
    </Box>
  );
}
