import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AccountType } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ToggleSidebarButton from '~components/Buttons/ToggleSidebarButton';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { JobAssignmentType } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { AccountTypeahead, useAccount } from '~hooks/useAccount';
import { DriverBasic, useDrivers } from '~hooks/useDrivers';
import { SummaryCounts } from '~pages/Dispatch/components/SummaryCounts';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { useAwaitableFeatureFlag, useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { DRIVER_PANEL_DRIVER_CARD_HEIGHT_IN_PX } from './constants';
import DriverPanelAssigneeCard from './DriverPanelAssigneeCard';
import { SearchInput } from './drivers/Filters/SearchInput';
import InfiniteList from './drivers/InfiniteList';
import { ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX } from './OrdersView';

const DRIVER_PANEL_SIDEBAR_ANIMATION_IN_MS = 300;
const DRIVER_PANEL_TOP_ROW_VERTICAL_GUTTER_SIZE = 0.5;
const DRIVER_PANEL_TOP_ROW_BORDER_BOTTOM_WIDTH_IN_PX = 1;
const DRIVER_PANEL_TOP_ROW_INNER_GUTTER_SIZE = 1.5;

const assigneeTypeTabs: TabProps<JobAssignmentType>[] = [
  {
    label: t('dispatch.job.assignment_popover.driver'),
    value: JobAssignmentType.DRIVER,
  },
  {
    label: t('dispatch.job.assignment_popover.vendor'),
    value: JobAssignmentType.VENDOR,
  },
];

const OrdersViewDriverPanel = observer(() => {
  const { ordersDispatchStore, userStore } = useStores();

  const [searchQuery, setSearchQuery] = useState({ input: '', applied: '' });
  const companyId = userStore.currentCompanies[0].id || userStore.userCompany.id;

  useEffect(() => {
    setSearchQuery({ input: '', applied: '' });
  }, [companyId]);

  const isSidebarCollapsed = ordersDispatchStore.isSidebarCollapsed;
  const [sidebarAnimationFinished, setSidebarAnimationFinished] = useState(
    Boolean(isSidebarCollapsed),
  );

  const driverPanelEnabledFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.driverPanelEnabled,
  });
  const summaryCountsEnabledFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.summaryCounts,
  });

  const switchAssigneeTabsOrderFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.switchAssigneeTabsOrder,
  });

  const [selectedAssigneeType, setSelectedAssigneeType] = useState<JobAssignmentType>(
    switchAssigneeTabsOrderFeatureFlagEnabled
      ? JobAssignmentType.VENDOR
      : JobAssignmentType.DRIVER,
  );

  const sortedAssigneeTypeTabs = useMemo(() => {
    if (switchAssigneeTabsOrderFeatureFlagEnabled) {
      return [...assigneeTypeTabs].reverse();
    }

    return assigneeTypeTabs;
  }, [switchAssigneeTabsOrderFeatureFlagEnabled]);

  const isReady =
    driverPanelEnabledFeatureFlag.isReady &&
    driverPanelEnabledFeatureFlag.isFeatureFlagEnabled;
  const shouldShowContent = !isSidebarCollapsed && sidebarAnimationFinished;

  const dateString = useMemo(() => {
    if (ordersDispatchStore.filters.startDate) {
      const formattedDate = dayjs
        .tz(ordersDispatchStore.filters.startDate)
        .format('DD-MMM-YYYY');

      return t('dispatch.dispatch_v2.driver_panel.date_status', { date: formattedDate });
    }

    return t('dispatch.dispatch_v2.filters.all_dates');
  }, [ordersDispatchStore.filters.startDate]);

  if (!isReady) {
    return null;
  }

  useEffect(() => {
    const debouncedApplySearchQuery = _.debounce(() => {
      setSearchQuery((state) => ({ ...state, applied: searchQuery.input }));
    }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

    debouncedApplySearchQuery();

    return () => {
      debouncedApplySearchQuery.cancel();
    };
  }, [searchQuery.input]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (_.isNil(isSidebarCollapsed)) {
      return;
    }

    if (isSidebarCollapsed) {
      setSidebarAnimationFinished(false);
    } else {
      setTimeout(() => {
        setSidebarAnimationFinished(true);
      }, DRIVER_PANEL_SIDEBAR_ANIMATION_IN_MS);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isSidebarCollapsed]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          bgcolor: 'white',
          flex: 0,
          pl: isSidebarCollapsed ? 1 : DRIVER_PANEL_TOP_ROW_INNER_GUTTER_SIZE,
          pr: DRIVER_PANEL_TOP_ROW_INNER_GUTTER_SIZE,
          py: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            minHeight: `calc(${ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX}px - ${theme.spacing(DRIVER_PANEL_TOP_ROW_VERTICAL_GUTTER_SIZE * 2)} - ${DRIVER_PANEL_TOP_ROW_BORDER_BOTTOM_WIDTH_IN_PX}px)`,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
            }}
          >
            {shouldShowContent && (
              <Typography
                color={theme.brandV2.colors.treadBlack}
                fontWeight={600}
                variant="body2"
              >
                {dateString}
              </Typography>
            )}

            <ToggleSidebarButton
              isCollapsed={Boolean(isSidebarCollapsed)}
              onClick={() => ordersDispatchStore.toggleSidebar()}
              sx={{ ml: 'auto' }}
            />
          </Box>

          <Box
            sx={{
              display: shouldShowContent ? 'flex' : 'none',
              flexDirection: 'column',
              gap: 1.5,
              mb: 0.5,
            }}
          >
            {summaryCountsEnabledFeatureFlag.isReady &&
              summaryCountsEnabledFeatureFlag.isFeatureFlagEnabled && <SummaryCounts />}
            <Divider sx={{ mx: DRIVER_PANEL_TOP_ROW_INNER_GUTTER_SIZE * -1 }} />
            <Tabs
              onChange={setSelectedAssigneeType}
              selected={selectedAssigneeType}
              tabs={sortedAssigneeTypeTabs}
              sx={{ mt: -0.5 }}
              tabSx={{ '&.MuiTab-root': { minWidth: 'auto' } }}
            />
            <SearchInput
              onChange={(input) => setSearchQuery((state) => ({ ...state, input }))}
              value={searchQuery.input}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: shouldShowContent ? theme.brandV2.colors.treadGray10 : 'white',
          flex: 1,
          py: 1,
        }}
      >
        <DriverPanel
          key={selectedAssigneeType}
          assigneeType={selectedAssigneeType}
          companyId={companyId}
          searchQuery={searchQuery.applied}
          showCards={shouldShowContent}
        />
      </Box>
    </Box>
  );
});

interface DriverPanelProps {
  assigneeType: JobAssignmentType;
  companyId: string;
  searchQuery: string;
  showCards: boolean;
}

function DriverPanel({
  assigneeType,
  companyId,
  searchQuery,
  showCards,
}: DriverPanelProps) {
  const driverPanelQuery = useDriverPanelQuery({
    assigneeType,
    companyId,
    searchQuery,
  });

  if (!showCards) {
    return;
  }

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      <Box
        sx={{
          height: '100%',
          ...(driverPanelQuery.loading ? { opacity: 0.4, pointerEvents: 'none' } : {}),
        }}
      >
        <InfiniteList
          hasNextPage={driverPanelQuery.hasMore}
          itemCount={driverPanelQuery.data.length}
          itemSize={DRIVER_PANEL_DRIVER_CARD_HEIGHT_IN_PX}
          items={driverPanelQuery.data}
          loadMoreItems={driverPanelQuery.fetchMore}
          style={{ scrollbarWidth: 'none' }}
          renderItem={(index, style) => {
            const assignee = driverPanelQuery.data[index];

            if (!assignee) {
              throw new Error('Invalid assignee');
            }

            return (
              <DriverPanelAssigneeCard
                assignee={assignee}
                placement="driver_panel"
                style={style}
              />
            );
          }}
        />
      </Box>

      <LoadingSpinner
        isVisible={driverPanelQuery.loading}
        sx={{ position: 'absolute', inset: 0 }}
      />
    </Box>
  );
}

const DRIVER_PANEL_QUERY_PAGINATION_LIMIT = 25;

type UseDriverPanelQuery = {
  assigneeType: JobAssignmentType;
  companyId: string;
  searchQuery: string;
};

type DriverPanelQueryState = {
  assigneeType: JobAssignmentType;
  cursor?: string;
  data: (DriverBasic | AccountTypeahead)[];
  loading: boolean;
  nextCursor?: string;
  searchValue: string;
};

function useDriverPanelQuery({
  assigneeType,
  companyId,
  searchQuery,
}: UseDriverPanelQuery) {
  const { ordersDispatchStore } = useStores();
  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const { getAccountsByCompanyIdTypeahead } = useAccount();

  const [query, setQuery] = useState<DriverPanelQueryState>({
    assigneeType,
    data: [],
    loading: true,
    searchValue: searchQuery,
  });

  const hasMore = 'nextCursor' in query && !_.isUndefined(query.nextCursor);

  const fetchDrivers = async (
    query: string,
    assigneeType: JobAssignmentType,
    cursor?: string,
  ) => {
    if (!companyId) {
      throw new Error('Company ID not set');
    }

    try {
      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        searchParams: {
          limit: DRIVER_PANEL_QUERY_PAGINATION_LIMIT,
          query: query ?? undefined,
          link: { type: 'after', cursor: cursor ?? '' },
        },
      });

      return response;
    } catch (error) {
      console.error('Unable to fetch drivers', error);
    }
  };

  const fetchVendors = async (query: string, cursor?: string) => {
    if (!companyId) {
      throw new Error('Company ID not set');
    }

    try {
      const response = await getAccountsByCompanyIdTypeahead({
        companyId,
        searchParams: {
          accountTypes: [AccountType.VENDOR],
          limit: DRIVER_PANEL_QUERY_PAGINATION_LIMIT,
          query: query ?? undefined,
          link: { type: 'after', cursor: cursor ?? '' },
        },
      });

      return response;
    } catch (error) {
      console.error('Unable to fetch vendors', error);
    }
  };

  const fetchDriversPage = async () => {
    try {
      const response = await fetchDrivers(
        query.searchValue,
        query.assigneeType,
        query.cursor,
      );

      if (!response?.data) {
        throw new Error('No drivers data returned');
      }

      setQuery((state) => ({
        ...state,
        loading: false,
        data: [...state.data, ...response.data],
        nextCursor: response.pagination.after,
      }));
    } catch (error) {
      console.error('Unable to fetch drivers', error);
    }
  };

  const fetchVendorsPage = async () => {
    try {
      const response = await fetchVendors(query.searchValue, query.cursor);

      if (!response?.data) {
        throw new Error('No vendors data returned');
      }

      setQuery((state) => ({
        ...state,
        loading: false,
        data: [...state.data, ...response.data],
        nextCursor: response.pagination.after,
      }));
    } catch (error) {
      console.error('Unable to fetch vendors', error);
    }
  };

  const fetchMore = useCallback(() => {
    if (query.loading || !hasMore) {
      return;
    }

    setQuery((state) => ({ ...state, loading: true, cursor: state.nextCursor }));
  }, [query.loading, hasMore]);

  useEffect(() => {
    if (query.loading) {
      if (query.assigneeType === JobAssignmentType.VENDOR) {
        fetchVendorsPage();
      } else {
        fetchDriversPage();
      }
    }
  }, [query.loading, query.assigneeType]);

  useEffect(() => {
    ordersDispatchStore.clearDriverPanelAssignees();

    setQuery({
      assigneeType,
      data: [],
      loading: true,
      searchValue: searchQuery,
    });
  }, [searchQuery, assigneeType]);

  useEffect(() => {
    ordersDispatchStore.clearDriverPanelAssignees();

    setQuery({
      assigneeType,
      data: [],
      loading: true,
      searchValue: '',
    });
  }, [companyId]);

  useEffect(() => {
    ordersDispatchStore.setDriverPanelAssignees(query.data);
  }, [query.data]);

  return {
    data: query.data,
    fetchMore,
    hasMore,
    loading: query.loading,
  };
}

export default OrdersViewDriverPanel;
