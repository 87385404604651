import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import React from 'react';

import theme from '~theme/AppTheme';

interface Props {
  title: string;
  action: () => void;
  disabled?: boolean;
  linkColor?: string;
  underline?: boolean;
  sx?: SxProps;
}
const PseudoLink = ({
  title,
  action,
  linkColor = theme.palette.info.main,
  disabled = false,
  underline = true,
  sx = {},
}: Props) => {
  const theme = useTheme();
  return (
    <Typography
      component={'span'}
      onClick={action}
      sx={{
        display: 'block',
        ...sx,
      }}
    >
      <Typography
        component={'span'}
        variant={'body2'}
        sx={{
          cursor: 'pointer',
          textDecoration: underline ? 'underline' : 'none',
          color: !disabled ? linkColor : theme.palette.text.disabled,

          '&:hover': {
            textDecoration: 'none',
            textDecorationStyle: 'none',
          },
        }}
      >
        {title}
      </Typography>
    </Typography>
  );
};

export { PseudoLink };
