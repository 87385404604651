import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { OrderState } from '@treadinc/horizon-api-spec';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import {
  ActionsColumn,
  BottomColumn,
  CheckboxColumn,
  MainColumn,
  makeOrderDispatchCardTemplateColumns,
} from '~components/Order/ordersDispatchColumns';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import {
  ExpandedState,
  ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
} from '~store/OrdersDispatchStore';
import theme from '~theme/AppTheme';

import OrderDispatchCardExpandedBlock from './OrderDispatchCardExpandedBlock';

interface OrderDispatchCardProps {
  checkedJobs?: string[];
  expandedState?: string;
  isChecked?: boolean;
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
  onAcceptOrder: () => void;
  onBulkAssignMultipleClick?: () => void;
  onCheckedStateChange?: (orderId: string, jobId?: string) => void;
  onCloneOrderClick: () => void;
  onCreateJobFromOrderClick: () => void;
  onEditClick: () => void;
  onExpandedStateChange: (orderId: string) => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onRejectOrder: () => void;
  onTextAllDriversClick: () => void;
  order: Order;
  companyHasChildCompanies: boolean;
}

const OrderDispatchCard = observer(
  ({
    checkedJobs,
    expandedState = ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
    isChecked,
    nonRoutableAlertsFeatureFlagEnabled,
    onAcceptOrder,
    onBulkAssignMultipleClick,
    onCheckedStateChange,
    onCloneOrderClick,
    onCreateJobFromOrderClick,
    onEditClick,
    onExpandedStateChange,
    onOrderStateChange,
    onRejectOrder,
    onTextAllDriversClick,
    order,
  }: OrderDispatchCardProps) => {
    const isOpen = expandedState === ExpandedState.OPEN;

    const { ordersDispatchStore } = useStores();
    const handleCheckedOrderStateChange = useCallback(() => {
      onCheckedStateChange?.(order.id);
    }, [onExpandedStateChange, order.id]);

    const handleCheckedJobStateChange = useCallback(
      (jobId: string) => {
        onCheckedStateChange?.(order.id, jobId);
      },
      [onExpandedStateChange, order.id],
    );

    const handleExpandedStateChange = useCallback(() => {
      onExpandedStateChange(order.id);
    }, [onExpandedStateChange, order.id]);

    return (
      <Box
        bgcolor="white"
        border={`solid 1px ${theme.brandV2.colors.treadGray7}`}
        borderRadius={theme.spacing()}
        boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.20)"
        width="100%"
      >
        <Box
          onClick={handleExpandedStateChange}
          sx={{
            display: 'grid',
            gridTemplateColumns: makeOrderDispatchCardTemplateColumns(false),
            p: 1,
            rowGap: 1,
          }}
        >
          <CheckboxColumn
            isChecked={isChecked}
            isDisabled={ordersDispatchStore.isLoadingOrderJobs.get(order.id) ?? true}
            onCheckedStateChange={handleCheckedOrderStateChange}
          />

          <MainColumn order={order} />

          <ActionsColumn
            nonRoutableAlertsFeatureFlagEnabled={nonRoutableAlertsFeatureFlagEnabled}
            onAccept={onAcceptOrder}
            onBulkAssignMultiple={onBulkAssignMultipleClick}
            onCancel={() => onOrderStateChange(OrderState.CANCELED)}
            onCloneOrder={onCloneOrderClick}
            onEdit={onEditClick}
            onOrderStateChange={onOrderStateChange}
            onReject={onRejectOrder}
            onTextAllDrivers={onTextAllDriversClick}
            order={order}
          />

          <BottomColumn order={order} />
        </Box>

        <Collapse mountOnEnter unmountOnExit in={isOpen}>
          <Box borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
            <OrderDispatchCardExpandedBlock
              checkedJobs={checkedJobs}
              onCheckedJobStateChange={handleCheckedJobStateChange}
              onCreateJobFromOrder={onCreateJobFromOrderClick}
              order={order}
            />
          </Box>
        </Collapse>
      </Box>
    );
  },
);

export default OrderDispatchCard;
