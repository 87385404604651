import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Mail from '@mui/icons-material/Mail';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Sms from '@mui/icons-material/Sms';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  AuthMethod,
  CompanyShareableType,
  ModelError_Item,
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { DriverForm, DriverFormValues } from '~components/DriverForm/DriverForm';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { PageTitle } from '~components/typographyTitles/PageTitle';
import {
  UserForm,
  UserFormHandler,
  UserFormStateChangeProps,
  UserValidationDTO,
} from '~components/UserForm/UserForm';
import UserNotificationsPreferenceForm, {
  UserNotificationsPreferenceDTO,
  UserNotificationsPreferenceHandler,
} from '~components/UserForm/UserNotificationsPreferenceForm';
import { UserTypesFilter } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { useCompany } from '~hooks/useCompany';
import { CompanyShare } from '~hooks/useCompanyShares';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { useDriverForm } from '~hooks/useDriverForm/useDriverForm';
import { Driver, useDrivers } from '~hooks/useDrivers';
import { BasicEquipment, EquipmentTypeahead } from '~hooks/useEquipment/models';
import { User, useUserRoles, useUsers } from '~hooks/useUsers';
import { CompanyShareFormData } from '~pages/Settings/Administration/EquipmentManagement/EquipmentManagement';
import {
  ExternalEquipmentDTO,
  ExternalEquipmentForm,
  ExternalEquipmentFormHandlers,
} from '~pages/Settings/Administration/EquipmentManagement/ExternalEquipmentForm';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { getConsolidatedEquipmentName } from '~utils/drivers/driver-utils';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';
import { isActionClicked } from '~utils/utilFunctions';

import { EquipmentSelector } from './EquipmentSelector';

export interface InvitesToSendState {
  email: { isDisabled: boolean; shouldSend: boolean };
  sms: { isDisabled: boolean; shouldSend: boolean };
}

interface DialogState {
  isOpen: boolean;
  availableAuthMethods: AuthMethod[];
}

const renderEquipmentDetails = (
  driver: Driver,
  onClick: (equipment: BasicEquipment) => void,
) => {
  const theme = useTheme();

  if (!driver.equipment) {
    return (
      <Typography variant="body2">
        {t('administration.equipment.no_truck_set')}
      </Typography>
    );
  }

  const { companyShare, name } = driver.equipment;

  if (companyShare) {
    return companyShare.externalId ? (
      <Typography variant="body2">{companyShare.externalId}</Typography>
    ) : (
      <PseudoLink
        title={t('administration.equipment.add_number_name')}
        action={() => onClick(driver.equipment as BasicEquipment)}
        linkColor={theme.brandV2.colors.treadOrange}
      />
    );
  }

  return <Typography variant="body2">{name}</Typography>;
};

export const UserManagementDataGrid = observer(() => {
  const theme = useTheme();
  const { treatments, isReady } = useSplitTreatments({
    names: [
      FeatureFlags.simplifiedSettingsTabs,
      FeatureFlags.driverEquipmentEnhancements,
    ],
  });
  const simplifiedSettingsTabsFeatureFlag =
    treatments[FeatureFlags.simplifiedSettingsTabs];
  const driverEquipmentEnhancementsFeatureFlag =
    treatments[FeatureFlags.driverEquipmentEnhancements];
  const isDriverEquipmentEnhancementsEnabled =
    isReady && driverEquipmentEnhancementsFeatureFlag.treatment === 'on';
  const location = useLocation();
  const isDriversPage = location.pathname.includes('drivers');
  const {
    driverDialogRef,
    driverFormIsDirty,
    isDriverDialogOpen,
    setIsDriverDialogOpen,
    driverInvitesToSend,
    setDriverInvitesToSend,
    onDriverFormStateChange,
    onChangeDriverInvite,
    createNewDriver,
  } = useDriverForm();

  const [userFormDialogState, setUserFormDialogState] = useState<DialogState>({
    isOpen: false,
    availableAuthMethods: [],
  });
  const [isSharedEquipmentDialogOpen, setIsSharedEquipmentDialogOpen] = useState(false);

  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [selectedDriver, setSelectedDriver] = useState<Driver | undefined>();
  const [selectedEquipment, setSelectedEquipment] = useState<
    BasicEquipment | undefined
  >();
  const [userFormIsDirty, setUserFormIsDirty] = useState(false);
  const [sharedEquipmentFormIsDirty, setSharedEquipmentFormIsDirty] = useState(false);
  const [
    userNotificationsPreferenceFormIsDirty,
    setUserNotificationsPreferenceFormIsDirty,
  ] = useState(false);

  const formIsDirty =
    userFormIsDirty || driverFormIsDirty || userNotificationsPreferenceFormIsDirty;

  const [invitesToSend, setInvitesToSend] = useState<InvitesToSendState>();
  const [companyShares, setCompanyShares] = useState<CompanyShare[]>([]);
  const { userStore, toasterStore, companyAssetsStore } = useStores();
  const {
    getCompanyUsers,
    getUserById,
    deleteUser,
    updateUser,
    createUser,
    sendInvitation,
    isLoadingAllUsers,
    isUpdating,
  } = useUsers();
  const { getUserRoles } = useUserRoles();
  const { getCompanyById } = useCompany();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const {
    createDriverByCompanyId,
    getDriverById,
    getDriversByCompanyId,
    isLoading: isLoadingDrivers,
  } = useDrivers();
  const {
    getCompanySharesByCompanyId,
    createCompanyShareByCompanyId,
    deleteCompanyShare,
    updateCompanyShare,
    isUpdating: isUpdatingCompanyShares,
  } = useCompanyShares();
  const isLoading = isLoadingAllUsers || isLoadingDrivers;
  const [userTypeFilter, setUserTypeFilter] = useState<UserTypesFilter>(
    UserTypesFilter.USERS,
  );
  const isShowingUsers = userTypeFilter === UserTypesFilter.USERS && !isDriversPage;
  const isExternalDriver =
    isDriversPage || userTypeFilter === UserTypesFilter.EXTERNAL_DRIVERS;
  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;

  useEffect(() => {
    getUserRoles();
  }, []);

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const loadingDriversRef = useRef(isLoadingDrivers);
  useEffect(() => {
    loadingDriversRef.current = isLoadingDrivers;
  }, [isLoadingDrivers]);

  useEffect(() => {
    if (selectedId) {
      getCompanySharesByCompanyId({
        companyId,
        company_shareable_type: 'User',
        company_shareable_id: selectedId,
        sender_company_id: companyId,
        callBack: setCompanyShares,
      });
    }

    if (selectedEquipment?.companyShare?.id) {
      getCompanySharesByCompanyId({
        companyId,
        company_shareable_type: 'Equipment',
        company_shareable_id: selectedEquipment?.id,
        sender_company_id: companyId,
        callBack: setCompanyShares,
      });
    }
  }, [companyId, selectedId, selectedEquipment]);

  const userRolesOptions = userStore.userRoles;
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };

  const onChangeInvite = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as 'email' | 'sms';
    const checked = event.target.checked;

    setInvitesToSend((state) => {
      const newState = _.cloneDeep(state);

      if (newState) {
        newState[name] = {
          ...newState[name],
          shouldSend: checked,
        };
      }

      return newState;
    });
  }, []);

  const modalDialogRef = useRef<ModalDialogHandler>(null);
  const updateUserDialogRef = useRef<UserFormHandler>(null);
  const sharedEquipmentDialogRef = useRef<ExternalEquipmentFormHandlers>(null);
  const userNotificationsPreferenceRef = useRef<UserNotificationsPreferenceHandler>(null);

  const handleClose = useCallback(() => {
    setUserFormDialogState((state) => ({ ...state, isOpen: false }));
    setIsSharedEquipmentDialogOpen(false);
    setIsDriverDialogOpen(false);
    setSelectedId(null);
    setSelectedUser(undefined);
    setSelectedDriver(undefined);
    setSelectedEquipment(undefined);
    setCompanyShares([]);
    setAsyncErrors([]);
  }, []);

  const handleSelectSharedEquipment = (equipment: BasicEquipment) => {
    setSelectedEquipment(equipment);
    setIsSharedEquipmentDialogOpen(true);
  };

  const handleErrors = (error: AxiosError<ModelError_Response>) => {
    setAsyncErrors(error.response?.data.error.errors || []);
  };

  const handleDeleteCompanyShares = (
    originalCompanyShares: CompanyShare[],
    companyShares: CompanyShareFormData[],
  ) => {
    const originalCompanySharesExcludingCurrentCompany = originalCompanyShares.filter(
      (companyShare) => companyShare.receiverCompany.id !== companyId,
    );

    const deletedCompanyShares = originalCompanySharesExcludingCurrentCompany.filter(
      (originalCompanyShare) =>
        !companyShares.some(
          (companyShare: { id: string | undefined }) =>
            companyShare.id === originalCompanyShare.id,
        ),
    );

    if (deletedCompanyShares?.length) {
      deletedCompanyShares.forEach((companyShare) => {
        deleteCompanyShare(companyShare.id as string);
      });
    }
  };

  const handleUpdateCompanyShare = async (data: {
    externalId?: string;
    ticketMatchId?: string;
  }) => {
    try {
      await updateCompanyShare({
        companyShareId: selectedEquipment?.companyShare?.id as string,
        external_id: data.externalId || '',
        ticket_match_id: data.ticketMatchId || '',
      });
    } catch (error) {
      handleErrors(error as AxiosError<ModelError_Response>);
      // We need to throw the error to prevent the onSuccess function from being called in the onSubmitCallback
      throw error;
    }
  };

  const editRow = async (id: string) => {
    setSelectedId(id);
    let fetchedUser: User | Driver | undefined;

    if (isExternalDriver || isDriversPage) {
      fetchedUser = await getDriverById(id);
      setSelectedDriver(fetchedUser as Driver);
      setUserFormDialogState({
        isOpen: true,
        availableAuthMethods: [],
      });
    } else {
      fetchedUser = await getUserById(id);
      setSelectedUser(fetchedUser as User);

      if (fetchedUser?.company?.id) {
        await getCompanyById({
          id: String(fetchedUser?.company?.id),
          callBack: (company) => {
            setUserFormDialogState({
              isOpen: true,
              availableAuthMethods: company.authMethods,
            });

            if ((fetchedUser as User)?.isActiveMember) {
              setInvitesToSend(undefined);
            } else {
              setInvitesToSend({
                email: { isDisabled: true, shouldSend: false },
                sms: { isDisabled: true, shouldSend: false },
              });
            }
          },
        });
      }
    }
  };

  const createNewUser = async () => {
    setSelectedId(null);

    await getCompanyById({
      id: String(companyId),
      callBack: (company) => {
        setUserFormDialogState({
          isOpen: true,
          availableAuthMethods: company.authMethods,
        });
        setInvitesToSend({
          email: { isDisabled: true, shouldSend: false },
          sms: { isDisabled: true, shouldSend: false },
        });
      },
    });
  };

  const deleteUserCallback = () => {
    if (selectedId) {
      deleteUser(selectedId, () => {
        modalDialogRef.current?.close();
        toasterStore.push(alert($t('user.user_deleted'), AlertTypes.success));
      });
    }
  };

  const resendInvite = (rowId: string, method: 'email' | 'sms') => {
    if (rowId) {
      sendInvitation(rowId, method).then(() => {
        toasterStore.push(alert($t('user.invitation_sent'), AlertTypes.success));
      });
    }
  };

  const onSuccess = (user?: User) => {
    handleClose();
    if (isDriversPage) {
      getDriversByCompanyId({
        companyId,
        ...(!isDriverEquipmentEnhancementsEnabled && {
          shared: true,
        }),
      });
    } else if (!isShowingUsers) {
      getDriversByType();
    }
    const name = `${user?.firstName} ${user?.lastName}`;
    toasterStore.push(
      alert(
        selectedId
          ? $t('user.user_updated', { name })
          : $t('user.user_created', { name }),
        AlertTypes.success,
      ),
    );
  };

  const onSharedEquipmentSubmitCallback = (data: ExternalEquipmentDTO) => {
    const originalCompanyShares = companyShares;
    const submittedCompanyShares = data.companyShares as CompanyShareFormData[];

    handleDeleteCompanyShares(originalCompanyShares, submittedCompanyShares);

    const promises = [];

    if (submittedCompanyShares.length) {
      submittedCompanyShares.forEach((companyShare) => {
        if (companyShare.id) {
          // Update existing company share
          promises.push(
            updateCompanyShare({
              companyShareId: companyShare.id,
              dispatchable: companyShare.dispatchable.id === 'dispatchable',
            }),
          );
        } else {
          // Create new company share
          promises.push(
            createCompanyShareByCompanyId({
              companyId: companyId,
              company_shareable_id: selectedEquipment?.id as string,
              company_shareable_type: 'Equipment' as CompanyShareableType,
              receiver_company_id: companyShare.receiverCompany.id,
              dispatchable: companyShare.dispatchable.id === 'dispatchable',
            }),
          );
        }
      });
    }

    promises.push(
      handleUpdateCompanyShare({
        externalId: data.externalId,
        ticketMatchId: data.ticketMatchId,
      }),
    );

    Promise.all(promises)
      .then(() => {
        // We don't update the driver object directly when we update a company share
        // Fetch the drvers in this instance to show the most recent data in the table
        getDriversByCompanyId({
          companyId,
        });

        onSharedEquipmentUpdateSuccess(data);
      })
      .catch((error) => {
        console.error('Error occurred during the operation:', error);
      });
  };

  const onSharedEquipmentUpdateSuccess = (data: ExternalEquipmentDTO) => {
    const name = `${data?.externalId || data?.name}`;

    if (!toasterStore.alerts.length) {
      toasterStore.push(
        alert(
          selectedId
            ? $t('administration.equipment.equipment_updated', { name })
            : $t('administration.equipment.equipment_updated', { name }),
          AlertTypes.success,
        ),
      );

      // Only close the modal if the action is successful
      handleClose();
    }
  };

  const onSubmitCallback = async (
    data: UserValidationDTO,
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ) => {
    const shouldSendEmailInvite = Boolean(
      !invitesToSend?.email.isDisabled && invitesToSend?.email.shouldSend,
    );
    const shouldSendSmsInvite = Boolean(
      !invitesToSend?.sms.isDisabled && invitesToSend?.sms.shouldSend,
    );

    if (selectedId) {
      try {
        if (!selectedDriver) {
          await updateUser(
            { id: selectedId, ...data } as User,
            userNotificationsPreferenceData,
          );
        }
        const companySharesPresentOnModalOpen = companyShares;
        const companySharesPresentOnSubmit = data.companyShares;

        // Generates a list of company shares created this session that we need to create
        const companySharesToCreate = companySharesPresentOnSubmit?.filter(
          (submittedCompanyShare) =>
            !companySharesPresentOnModalOpen.some(
              (existingCompanyShare) =>
                existingCompanyShare.receiverCompany.id === submittedCompanyShare.id,
            ),
        );

        // Generates a list of company shares that were removed this session that we need to delete
        const companySharesToDelete = companySharesPresentOnModalOpen.filter(
          (existingCompanyShare) =>
            companySharesPresentOnSubmit &&
            !companySharesPresentOnSubmit.some(
              (submittedCompanyShare) =>
                existingCompanyShare.receiverCompany.id === submittedCompanyShare.id,
            ),
        );

        if (companySharesToCreate && companySharesToCreate.length) {
          companySharesToCreate.forEach((companyShare) => {
            createCompanyShareByCompanyId({
              companyId,
              company_shareable_id: selectedId,
              receiver_company_id: companyShare.id as string,
              company_shareable_type: 'User' as CompanyShareableType,
            });
          });
        }

        if (companySharesToDelete && companySharesToDelete.length) {
          companySharesToDelete.forEach((companyShare) => {
            deleteCompanyShare(companyShare.id as string);
          });
        }
        if (shouldSendEmailInvite) {
          await sendInvitation(selectedId, 'email');
        }

        if (shouldSendSmsInvite) {
          await sendInvitation(selectedId, 'sms');
        }

        onSuccess({ id: selectedId, ...data } as User);
      } catch (error) {
        const e = error as AxiosError<ModelError_Response>;

        setAsyncErrors(e.response?.data.error.errors || []);
        throw new Error('Failed to update user');
      }
    } else {
      try {
        const newUser = await createUser(data as User, {
          email: shouldSendEmailInvite,
          sms: shouldSendSmsInvite,
        });
        const submittedCompanyShares = data.companyShares;

        if (newUser && submittedCompanyShares && submittedCompanyShares.length) {
          submittedCompanyShares.forEach((companyShare) => {
            createCompanyShareByCompanyId({
              companyId,
              receiver_company_id: companyShare.id as string,
              company_shareable_id: newUser.id as string,
              company_shareable_type: 'User' as CompanyShareableType,
            });
          });
        }
        onSuccess(data as User);
      } catch (error) {
        const e = error as AxiosError<ModelError_Response>;

        setAsyncErrors(e.response?.data.error.errors || []);
        throw new Error('Failed to create user');
      }
    }
  };

  const onSubmitForm = async () => {
    const userData = await new Promise<UserValidationDTO | undefined>((resolve) => {
      updateUserDialogRef.current?.submit((data) => resolve(data));
    });

    const userNotificationsPreferenceData = await new Promise<
      UserNotificationsPreferenceDTO | undefined
    >((resolve) => {
      if (selectedUser?.id && userNotificationsPreferenceRef.current) {
        userNotificationsPreferenceRef.current?.onSubmit?.((data) => resolve(data));
      } else {
        resolve(undefined);
      }
    });

    if (userData) {
      await onSubmitCallback(userData, userNotificationsPreferenceData);
    }
  };

  const onSubmitSharedEquipmentForm = async () => {
    const sharedEquipmentData = await new Promise<ExternalEquipmentDTO | undefined>(
      (resolve) => {
        sharedEquipmentDialogRef.current?.submit((data) => resolve(data));
      },
    );

    if (sharedEquipmentData) {
      await onSharedEquipmentSubmitCallback(sharedEquipmentData);
    }
  };

  const onDriverSubmitCallback = async (
    data: DriverFormValues,
    userNotificationsPreferenceData?: InvitesToSendState,
  ) => {
    const shouldSendEmailInvite = Boolean(
      !userNotificationsPreferenceData?.email.isDisabled &&
        userNotificationsPreferenceData?.email.shouldSend,
    );
    const shouldSendSmsInvite = Boolean(
      !userNotificationsPreferenceData?.sms.isDisabled &&
        userNotificationsPreferenceData?.sms.shouldSend,
    );
    const invitesToSend = { email: shouldSendEmailInvite, sms: shouldSendSmsInvite };

    try {
      await createDriverByCompanyId(data, invitesToSend);
      onSuccess(data as User);
    } catch (error) {
      const e = error as AxiosError<ModelError_Response>;

      setAsyncErrors(e.response?.data.error.errors || []);
      throw new Error('Failed to create driver');
    }
  };

  const onSubmitDriverForm = async () => {
    const driverData = await new Promise<DriverFormValues | undefined>((resolve) => {
      driverDialogRef.current?.submit((data) => resolve(data));
    });

    if (driverData) {
      await onDriverSubmitCallback(driverData, driverInvitesToSend);
    }
  };

  const onSharedEquipmentFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setSharedEquipmentFormIsDirty(isDirty);
  };

  const onFormStateChange = ({
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: UserFormStateChangeProps) => {
    setUserFormIsDirty(isDirty);

    if (invitesToSend) {
      setInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState.email = {
            ...newState.email,
            isDisabled: !hasValidEmail,
          };

          newState.sms = {
            ...newState.sms,
            isDisabled: !hasValidPhone,
          };
        }

        return newState;
      });
    }

    if (driverInvitesToSend) {
      setDriverInvitesToSend((state) => {
        const newState = _.cloneDeep(state);

        if (newState) {
          newState.email = {
            ...newState.email,
            isDisabled: !hasValidEmail,
          };

          newState.sms = {
            ...newState.sms,
            isDisabled: !hasValidPhone,
          };
        }

        return newState;
      });
    }
  };

  const onUserNotificationsPreferenceFormStateChange = useCallback(
    ({ isDirty }: FormStateChangeProps) => {
      setUserNotificationsPreferenceFormIsDirty(isDirty);
    },
    [],
  );

  // This fixes issues with the data grid reordering rows when the user data is updated
  const [rows, setRows] = useState<User[] | Driver[]>([]);
  useEffect(() => {
    const newRows =
      isDriversPage || !isShowingUsers ? companyAssetsStore.drivers : userStore.users;
    setRows(newRows);
  }, [
    userStore.users,
    companyAssetsStore.drivers,
    userTypeFilter,
    isDriversPage,
    isShowingUsers,
  ]);

  const totalUserCount = useMemo(() => userStore.users.length, [userStore.users]);
  const totalDriverCount = useMemo(
    () => companyAssetsStore.drivers.length,
    [companyAssetsStore.drivers],
  );

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore.getPermissions()]);

  const isViewingChildCompanyExternalDrivers =
    (userTypeFilter === UserTypesFilter.EXTERNAL_DRIVERS || isDriversPage) &&
    !userStore.isCurrentCompanyActive;

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'lastName',
          headerName: $t('form_fields.last_name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'firstName',
          headerName: $t('form_fields.first_name'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const user = params.row as User;
            return `${user.firstName}`;
          },
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'company',
          headerName: $t('form_fields.company'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((item) => item.company?.legalName))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return (params.row as User).company?.legalName || '';
          },
        },
        {
          field: 'roles',
          headerName: isShowingUsers
            ? $t('form_fields.role')
            : $t('form_fields.equipment'),
          flex: 1,
          height: 'auto',
          valueGetter: (params: GridValueGetterParams) => {
            if (isShowingUsers) {
              const user = params.row as User;

              return user.userRoles?.map((role) => role.name).join(', ');
            }

            const driver = params.row as Driver;

            return getConsolidatedEquipmentName(driver);
          },
          renderCell: (params: GridValueGetterParams) => {
            const user = params.row as User;
            const isInternalDriver =
              user.company?.id === getEffectiveUserCompanyId(userStore);

            if (isShowingUsers) {
              return <Typography variant="body2">{params.value}</Typography>;
            } else if (!isDriverEquipmentEnhancementsEnabled && isDriversPage) {
              const driver = params.row as Driver;
              return (
                <Typography variant="body2">
                  {getConsolidatedEquipmentName(driver)}
                </Typography>
              );
            } else if (isDriverEquipmentEnhancementsEnabled && !isInternalDriver) {
              const driver = params.row as Driver;
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box>
                    {renderEquipmentDetails(driver, handleSelectSharedEquipment)}

                    {driver?.equipment?.equipmentType?.name && (
                      <Typography variant="body2">
                        <Typography
                          variant={'body2'}
                          component={'span'}
                          sx={{ color: theme.brandV2.colors.treadGray3 }}
                        >
                          Type:
                        </Typography>{' '}
                        {driver.equipment.equipmentType.name}
                      </Typography>
                    )}
                  </Box>

                  {driver?.equipment && driver?.equipment?.companyShare && (
                    <IconButton
                      disabled={!driver.equipment}
                      onClick={() =>
                        handleSelectSharedEquipment(driver.equipment as BasicEquipment)
                      }
                      sx={{
                        opacity: !driver.equipment ? 0.2 : 1,
                        pointerEvents: !driver.equipment ? 'none' : 'auto',
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Box>
              );
            }

            return (
              <EquipmentSelector
                defaultEquipment={user.equipment}
                disabled={!userPermissions.canEditUser || isUpdating}
                onSelect={(equipment: Nullable<EquipmentTypeahead>) => {
                  user.equipment = equipment as BasicEquipment;
                  updateUser(user).then(() => {
                    // We don't want to show success toast if we're updating the equipment for a driver
                    getDriversByType();
                  });
                }}
                selectedCompanyId={companyId}
              />
            );
          },
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'phone',
          headerName: $t('form_fields.phone'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => (
            <div
              title={params.value}
              className={'MuiDataGrid-cellContent'}
              role={'presentation'}
              data-sentry-mask
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          flex: 1,
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography variant="body2">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'another.action.navigation',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => {
            return isShowingUsers ? (
              <HeaderNavigation
                count={totalUserCount}
                loading={loadingRef.current}
                pagination={userStore.userManagementPagination}
                callback={(link, searchQuery) => {
                  return getCompanyUsers({
                    companyId,
                    limit: userStore.userManagementPagination.limit,
                    searchQuery,
                    link,
                  });
                }}
                altText={`${$t('actions.actions')}`}
              />
            ) : (
              <HeaderNavigation
                count={totalDriverCount}
                loading={loadingDriversRef.current}
                pagination={companyAssetsStore.driversPagination}
                callback={(link, searchQuery) => {
                  return getDriversByCompanyId({
                    companyId,
                    link,
                    ...(!isDriverEquipmentEnhancementsEnabled && {
                      shared: isExternalDriver,
                    }),
                    searchQuery,
                  });
                }}
                altText={`${$t('actions.actions')}`}
              />
            );
          },
          renderCell: (params: GridValueGetterParams) => {
            const options = [];

            if (
              !isExternalDriver &&
              !isDriversPage &&
              !params.row.isActiveMember &&
              params.row.email
            ) {
              options.push({
                title: t('user.resend_email_invitation'),
                icon: <Mail />,
                callBack: () => resendInvite(params.row.id, 'email'),
              });
            }

            if (
              !isExternalDriver &&
              !isDriversPage &&
              !params.row.isActiveUser &&
              params.row.phone
            ) {
              options.push({
                title: t('user.resend_sms_invitation'),
                icon: <Sms />,
                callBack: () => resendInvite(params.row.id, 'sms'),
              });
            }

            return (
              <>
                {userPermissions.canEditUser && !isViewingChildCompanyExternalDrivers && (
                  <IconButton size="small" onClick={() => editRow(params.row.id)}>
                    <Edit />
                  </IconButton>
                )}
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows, userPermissions, isShowingUsers],
  );

  const initialState = useMemo(
    () => ({
      columns: {},
    }),
    [],
  );

  const userTypeFilters = useMemo(() => {
    const filters = [
      {
        value: UserTypesFilter.USERS,
        label: 'user.users',
      },
      {
        value: UserTypesFilter.INTERNAL_DRIVERS,
        label: 'user.internal_drivers',
      },
    ];

    if (
      !isDriversPage &&
      isReady &&
      simplifiedSettingsTabsFeatureFlag.treatment !== 'on'
    ) {
      filters.push({
        value: UserTypesFilter.EXTERNAL_DRIVERS,
        label: 'user.external_drivers',
      });
    }

    return filters;
  }, [isReady, simplifiedSettingsTabsFeatureFlag.treatment, isDriversPage]);

  const getDriversByType = async (link?: PaginationLink) => {
    await getDriversByCompanyId({
      companyId,
      link,
      ...(!isDriverEquipmentEnhancementsEnabled && {
        shared: isExternalDriver,
      }),
      ...(isDriverEquipmentEnhancementsEnabled &&
        !isDriversPage && {
          shared: false,
        }),
      searchQuery: searchValue,
    });
  };

  useEffect(() => {
    if (isDriversPage) {
      getDriversByCompanyId({
        companyId,
        ...(!isDriverEquipmentEnhancementsEnabled && {
          shared: true,
        }),
        searchQuery: searchValue,
      });
    } else if (isShowingUsers) {
      getCompanyUsers({
        companyId,
        searchQuery: searchValue,
      });
    } else {
      getDriversByType();
    }
  }, [isShowingUsers, searchValue, userTypeFilter, companyId, isDriversPage]);

  return (
    <>
      <Box data-test-id={'users-data-grid'} width={'100%'}>
        {rows && columns && (
          <Box sx={{ mr: -3 }}>
            {!isDriversPage ? (
              <Box sx={{ mb: 2 }}>
                <StatusTabFilters
                  value={userTypeFilter}
                  onChange={(tab) => setUserTypeFilter(tab)}
                  filters={userTypeFilters}
                />
              </Box>
            ) : (
              <PageTitle text={`${t('administration.drivers.title')}`} sx={{ mb: 2 }} />
            )}
            <DataGrid
              id="users-grid"
              sx={{ maxWidth: `calc(100% - ${theme.spacing(3)})` }}
              columns={columns}
              rows={rows as unknown as Record<string, User | Driver>[]}
              loading={isLoading}
              initialState={initialState}
              onChangeFilter={onChangeQuickFilter}
              headerActionsComponent={
                <>
                  {userPermissions.canCreateUser && (
                    <Box display={'flex'}>
                      <Button
                        size="medium"
                        color="primary"
                        onClick={isDriversPage ? createNewDriver : createNewUser}
                        startIcon={<Add />}
                        disabled={!companyId}
                      >
                        {t(isDriversPage ? 'user.create_driver' : 'user.create_user')}
                      </Button>
                    </Box>
                  )}
                </>
              }
            />
          </Box>
        )}
      </Box>

      <Dialog open={isDriverDialogOpen} onClose={handleClose}>
        <DialogHeader closeCallBack={handleClose} title={t(`user.create_driver`)} />
        <DialogContent sx={{ my: 4 }}>
          <DriverForm
            ref={driverDialogRef}
            onFormStateChange={onDriverFormStateChange}
            errors={asyncErrors}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {driverInvitesToSend && (
            <Box display="flex" gap={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="email"
                      onChange={onChangeDriverInvite}
                      checked={driverInvitesToSend.email.shouldSend}
                      color={'primary'}
                      disabled={isLoadingDrivers || driverInvitesToSend.email.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_email_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="sms"
                      onChange={onChangeDriverInvite}
                      checked={driverInvitesToSend.sms.shouldSend}
                      color={'primary'}
                      disabled={isLoadingDrivers || driverInvitesToSend.sms.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_sms_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!formIsDirty || isLoadingDrivers}
              loading={isLoadingDrivers}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitDriverForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isLoadingDrivers ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(`actions.create`)}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isLoadingDrivers}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        open={userFormDialogState.isOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(`user.${selectedId ? 'update_user' : 'create_user'}`)}
        />
        <DialogContent sx={{ my: 4 }}>
          <UserForm
            ref={updateUserDialogRef}
            editable={true}
            defaultUser={selectedId ? selectedUser : null}
            defaultDriver={selectedDriver}
            onFormStateChange={onFormStateChange}
            userRolesOptions={userRolesOptions}
            availableAuthMethods={userFormDialogState.availableAuthMethods}
            allowSharing={true}
            companyShares={companyShares}
            errors={asyncErrors}
          />

          {selectedUser?.id && (
            <UserNotificationsPreferenceForm
              key={selectedUser?.id}
              ref={userNotificationsPreferenceRef}
              editable
              onFormStateChange={onUserNotificationsPreferenceFormStateChange}
              user={selectedUser}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          {!selectedDriver && invitesToSend && (
            <Box display="flex" gap={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="email"
                      onChange={onChangeInvite}
                      checked={invitesToSend.email.shouldSend}
                      color={'primary'}
                      disabled={isUpdating || invitesToSend.email.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_email_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ padding: 0.5, marginTop: '-1px' }}
                      size="small"
                      name="sms"
                      onChange={onChangeInvite}
                      checked={invitesToSend.sms.shouldSend}
                      color={'primary'}
                      disabled={isUpdating || invitesToSend.sms.isDisabled}
                    />
                  }
                  label={
                    <Typography fontSize="12px">
                      {$t('user.form.send_sms_invitation')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={!formIsDirty || isUpdating}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(`actions.${selectedId ? 'update' : 'create'}`)}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={isSharedEquipmentDialogOpen} onClose={handleClose}>
        <DialogHeader
          closeCallBack={handleClose}
          title={t('administration.equipment.update_external_truck')}
        />
        <DialogContent sx={{ my: 4 }}>
          <ExternalEquipmentForm
            ref={sharedEquipmentDialogRef}
            defaultEquipment={selectedEquipment}
            companyShares={companyShares}
            onFormStateChange={onSharedEquipmentFormStateChange}
            selectedCompanyId={companyId}
            errors={asyncErrors}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={isUpdatingCompanyShares || !sharedEquipmentFormIsDirty}
              loading={isUpdatingCompanyShares}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitSharedEquipmentForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdatingCompanyShares ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t('actions.update')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdatingCompanyShares}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        title={$t('user.delete_user_title')}
        content={$t('user.delete_user_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteUserCallback}
        loading={isUpdating}
      />
    </>
  );
});
