import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { t as $t, t } from 'i18next';
import { capitalize, startCase } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FormStateChangeProps } from '~formsShared';
import { AdminCompany } from '~hooks/admin/useCompaniesAdmin/models';
import { useCompaniesAdmin } from '~hooks/admin/useCompaniesAdmin/useCompaniesAdmin';
import { CompanyBasic } from '~hooks/useCompany';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { Nullable } from '~types/Nullable';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

import { CompanyForm } from './CompanyForm';

const AdminCompaniesDataGrid = () => {
  const theme = useTheme();
  const { companyAdminStore, toasterStore } = useStores();
  const {
    getAllCompanies,
    postCompanyUpdate,
    deleteCompany,
    uploadLogo,
    isUpdating,
    isLoadingAllCompanies,
  } = useCompaniesAdmin();
  const { setSearchQueryValue, searchValue } = useDataGridSearch();
  const [isCompanyDialogOpen, setCompanyDialogOpen] = useState(false);
  const modalDialogRef = useRef<any>(null);
  const updateDialogRef = useRef<any>(null);
  const [logo, setLogo] = useState<Nullable<File>>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const loadingRef = useRef(isLoadingAllCompanies);
  useEffect(() => {
    loadingRef.current = isLoadingAllCompanies;
  }, [isLoadingAllCompanies]);

  const pagination = companyAdminStore.pagination;
  const companiesTotalCount = companyAdminStore.companies?.length || 0;

  const handleClose = () => {
    setCompanyDialogOpen(false);
  };

  const rows = useMemo(() => {
    return companyAdminStore.companies || [];
  }, [companyAdminStore.companies]);
  const companyOptions = useMemo(() => {
    return (
      companyAdminStore.companies.map((co) => {
        return { legalName: co.legalName, id: co.id };
      }) || []
    );
  }, [companyAdminStore.companies]);
  const selectedCompany = useMemo(() => {
    if (rows.length && selectedId) {
      return rows.find((company) => company.id === selectedId) || undefined;
    }
    return null;
  }, [selectedId, rows]);

  const handleErrors = () => {
    // Do nothing
    // TDB: how to handle errors here?
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  // Delete flow
  const deleteRow = (id: string) => {
    setSelectedId(id);
    modalDialogRef.current?.open();
  };

  const deleteCompanyCallback = () => {
    if (selectedCompany?.id) {
      deleteCompany({
        id: selectedCompany.id,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(alert($t('company.company_deleted'), 'success'));
        },
      });
    }
  };
  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
    getAllCompanies({} as PaginationLink, searchQuery);
  };
  // Edit/Create new flow
  const editRow = (id: string) => {
    setSelectedId(id);
    setCompanyDialogOpen(true);
  };

  const createNew = () => {
    setSelectedId(null);
    setCompanyDialogOpen(true);
  };

  const onSuccess = (company?: AdminCompany) => {
    setSelectedId(null);

    handleClose();
    toasterStore.push(
      alert(
        selectedCompany?.id
          ? $t('company.company_updated', { name: company?.legalName })
          : $t('company.company_created', { name: company?.legalName }),
        AlertTypes.success,
      ),
    );
  };

  const onChangeLogo = (file: File) => {
    setLogo(file);
  };

  const uploadCompanyLogo = (id: string) => {
    uploadLogo({
      id: id,
      file: logo as File,
      callBack: (company: AdminCompany) => {
        onSuccess(company);
      },
    });
  };
  const onSubmitCallback = (data: AdminCompany) => {
    const options = {
      company: {
        ...data,
        id: data.id || selectedId || '',
      } as unknown as AdminCompany,
      callBack: (data: AdminCompany) => {
        setSelectedId(data.id);

        if (data.id && logo) {
          uploadCompanyLogo(data.id);
        } else {
          onSuccess(data);
        }
      },
    };

    postCompanyUpdate(options).catch(handleErrors);
  };

  const onSubmitCompanyForm = () => {
    updateDialogRef.current?.submit(onSubmitCallback);
  };

  const onValidateCompanyForm = ({ isDirty }: FormStateChangeProps) => {
    setIsDirty(isDirty);
  };

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'legalName',
          headerName: $t('form_fields.company'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return (
              <>
                <Typography component="span" sx={{ mr: 1 }}>
                  {params.row.logoUrl && (
                    <img src={params.row.logoUrl} alt="logo" width={25} />
                  )}
                </Typography>
                <BasicTooltip title={params.row.dbaName}>
                  <Typography component="span">{params.value}</Typography>
                </BasicTooltip>
              </>
            );
          },
        },
        {
          field: 'dbaName',
          headerName: $t('form_fields.dba'),
          flex: 1,
        },
        {
          field: 'parentCompany',
          headerName: $t('form_fields.parent_company'),
          type: 'singleSelect',
          valueOptions: [
            ...new Set(
              rows.map((item) => item.parentCompany?.legalName).filter((item) => !!item),
            ),
          ],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.legalName || '';
          },
        },
        { field: 'treadId', headerName: $t('form_fields.tread_id'), flex: 1 },
        { field: 'id', headerName: $t('form_fields.uuid'), flex: 1 },
        {
          field: 'companyType',
          headerName: $t('form_fields.type'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => startCase(row.companyType || '')))],
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value ? startCase(params.value) : '';
          },
        },
        {
          field: 'address',
          headerName: $t('form_fields.address'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const {
              address: { streetAddress, premise, locality, country, administrativeArea },
            } = params.row;

            return `${streetAddress}${premise ? ` ${premise},` : ','} ${locality}, ${administrativeArea}, ${country.name}`;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip title={params.value}>
                <Typography
                  component="span"
                  variant={'body1'}
                  color={'secondary'}
                  className="MuiDataGrid-cellContent"
                >
                  {params.value}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'timeZone',
          headerName: $t('form_fields.time_zone'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((item) => item.timeZone))],
          flex: 1,
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          flex: 1,
          type: 'date',
          valueGetter: (params: GridValueGetterParams) => {
            return params.value ? new Date(params.value) : null;
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <Typography component="span">
                  {dateFormat(params.row.createdAt)}
                </Typography>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'usageStatus',
          headerName: $t('form_fields.usage_status'),
          type: 'singleSelect',
          // Groupable: true,
          valueOptions: [...new Set(rows.map((item) => item.usageStatus))],
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return <Typography component="span">{capitalize(params.value)}</Typography>;
          },
        },
        {
          field: 'billingStatus',
          headerName: $t('form_fields.billing_status'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((item) => item.billingStatus))],
          // Groupable: true,
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return <Typography component="span">{capitalize(params.value)}</Typography>;
          },
        },
        {
          field: 'saasBillingPlan',
          headerName: $t('form_fields.plan'),
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((item) => item.saasBillingPlan))],
          // Groupable: true,
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            return <Typography component="span">{capitalize(params.value)}</Typography>;
          },
        },
        {
          field: 'primaryContact',
          headerName: $t('form_fields.primary_contact'),
          valueGetter: (params: GridValueGetterParams) => {
            const { primaryContact } = params.row;
            return primaryContact?.name;
          },
        },
        {
          field: 'primaryPhone',
          headerName: $t('form_fields.primary_phone'),
          valueGetter: (params: GridValueGetterParams) => {
            const { primaryContact } = params.row;
            return primaryContact?.phone;
          },
        },
        {
          field: 'primaryEmail',
          headerName: $t('form_fields.email'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const { primaryContact } = params.row;
            return primaryContact?.email;
          },
        },
        {
          field: 'billingContact',
          headerName: $t('form_fields.billing_contact'),
          valueGetter: (params: GridValueGetterParams) => {
            const { billingContact } = params.row;
            return billingContact?.name;
          },
        },
        {
          field: 'billingPhone',
          headerName: $t('form_fields.billing_phone'),
          valueGetter: (params: GridValueGetterParams) => {
            const { billingContact } = params.row;
            return billingContact?.phone;
          },
        },
        {
          field: 'billingEmail',
          headerName: $t('form_fields.billing_email'),
          valueGetter: (params: GridValueGetterParams) => {
            const { billingContact } = params.row;
            return billingContact?.email;
          },
        },
        {
          field: 'another.action.navigation',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companiesTotalCount}
              loading={loadingRef.current}
              pagination={pagination}
              callback={getAllCompanies}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params) => {
            return (
              <>
                <IconButton size="small" onClick={() => editRow(params.row.id)}>
                  <Edit />
                </IconButton>
                <SimpleMenu
                  options={[
                    {
                      title: t('actions.delete'),
                      icon: <Delete />,
                      callBack: () => deleteRow(params.row.id),
                    },
                  ]}
                >
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          dbaName: false,
          role: false,
          address: false,
          primaryContact: false,
          primaryPhone: false,
          primaryEmail: false,
          billingContact: false,
          billingPhone: false,
          billingEmail: false,
        },
      },
    }),
    [],
  );

  return (
    <>
      <Box sx={{ mt: 3 }} display={'block'} data-test-id="company-data-grid">
        <DataGrid
          id="admin-company-grid"
          columns={columns}
          hiddenColumns={['id', 'address.country', 'address.zip']}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoadingAllCompanies}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button
                  data-test-id={'create-company-btn'}
                  color={'primary'}
                  onClick={createNew}
                  startIcon={<Add />}
                >
                  {t('company.create_company')}
                </Button>
              </Box>
            </>
          }
        />
      </Box>
      <Dialog
        open={isCompanyDialogOpen}
        onClose={(_: never, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(
            `company.${selectedCompany?.id ? 'update_company' : 'create_company'}`,
          )}
        />
        <DialogContent>
          <CompanyForm
            ref={updateDialogRef}
            defaultCompany={selectedCompany}
            onFormStateChange={onValidateCompanyForm}
            onChangeLogo={onChangeLogo}
            companyOptions={companyOptions as unknown as CompanyBasic[]}
            isAdminPage
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <LoadingButton
            disabled={!isDirty || isUpdating}
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<></>}
            onClick={onSubmitCompanyForm}
            data-test-id={'create-company-btn'}
            type="button"
            variant="contained"
            color="primary"
            sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
          >
            {$t(`actions.${selectedCompany?.id ? 'submit' : 'create'}`)}
          </LoadingButton>
          <Button
            onClick={handleClose}
            sx={{ mr: 2, px: 2 }}
            disabled={isUpdating}
            color="secondary"
            variant="outlined"
          >
            {$t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        loading={isUpdating}
        title={$t('company.delete_company_title')}
        content={$t('company.delete_company_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteCompanyCallback}
      />
    </>
  );
};
export default observer(AdminCompaniesDataGrid);
