import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { AccountType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { Account, AccountTypeahead, useAccount } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { ProjectState, useProjects } from '~hooks/useProjects';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

interface OrderCustomerDetailsV2Props {
  companyId?: string;
}

export default function OrderCustomerDetailsV2({
  companyId,
}: OrderCustomerDetailsV2Props) {
  const form = useFormContext();

  const { toasterStore } = useStores();
  const { createAccount, getAccountsByCompanyIdTypeahead } = useAccount();
  const { getProjectsByCompanyIdTypeahead } = useProjects();

  const [tmpAccountName, setTmpAccountName] = useState<string>('');
  const onInputAccountName = (event: SyntheticEvent, value: string) => {
    setTmpAccountName(value);
  };

  const watchedSelectedCompany = form.watch('company') as CompanyBasic | null;
  const watchedAccount = form.watch('account') as AccountTypeahead | null;

  const fetchAccountsByCompanyId = useCallback(
    async (additionalProps = {}) => {
      if (!companyId) {
        return [];
      }

      return await getAccountsByCompanyIdTypeahead({
        companyId,
        accountTypes: [AccountType.CUSTOMER],
        ...additionalProps,
      });
    },
    [companyId],
  );

  const fetchProjectsByCompanyId = useCallback(
    async (additionalProps = {}) => {
      if (!companyId) {
        return [];
      }

      return await getProjectsByCompanyIdTypeahead({
        companyId: companyId,
        states: [ProjectState.ACTIVE],
        accountId: watchedAccount?.id,
        ...additionalProps,
      });
    },
    [companyId, watchedAccount?.id],
  );

  const createNewAccount = () => {
    if (!watchedSelectedCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createAccount({
      account: {
        name: tmpAccountName,
        accountTypes: [AccountType.CUSTOMER],
        connectedCompany: watchedSelectedCompany as CompanyBasic,
      } as unknown as Account,
      callBack: (account) => {
        toasterStore.push(
          alert(
            t('account.account_created', {
              name: account.name,
            }),
            AlertTypes.success,
          ),
        );
        form.setValue('account', account);
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          asyncCallback={fetchAccountsByCompanyId}
          control={form.control}
          disabled={!companyId}
          errors={form.formState.errors}
          getLabel={(item) => item.name || ''}
          getValue={(item) => item.id}
          isRequired
          label={`${t('dispatch.order.customer_account')}`}
          name="account"
          onSelect={(option) => {
            form.setValue('project', null);

            return option;
          }}
          onInput={onInputAccountName}
          noOptionsText={
            <Chip
              size={'small'}
              variant={'filled'}
              color={'primary'}
              onClick={() => createNewAccount()}
              sx={{ cursor: 'pointer' }}
              label={t('account.create_account')}
            />
          }
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteAsyncFormField
          asyncCallback={fetchProjectsByCompanyId}
          clearable
          control={form.control}
          disabled={!companyId}
          errors={form.formState.errors}
          getLabel={(item) => item.name || ''}
          getValue={(item) => item.id}
          label={`${t('order.form.associated_project_name')}`}
          name="project"
          onClear={() => form.setValue('project', null)}
        />
      </Grid>

      <Grid item xs={6}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.order_name')}`}
          name="name"
        />
      </Grid>

      <Grid item xs={6}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.dispatch_number_full')}`}
          name="dispatchNumber"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.order_po')}`}
          name="poJobNumber"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('order.form.order_zone')}`}
          name="zone"
        />
      </Grid>

      <Grid item xs={3}>
        <TextFormField
          control={form.control}
          errors={form.formState.errors}
          label={`${t('form_fields.external_id')}`}
          name="externalId"
        />
      </Grid>
    </Grid>
  );
}
