import { Address_Read_Nested } from '@treadinc/horizon-api-spec';
import { get } from 'lodash';

import { countries } from '~constants/enums';
import { Nullable } from '~types/Nullable';

type CountryType =
  | {
      name: string;
      code: string;
    }
  | undefined;

export class AddressItem {
  public static parse(proto: Address_Read_Nested): AddressItem {
    return new AddressItem(
      proto?.thoroughfare ?? '',
      proto.premise ?? '',
      proto?.locality ?? '',
      proto?.administrative_area ?? '',
      proto?.postal_code ?? '',
      proto?.country
        ? countries.values.find((country) => country.code === proto.country)
        : countries.default,
      proto?.lat ?? '0.0',
      proto?.lon ?? '0.0',
      proto?.place_id ?? '',
      proto.id ?? '',
      null,
    );
  }

  public static deparse(proto: Nullable<AddressItem>): Address_Read_Nested | undefined {
    if (!proto?.streetAddress) {
      return undefined;
    }

    return {
      thoroughfare: proto?.shortName || '',
      premise: proto?.premise,
      locality: proto?.locality,
      administrative_area: proto?.administrativeArea,
      postal_code: proto?.postalCode,
      country: proto.country?.code || '',
      lat: proto.lat?.toString() || '',
      lon: proto.lng?.toString() || '',
      place_id: proto.placeId,
      id: proto.id,
    };
  }
  public static decodeNBSuggestion(proto: any): AddressItem {
    return new AddressItem(
      get(proto, 'place.address', ''),
      get(proto, 'place.house', ''),
      get(proto, 'place.city', ''),
      get(proto, 'place.state', ''),
      get(proto, 'place.postalCode', ''),
      proto?.place?.country
        ? countries.values.find((country) => country.alpha3 === proto.place.country)
        : countries.default,
      get(proto, 'place.geopoint.lat', ''),
      get(proto, 'place.geopoint.lng', ''),
      get(proto, 'dataSource.refId', ''),
      '',
      null,
    );
  }

  public static decodeNBPlace(proto: any): AddressItem {
    return new AddressItem(
      get(proto, 'address.label', ''),
      get(proto, 'address.houseNumber', ''),
      get(proto, 'address.city', ''),
      get(proto, 'address.state', ''),
      get(proto, 'address.postalCode', ''),
      proto.address.countryCode
        ? countries.values.find((country) => country.alpha3 === proto.address.countryCode)
        : countries.default,
      get(proto, 'position.lat', ''),
      get(proto, 'position.lng', ''),
      get(proto, 'id', ''),
      '',
      null,
    );
  }

  public get streetAddress(): string {
    return this._thoroughfare;
  }
  public get shortName(): string | null {
    return this._short_name;
  }
  public get premise(): string {
    return this._premise;
  }
  public get locality(): string {
    return this._locality;
  }
  public get administrativeArea(): string {
    return this._administrative_area;
  }
  public get postalCode(): string {
    return this._postal_code;
  }
  public get country(): CountryType {
    return this._country;
  }
  public get lat(): number {
    return parseFloat(this._lat);
  }
  public get lng(): number {
    return parseFloat(this._lon);
  }
  public get placeId(): string {
    return this._place_id;
  }
  public get id(): string {
    return this._id;
  }
  public get thoroughfare(): string {
    return this._thoroughfare;
  }
  constructor(
    private _thoroughfare: string,
    private _premise: string,
    private _locality: string,
    private _administrative_area: string,
    private _postal_code: string,
    private _country: CountryType,
    private _lat: string,
    private _lon: string,
    private _place_id: string,
    private _id: string,
    private _short_name: string | null,
  ) {}
}
