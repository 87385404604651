import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { t } from 'i18next';
import { useRef } from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import {
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import theme from '~theme/AppTheme';

const ApprovedChip = () => {
  return (
    <Chip
      color={'success'}
      icon={
        <ArrowDropDown
          sx={{
            color: theme.palette.success.main,
            fontSize: '15px',
          }}
        />
      }
      label={t('approvals.driver_day.approved')}
      size="small"
      variant="outlined"
      sx={{
        '&.MuiChip-root': {
          '&.MuiChip-outlined': { flexDirection: 'row-reverse', borderRadius: 1 },
          '.MuiChip-label': {
            fontSize: '12px',
            fontWeight: 700,
            pr: 0,
          },
          '.MuiChip-icon': { mx: 0 },
        },
      }}
    />
  );
};

const UnApprovedChip = () => {
  return (
    <Chip
      color={'primary'}
      label={t('approvals.driver_day.not_approved')}
      icon={
        <ArrowDropDown
          sx={{
            color: '#4B3525',
            fontSize: '15px',
          }}
        />
      }
      size="small"
      variant="outlined"
      sx={{
        '&.MuiChip-root': {
          '&.MuiChip-outlined': { flexDirection: 'row-reverse', borderRadius: 1 },
          '.MuiChip-label': {
            fontSize: '12px',
            fontWeight: 700,
          },
          '.MuiChip-icon': { mx: 0 },
        },
      }}
    />
  );
};

const ApprovalActionsMenu = ({
  onMenuItemClick,
  approved,
}: {
  onMenuItemClick?: () => void;
  approved: boolean;
}) => {
  const menuHandler = useRef<MenuHandler>(null);

  return (
    <Menu
      ref={menuHandler}
      disableAutoFocusItem
      menuTrigger={
        <Box sx={{ cursor: 'pointer', display: 'inline-block', height: 'fit-content' }}>
          {approved ? <ApprovedChip /> : <UnApprovedChip />}
        </Box>
      }
    >
      <MenuItem
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();

          if (onMenuItemClick) {
            onMenuItemClick();
          }
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          gap={1}
          justifyContent="space-between"
          width="100%"
        >
          <ArrowCircleDown
            sx={{
              color: onMenuItemClick
                ? theme.brandV2.colors.treadBlack
                : theme.brandV2.colors.treadGray6,
              fontSize: '15px',
              transform: 'rotate(90deg)',
            }}
          />

          <OverflowAwareText
            flex={1}
            sx={{
              color: onMenuItemClick
                ? theme.brandV2.colors.treadBlack
                : theme.brandV2.colors.treadGray6,
            }}
          >
            {approved
              ? t('approvals.driver_day.unapprove')
              : t('approvals.driver_day.approve')}
          </OverflowAwareText>

          <LoadingSpinner loadingIndicatorSize={15} sx={{ flex: 0, minWidth: '15px' }} />
        </Box>
      </MenuItem>
    </Menu>
  );
};

export default ApprovalActionsMenu;
