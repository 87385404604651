import {
  Equipment_Read_Nested,
  NextBillionAssetLocations_LatestLocation_Read,
  Radii_Read_Nested,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { Nullable } from '~types/Nullable';

export class NextBillionAssetLocation {
  public static parse(
    proto: NextBillionAssetLocations_LatestLocation_Read,
  ): NextBillionAssetLocation {
    return new NextBillionAssetLocation(
      proto.id,
      proto.next_billion_external_id,
      proto.lat,
      proto.lon,
      proto.accuracy,
      proto.speed,
      proto.bearing,
      proto.altitude,
      proto.device_id ?? '',
      proto.next_billion_asset_id,
      proto.order_id ?? null,
      proto.job_id,
      proto.load_id,
      proto.equipment ?? null,
      dayjs(proto.created_at),
      proto.job_state,
      proto.load_state,
      proto.radii?.length ? proto.radii.map(BasicRadiiData.parse) : null,
      proto.driver ? proto.driver.first_name + ' ' + proto.driver.last_name : null,
      proto.vendor_job_assignment?.vendor_account
        ? proto.vendor_job_assignment.vendor_account.name
        : null,
      proto.vendor_job_assignment?.customer_account
        ? proto.vendor_job_assignment?.customer_account.name
        : null,
      proto.driver ? proto.driver.phone : null,
      proto.dispatch_number ?? null,
    );
  }

  public get id() {
    return this._id;
  }
  public get nextBillionExternalId() {
    return this._nextBillionExternalId;
  }
  public get lat() {
    return this._lat;
  }
  public get lon() {
    return this._lon;
  }
  public get accuracy() {
    return this._accuracy;
  }
  public get speed() {
    return this._speed;
  }
  public get bearing() {
    return this._bearing;
  }
  public get altitude() {
    return this._altitude;
  }
  public get deviceId() {
    return this._deviceId;
  }
  public get nextBillionAssetId() {
    return this._nextBillionAssetId;
  }
  public get orderId() {
    return this._orderId;
  }
  public get jobId() {
    return this._jobId;
  }
  public get loadId() {
    return this._loadId;
  }
  public get createdAt() {
    return this._createdAt;
  }
  public get jobState() {
    return this._jobState;
  }
  public get loadState() {
    return this._loadState;
  }
  public get equipment() {
    return this._equipment;
  }
  public get radii() {
    return this._radii;
  }
  public get driverName() {
    return this._driverName;
  }
  public get vendorName() {
    return this._vendorName;
  }
  public get customerName() {
    return this._customerName;
  }
  public get driverPhoneNumber() {
    return this._driverPhoneNumber;
  }
  public get dispatchNumber() {
    return this._dispatch_number;
  }

  constructor(
    private _id: string,
    private _nextBillionExternalId: Nullable<string>,
    private _lat: string,
    private _lon: string,
    private _accuracy: Nullable<string>,
    private _speed: Nullable<string>,
    private _bearing: Nullable<string>,
    private _altitude: Nullable<string>,
    private _deviceId: string,
    private _nextBillionAssetId: Nullable<string>,
    private _orderId: Nullable<string>,
    private _jobId: Nullable<string>,
    private _loadId: Nullable<string>,
    private _equipment: Nullable<Equipment_Read_Nested>,
    private _createdAt: Dayjs,
    private _jobState: Nullable<string>,
    private _loadState: Nullable<string>,
    private _radii: Nullable<BasicRadiiData[]>,
    private _driverName: Nullable<string>,
    private _vendorName: Nullable<string>,
    private _customerName: Nullable<string>,
    private _driverPhoneNumber: Nullable<string>,
    private _dispatch_number: Nullable<string>,
  ) {}
}
export class BasicRadiiData {
  public static parse(proto: Radii_Read_Nested): BasicRadiiData {
    return new BasicRadiiData(proto.id, proto.radius_meters);
  }

  public get id() {
    return this._id;
  }
  public get radiusMeters() {
    return this._radius_meters;
  }

  constructor(
    private _id: string,
    private _radius_meters: number,
  ) {}
}
