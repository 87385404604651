import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { Status as JobStatus } from '~components/Job/Status';
import { Job, JobLoadCycle } from '~hooks/useJob/models';
import { useJob } from '~hooks/useJob/useJob';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';

const emDash = '-';

function KeyValuePair({
  keyName,
  value,
}: {
  keyName: string;
  value: string | null | undefined;
}) {
  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {keyName}
      </Typography>
      <Typography variant="body2">{value ?? emDash}</Typography>
    </>
  );
}

function Break() {
  return (
    <Box
      sx={(theme) => ({
        gridColumn: 'span 2',
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
      })}
    />
  );
}

export const TruckOnClickPopoverContent = observer(
  ({ truckLocation }: { truckLocation: NextBillionAssetLocation }) => {
    const { isLoading, getJobById, getJobLoadCycles } = useJob();
    const [jobDetails, setJobDetails] = useState<Job | null>(null);
    const [cycles, setCycles] = useState<Array<JobLoadCycle>>([]);

    useEffect(() => {
      if (!truckLocation.jobId) {
        return;
      }

      getJobById(truckLocation.jobId).then((job) => setJobDetails(job));
      getJobLoadCycles(truckLocation.jobId).then((res) => {
        setCycles(res);
      });
    }, [truckLocation.jobId]);

    if (isLoading) {
      return (
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            display: 'grid',
            maxWidth: '360px',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      );
    }

    const cycleAverage =
      cycles.length > 0
        ? cycles.reduce((acc, cycle) => acc + cycle.timeMinutes, 0) / cycles.length
        : 0;

    return (
      <Box
        sx={{
          p: 1,
          borderRadius: 1,
          display: 'grid',
          gridTemplateColumns: '2fr 3fr',
          gap: 0.5,
          maxWidth: '240px',
        }}
      >
        <Box sx={{ gridColumn: 'span 2' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <Typography
              noWrap
              variant="h6"
              sx={{ fontSize: '14px' }}
            >{`${truckLocation.equipment?.company_share?.external_id ?? truckLocation.equipment?.name}${truckLocation.driverName ? ` - ${truckLocation.driverName}` : ''}`}</Typography>
            {truckLocation.jobState && (
              <JobStatus status={truckLocation.jobState as JobState} />
            )}
          </Box>
          {truckLocation.vendorName && (
            <Typography
              sx={{ fontSize: '12px' }}
            >{`${truckLocation.vendorName}`}</Typography>
          )}
        </Box>
        <Break />
        <KeyValuePair
          keyName={t('live_map.truck_popover.phone')}
          value={truckLocation.driverPhoneNumber}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.gps_data')}
          value={truckLocation.createdAt.format('ddd DD [at] hh:mm A')}
        />
        <Break />
        <KeyValuePair
          keyName={t('live_map.truck_popover.for')}
          value={jobDetails?.company?.legalName}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.loads')}
          value={
            Number.isInteger(jobDetails?.deliveredQuantity) && jobDetails?.loadsCount
              ? `${jobDetails?.deliveredQuantity} of ${jobDetails?.loadsCount} delivered`
              : null
          }
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.time_in_status')}
          value={
            jobDetails?.statusChangedAt
              ? t('live_map.truck_popover.x_minutes', {
                  minutes: dayjs().diff(jobDetails?.statusChangedAt, 'minutes'),
                })
              : null
          }
        />
        <Break />
        <KeyValuePair
          keyName={t('live_map.truck_popover.customer')}
          value={truckLocation.customerName}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.job_id')}
          value={jobDetails?.jobId}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.pickup')}
          value={jobDetails?.pickUpWayPoints[0]?.site?.name}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.dropoff')}
          value={jobDetails?.dropOffWayPoints[0]?.site?.name}
        />
        <Break />
        <KeyValuePair
          keyName={t('live_map.truck_popover.job_start')}
          value={jobDetails?.jobStartAt.format('hh:mm A')}
        />
        <KeyValuePair
          keyName={t('live_map.truck_popover.cycle_avg')}
          value={
            cycleAverage > 0
              ? t('live_map.truck_popover.x_minutes', { minutes: cycleAverage })
              : null
          }
        />
      </Box>
    );
  },
);
