import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SxProps } from '@mui/system';
import { intersection } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { routes } from '~router';
import { Nullable } from '~types/Nullable';

interface NavItem {
  title: string;
  link: string;
  icon?: string | React.ReactElement;
  isNewTab?: boolean;
  sx?: SxProps<Theme>; // Style
  value?: string;
  isSubRoute?: boolean;
}
interface NavItems {
  navItems: NavItem[];
  sx?: SxProps<Theme>; // Style
  callBack?: (itemName: string) => void;
  initialValue?: string;
}

const NavigationTabs = ({ navItems, sx, callBack, initialValue }: NavItems) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<Nullable<string>>(
    initialValue || navItems?.[0]?.value || navItems?.[0]?.link || '',
  );
  const location = useLocation();
  const NO_TAB_SELECTED = 'no-tab';

  useEffect(() => {
    const { pathname, search } = location;
    const path = `${pathname}${search}`;

    // Split the path by '/' to find any subroutes for nested tab menus
    const pathSegment = path.split('/').filter((item) => !!item);
    const navItemsLinks = navItems.map((navItem) => navItem?.link);

    // Special case for Driver Pay, where the selected tab is derived from the query string
    const driverPayBasePath = `/${routes.approvals.base}/${routes.approvals.driverPay}`;
    const isDriverPayPath = path.startsWith(driverPayBasePath);

    if (isDriverPayPath) {
      // This is to check if the user is picking a tab from the top nav or from the
      // "Approvals" section
      const isSelectingDriverPayTab = navItemsLinks.some((link) => {
        return link.includes(routes.approvals.driverPay);
      });

      if (isSelectingDriverPayTab) {
        const categoryTabInQueryString = new URLSearchParams(search).get('category');

        // No "payables" or "receivables" tab selected, default redirect to "receivables"
        if (!categoryTabInQueryString) {
          const receivablesTabLink = navItemsLinks.find((link) => {
            return link.includes('receivables');
          });

          navigate(String(receivablesTabLink));

          return;
        }

        const slug = categoryTabInQueryString.replace(/(payables|receivables)|./gi, '$1');
        const tab = navItemsLinks.find((link) => link.includes(slug));

        setValue(tab);

        return;
      }

      const tab = navItemsLinks.find((link) => link.includes(routes.approvals.base));

      setValue(tab);

      return;
    }

    navItemsLinks.forEach((navItem) => {
      // Check whether the path is part the navItem's subroute
      // There might be a better way to handle this with react router

      if (intersection(pathSegment, navItem.split('/')).length) {
        setValue(navItem);
      }
    });
  }, [location.pathname, location.search, navItems]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    callBack?.(newValue);
  };

  return (
    <Box sx={{ width: '100%', ...sx }} data-test-id="navigation-tabs">
      <Tabs
        value={value === NO_TAB_SELECTED ? false : value}
        onChange={handleChange}
        aria-label="navigation-aria"
      >
        {navItems?.map((navItem: NavItem, index: number) => {
          return (
            <Tab
              iconPosition="start"
              sx={{
                // Offset the spacing taken by the navitem underline
                mt: 0.5,
                fontWeight: 700,
                ...navItem.sx,
              }}
              target={navItem.isNewTab ? '_blank' : undefined}
              key={`${navItem.title}--${index}`}
              label={navItem.title}
              value={navItem.value || navItem.link}
              icon={navItem.icon}
              to={navItem.link}
              component={Link}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
export { NavigationTabs };
