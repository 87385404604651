import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  CompanyShareableType,
  ModelError_Item,
  ModelError_Response,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { StatusTabFilters } from '~components/StatusTabFilters/StatusTabFilters';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { EquipmentStatusFilter } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { FormStateChangeProps } from '~formsShared';
import { useUserRolesAdmin } from '~hooks/admin/useUsersAdmin/useUserRolesAdmin';
import { CompanyShare } from '~hooks/useCompanyShares';
import { useCompanyShares } from '~hooks/useCompanyShares/useCompanyShares';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import {
  Equipment,
  GetEquipmentByCompanyIdProps,
  useEquipment,
} from '~hooks/useEquipment';
import { EquipmentForm } from '~pages/Settings/Administration/EquipmentManagement/EquipmentForm';
import { ExternalEquipmentForm } from '~pages/Settings/Administration/EquipmentManagement/ExternalEquipmentForm';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';

interface ReceiverCompany {
  id: string;
  legalName: string;
}

interface Dispatchable {
  id: string;
  name: string;
}

export interface CompanyShareFormData {
  id: string;
  receiverCompany: ReceiverCompany;
  dispatchable: Dispatchable;
  companyShareableId: string;
}
const equipmentStatusFilters = [
  {
    value: EquipmentStatusFilter.COMPANY,
    label: 'common.company',
  },
  { value: EquipmentStatusFilter.SHARED, label: 'common.shared' },
];

const EquipmentManagement = observer(() => {
  const theme = useTheme();
  const { treatments, isReady } = useSplitTreatments({
    names: [FeatureFlags.driverEquipmentEnhancements],
  });
  const driverEquipmentEnhancementsFeatureFlag =
    treatments[FeatureFlags.driverEquipmentEnhancements];
  const isDriverEquipmentEnhancementsEnabled =
    isReady && driverEquipmentEnhancementsFeatureFlag.treatment === 'on';

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [companyShares, setCompanyShares] = useState<CompanyShare[]>([]);
  const [asyncErrors, setAsyncErrors] = useState<ModelError_Item[]>([]);
  const [equipmentStatusFilter, setEquipmentStatusFilter] =
    useState<EquipmentStatusFilter>(EquipmentStatusFilter.COMPANY);
  const { companyAssetsStore, userStore, toasterStore } = useStores();
  const { getUserRoles } = useUserRolesAdmin();
  const {
    getEquipmentByCompanyId,
    updateEquipment,
    createEquipment,
    deleteEquipment,
    isLoading,
    isUpdating,
  } = useEquipment();
  const { searchValue, setSearchQueryValue } = useDataGridSearch();
  const {
    getCompanySharesByCompanyId,
    updateCompanyShare,
    createCompanyShareByCompanyId,
    deleteCompanyShare,
    isUpdating: isUpdatingCompanyShares,
  } = useCompanyShares();

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const companyId = userStore.currentCompanies[0]?.id || userStore.userCompany?.id;
  useEffect(() => {
    getUserRoles();
  }, []);

  const displaySharedEquipment = equipmentStatusFilter === EquipmentStatusFilter.SHARED;

  useEffect(() => {
    if (companyId) {
      const params: GetEquipmentByCompanyIdProps = {
        companyId,
        query: searchValue,
      };

      if (!isDriverEquipmentEnhancementsEnabled) {
        params.shared = displaySharedEquipment;
      }

      getEquipmentByCompanyId(params);
    }
  }, [
    companyId,
    displaySharedEquipment,
    isDriverEquipmentEnhancementsEnabled,
    searchValue,
  ]);

  useEffect(() => {
    if (selectedId) {
      getCompanySharesByCompanyId({
        companyId,
        company_shareable_type: 'Equipment',
        company_shareable_id: selectedId,
        sender_company_id: companyId,
        callBack: setCompanyShares,
      });
    }
  }, [companyId, selectedId]);

  const getDialogTitle = () => {
    if (isExternalEquipment) {
      return 'administration.equipment.update_external_truck';
    }

    if (isDriverEquipmentEnhancementsEnabled) {
      return selectedId
        ? 'administration.equipment.update_internal_truck'
        : 'administration.equipment.create_internal_truck';
    }

    return selectedId
      ? 'administration.equipment.update_equipment'
      : 'administration.equipment.create_equipment';
  };

  const onChangeQuickFilter = (searchQuery: string) => {
    setSearchQueryValue(searchQuery);
  };
  const modalDialogRef = useRef<any>(null);
  const updateDialogRef = useRef<any>(null);

  const handleClose = () => {
    setCompanyShares([]);
    setSelectedId(null);
    setDialogOpen(false);
  };
  const editRow = (id: string) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const createNew = () => {
    setSelectedId(null);
    setDialogOpen(true);
  };

  const deleteRow = (id: string) => {
    setSelectedId(id);
    modalDialogRef.current?.open();
  };

  const deleteEquipmentCallback = () => {
    if (selectedId) {
      deleteEquipment({
        equipmentId: selectedId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(
            alert($t('administration.equipment.equipment_deleted'), AlertTypes.success),
          );
        },
      });
    }
  };

  const handleErrors = (error: AxiosError<ModelError_Response>) => {
    setAsyncErrors(error.response?.data.error.errors || []);
  };

  const onSuccess = (eq?: Equipment) => {
    const name = isExternalEquipment ? eq?.externalId || eq?.name : eq?.name;

    // Do not show a success alert if there is an error alert (handled in connections.ts)
    if (!toasterStore.alerts.length) {
      toasterStore.push(
        alert(
          selectedId
            ? $t('administration.equipment.equipment_updated', { name })
            : $t('administration.equipment.equipment_updated', { name }),
          AlertTypes.success,
        ),
      );

      // Only close the modal if the action is successful
      handleClose();
    }
  };

  const handleDeleteCompanyShares = (
    originalCompanyShares: CompanyShare[],
    companyShares: CompanyShareFormData[],
  ) => {
    const originalCompanySharesExcludingCurrentCompany = originalCompanyShares.filter(
      (companyShare) => companyShare.receiverCompany.id !== companyId,
    );

    const deletedCompanyShares = originalCompanySharesExcludingCurrentCompany.filter(
      (originalCompanyShare) =>
        !companyShares.some(
          (companyShare: { id: string | undefined }) =>
            companyShare.id === originalCompanyShare.id,
        ),
    );

    if (deletedCompanyShares?.length) {
      deletedCompanyShares.forEach((companyShare) => {
        deleteCompanyShare(companyShare.id as string);
      });
    }
  };

  const handleUpdateCompanyShare = async (data: {
    externalId?: string;
    ticketMatchId?: string;
  }) => {
    try {
      await updateCompanyShare({
        companyShareId: selectedEquipment?.companyShare?.id as string,
        external_id: data.externalId || '',
        ticket_match_id: data.ticketMatchId || '',
      });
    } catch (error) {
      handleErrors(error as AxiosError<ModelError_Response>);
      // We need to throw the error to prevent the onSuccess function from being called in the onSubmitCallback
      throw error;
    }
  };

  const handleUpdateEquipment = async (
    data: Equipment & { companySpecificId?: string },
  ) => {
    try {
      // Update company specific id if equipment was previously shared
      if (selectedEquipment?.companyShare?.id && data.companySpecificId) {
        await updateCompanyShare({
          companyShareId: selectedEquipment.companyShare.id,
          external_id: data.companySpecificId,
        });
      } else {
        await updateEquipment({
          equipment: data,
        });
      }
    } catch (error) {
      handleErrors(error as AxiosError<ModelError_Response>);
      // We need to throw the error to prevent the onSuccess function from being called in the onSubmitCallback
      throw error;
    }
  };

  const onSubmitCallback = (
    data: Equipment & {
      companySpecificId?: string;
      externalId?: string;
      ticketMatchId?: string;
      companyShares: CompanyShareFormData[];
    },
  ) => {
    const originalCompanyShares = companyShares;
    const submittedCompanyShares = data.companyShares;

    if (selectedId) {
      handleDeleteCompanyShares(originalCompanyShares, submittedCompanyShares);

      const promises = [];

      if (submittedCompanyShares.length) {
        submittedCompanyShares.forEach((companyShare) => {
          if (companyShare.id) {
            // Update existing company share
            promises.push(
              updateCompanyShare({
                companyShareId: companyShare.id,
                dispatchable: companyShare.dispatchable.id === 'dispatchable',
              }),
            );
          } else {
            // Create new company share
            promises.push(
              createCompanyShareByCompanyId({
                companyId: companyId,
                company_shareable_id: companyShare.companyShareableId,
                company_shareable_type: 'Equipment' as CompanyShareableType,
                receiver_company_id: companyShare.receiverCompany.id,
                dispatchable: companyShare.dispatchable.id === 'dispatchable',
              }),
            );
          }
        });
      }

      if (isDriverEquipmentEnhancementsEnabled && isExternalEquipment) {
        promises.push(
          handleUpdateCompanyShare({
            externalId: data.externalId,
            ticketMatchId: data.ticketMatchId,
          }),
        );
      } else {
        promises.push(handleUpdateEquipment(data));
      }

      Promise.all(promises)
        .then(() => {
          // We don't update the equipment object directly when we update a company share
          // Fetch the equipment in this instance to show the most recent data in the table
          if (isExternalEquipment) {
            const params: GetEquipmentByCompanyIdProps = {
              companyId,
            };

            if (!isDriverEquipmentEnhancementsEnabled) {
              params.shared = displaySharedEquipment;
            }

            getEquipmentByCompanyId(params);
          }
          onSuccess(data);
        })
        .catch((error) => {
          console.error('Error occurred during the operation:', error);
        });
    } else {
      createEquipment({ equipment: data, callBack: onSuccess })
        .then((res) => {
          if (submittedCompanyShares.length) {
            submittedCompanyShares.forEach((companyShare) =>
              createCompanyShareByCompanyId({
                companyId: companyId,
                company_shareable_id: res.id,
                company_shareable_type: 'Equipment' as CompanyShareableType,
                receiver_company_id: companyShare.receiverCompany.id,
                dispatchable: companyShare.dispatchable.id === 'dispatchable',
              }),
            );
          }
        })
        .catch(handleErrors);
    }
  };

  const onSubmitForm = () => {
    updateDialogRef.current?.submit(onSubmitCallback);
  };

  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsDirty(isDirty);
  };

  const rows = useMemo<Equipment[]>(
    () => companyAssetsStore.equipment,
    [companyAssetsStore.equipment],
  );
  const selectedEquipment = useMemo(() => {
    return companyAssetsStore.equipment.find((eq) => eq.id === selectedId) || null;
  }, [selectedId, rows]);
  const isExternalEquipment = selectedEquipment?.companyShare?.id;

  const columns: GridColDef[] = useMemo(
    () =>
      [
        //Order goes (1) Name, (2) Equipment Type, (3) License Number, (4) Equipment ID
        {
          field: 'name',
          headerName: isDriverEquipmentEnhancementsEnabled
            ? $t('administration.equipment.number_name')
            : $t('form_fields.name'),
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const equipment = params.row as Equipment;
            const isExternalEquipment = !!equipment.companyShare?.id;
            const hasExternalId = !!equipment.companyShare?.externalId;

            if (isDriverEquipmentEnhancementsEnabled && isExternalEquipment) {
              return hasExternalId ? (
                <Typography variant={'body2'}>
                  {equipment.companyShare?.externalId}
                </Typography>
              ) : (
                <PseudoLink
                  title={t('administration.equipment.add_number_name')}
                  action={() => editRow(params.row.id)}
                  linkColor={theme.brandV2.colors.treadOrange}
                />
              );
            }
            return <Typography variant={'body2'}>{params.value}</Typography>;
          },
        },
        {
          field: 'typeName',
          headerName: $t('form_fields.equipment_type'),
          flex: 1,
          groupable: true,
          aggregable: false,
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.typeName))],
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`eq__type__${params.row.id}`}
                title={`${$t('form_fields.equipment_type')}: ${params.value}`}
              >
                <span>{params.value}</span>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'companyName',
          headerName: $t('form_fields.company'),
          flex: 1,
          groupable: true,
          aggregable: false,
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.companyName))],
        },
        {
          field: 'licenseNumber',
          headerName: $t('form_fields.license_number'),
          flex: 1,
          height: 'auto',
        },
        {
          field: 'externalId',
          headerName: $t('form_fields.equipment_id'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            const isExternalEquipment = !!params.row?.companyShare?.id;
            return !isExternalEquipment ? params.value : '';
          },
        },
        {
          field: 'isExternal',
          headerName: $t('administration.equipment.ownership'),
          flex: 1,
          type: 'singleSelect',
          valueOptions: [
            $t('administration.equipment.ownership_external'),
            $t('administration.equipment.ownership_internal'),
          ],
          valueGetter: (params: GridValueGetterParams) => {
            return params.value
              ? $t('administration.equipment.ownership_external')
              : $t('administration.equipment.ownership_internal');
          },
        },
        {
          field: 'isNotActive',
          headerName: $t('form_fields.status'),
          flex: 1,
          type: 'singleSelect',
          valueOptions: [
            { value: false, label: $t('common.active') },
            { value: true, label: $t('common.inactive') },
          ],
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Chip
                label={
                  params.row.isNotActive ? $t('common.inactive') : $t('common.active')
                }
                color={params.row.isNotActive ? 'default' : 'success'}
                variant={'outlined'}
              />
            );
          },
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <span>{dateFormat(params.row.createdAt)}</span>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.equipment.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.equipmentPagination}
              callback={(link, searchQuery) => {
                const params: GetEquipmentByCompanyIdProps = {
                  companyId,
                  link,
                  query: searchQuery,
                };

                if (!isDriverEquipmentEnhancementsEnabled) {
                  params.shared = false;
                }

                return getEquipmentByCompanyId(params);
              }}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const options = [
              {
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              },
            ];
            return (
              <>
                <IconButton size="small" onClick={() => editRow(params.row.id)}>
                  <Edit />
                </IconButton>
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
            //
            // If (userPermissions.canDeleteEquipment) {
            //   Options.push({
            //     Title: t('actions.delete'),
            //     Icon: <Delete />,
            //     CallBack: () => deleteRow(params.row.id),
            //   });
            // }
            // Return (
            //   <>
            //     {userPermissions.canEditEquipment && (
            //       <IconButton size="small" onClick={() => editRow(params.row.id)}>
            //         <Edit />
            //       </IconButton>
            //     )}
            //     {Boolean(options?.length) && (
            //       <SimpleMenu options={options}>
            //         <MoreHoriz />
            //       </SimpleMenu>
            //     )}
            //   </>
            // );
          },
        },
      ].filter((column) => {
        if (isDriverEquipmentEnhancementsEnabled) {
          return !['isExternal', 'createdAt', 'isNotActive'].includes(column.field);
        }
        return true;
      }) as GridColDef[],
    [rows, isDriverEquipmentEnhancementsEnabled],
  );

  const sharedEquipmentColumns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.name'),
          flex: 1,
        },
        {
          field: 'sharingCompany',
          headerName: $t('form_fields.sharing_company'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.companyShare?.senderCompany?.legalName;
          },
        },
        {
          field: 'companySpecificID',
          headerName: $t('form_fields.company_specific_id'),
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return params.row.companyShare?.externalId;
          },
        },
        {
          field: 'licenseNumber',
          headerName: $t('form_fields.license_number'),
          flex: 1,
        },

        {
          field: 'externalId',
          headerName: $t('form_fields.sharing_company_equipment_id'),
          flex: 1,
        },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.equipment.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.equipmentPagination}
              callback={(link, searchQuery) => {
                return getEquipmentByCompanyId({
                  companyId,
                  link,
                  query: searchQuery,
                  shared: true,
                });
              }}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const options = [
              {
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              },
            ];
            return (
              <>
                <IconButton size="small" onClick={() => editRow(params.row.id)}>
                  <Edit />
                </IconButton>
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows],
  );

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {},
      },
    }),
    [],
  );

  const getDataGridId = () => {
    if (isDriverEquipmentEnhancementsEnabled) {
      return 'equipment-data-grid';
    }

    return equipmentStatusFilter === EquipmentStatusFilter.COMPANY
      ? 'company-equipment-data-grid'
      : 'shared-equipment-data-grid';
  };

  return (
    <>
      <Box sx={{ mt: 3 }} data-test-id={'users-data-grid'}>
        {!isDriverEquipmentEnhancementsEnabled && (
          <Box mb={2}>
            <StatusTabFilters
              value={equipmentStatusFilter}
              onChange={(tab) => setEquipmentStatusFilter(tab)}
              filters={equipmentStatusFilters}
            />
          </Box>
        )}
        <DataGrid
          id={getDataGridId()}
          columns={
            equipmentStatusFilter === EquipmentStatusFilter.COMPANY
              ? columns
              : sharedEquipmentColumns
          }
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          initialState={initialState}
          onChangeFilter={onChangeQuickFilter}
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button
                  size="medium"
                  color={'primary'}
                  onClick={createNew}
                  startIcon={<Add />}
                  // Disabled={!selectedCompanyId}
                >
                  {t('administration.equipment.create_equipment')}
                </Button>
              </Box>
            </>
          }
        />
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={(_: any, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader closeCallBack={handleClose} title={t(getDialogTitle())} />
        <DialogContent sx={{ my: 4 }}>
          {isDriverEquipmentEnhancementsEnabled && isExternalEquipment ? (
            <ExternalEquipmentForm
              ref={updateDialogRef}
              defaultEquipment={selectedEquipment}
              companyShares={companyShares}
              onFormStateChange={onFormStateChange}
              selectedCompanyId={companyId}
              errors={asyncErrors}
            />
          ) : (
            <EquipmentForm
              ref={updateDialogRef}
              defaultEquipment={selectedEquipment}
              companyShares={companyShares}
              onFormStateChange={onFormStateChange}
              selectedCompanyId={companyId}
              errors={asyncErrors}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={isUpdating || isUpdatingCompanyShares || !isDirty}
              loading={isUpdating || isUpdatingCompanyShares}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating || isUpdatingCompanyShares ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(selectedId ? `actions.update` : 'actions.create')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating || isUpdatingCompanyShares}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        title={$t('administration.equipment.delete_equipment_title')}
        content={$t('administration.equipment.delete_equipment_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteEquipmentCallback}
        loading={isUpdating}
      />
    </>
  );
});

export { EquipmentManagement };
