import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { OrderUnitOfMeasure, unitOfMeasureOptions } from '~constants/enums';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';
import { Material, useMaterials } from '~hooks/useMaterials';
import { Order } from '~hooks/useOrders';
import { Project } from '~hooks/useProjects';
import { useServiceClass } from '~hooks/useServiceClass';
import { Service } from '~hooks/useServices';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import {
  QuantityType,
  quantityTypeOptions,
  serviceIsHiredTruck,
} from '../newOrderFormSchema';
import { requiredForEstimatesInputAdornment } from './shared-components';

interface OrderDetailsV2Props {
  order?: Order;
  parent?: Project | Order;
}

export default function OrderDetailsV2({ order, parent }: OrderDetailsV2Props) {
  const form = useFormContext();

  const { companyAssetsStore, userStore, toasterStore } = useStores();

  const { getServiceClassesByCompanyId } = useServiceClass();
  const {
    createMaterialByCompanyId,
    getMaterialsByCompanyId,
    getMaterialsByCompanyIdTypeahead,
    isLoading: isMaterialLoading,
  } = useMaterials();
  const {
    createEquipmentTypeByCompanyId,
    getEquipmentTypeById,
    getEquipmentTypesByCompanyIdTypeahead,
    isLoading: isEquipmentTypeLoading,
  } = useEquipment();

  const [notFoundMaterialName, setNotFoundMaterialName] = useState('');
  const [notFoundEquipmentTypeName, setNotFoundEquipmentTypeName] = useState('');

  const watchedCompany = form.watch('company');
  const watchedService = form.watch('service') as Service | null;
  const watchedQuantityType = form.watch('quantityType') as NameIdSchemaRequired | null;

  const companyId = watchedCompany?.id || userStore.userCompany?.id || '';
  const isHiredTruckService = serviceIsHiredTruck(watchedService);

  const shouldDisableMaterialUoMField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    const isTrucksQuantityTypeSelected = selectedQuantityTypeId === QuantityType.TRUCKS;
    const isLoadsQuantityTypeSelected = selectedQuantityTypeId === QuantityType.LOAD;

    if (isTrucksQuantityTypeSelected || isLoadsQuantityTypeSelected) {
      return false;
    }

    return true;
  }, [watchedQuantityType]);

  const createMaterial = useCallback(async () => {
    if (!companyId) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );

      return;
    }

    const createdMaterial = await createMaterialByCompanyId({
      companyId,
      material: { name: notFoundMaterialName } as unknown as Material,
    });

    form.setValue('material', createdMaterial, {
      shouldValidate: true,
      shouldDirty: true,
    });

    toasterStore.push(
      alert(
        t('administration.material.material_created', { name: createdMaterial.name }),
        AlertTypes.success,
      ),
    );
  }, [companyId, notFoundMaterialName]);

  const handleMaterialInput = useCallback((_: React.SyntheticEvent, value: string) => {
    setNotFoundMaterialName(value);
  }, []);

  const fetchEquipmentTypesByCompanyId = useCallback(
    (additionalProps = {}) => {
      if (!companyId) {
        toasterStore.push(
          alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
        );

        return;
      }

      return getEquipmentTypesByCompanyIdTypeahead({ companyId, ...additionalProps });
    },
    [companyId],
  );

  const createEquipmentType = useCallback(async () => {
    if (!companyId) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );

      return;
    }

    const createdEquipmentType = await createEquipmentTypeByCompanyId({
      companyId,
      equipmentType: { name: notFoundEquipmentTypeName } as unknown as EquipmentTypeItem,
    });

    form.setValue('equipmentType', createdEquipmentType, {
      shouldValidate: true,
      shouldDirty: true,
    });

    toasterStore.push(
      alert(
        t('administration.equipment.equipment_created', {
          name: createdEquipmentType.name,
        }),
        AlertTypes.success,
      ),
    );
  }, [companyId, notFoundEquipmentTypeName]);

  const fetchEquipmentType = useCallback(
    async (equipmentTypeId: string, grossCapacityOverride?: number) => {
      const equipmentType = await getEquipmentTypeById(equipmentTypeId);

      form.setValue('equipmentType', equipmentType, {
        shouldValidate: true,
        shouldDirty: true,
      });

      const grossCapacity = _.isNil(grossCapacityOverride)
        ? equipmentType.grossCapacity
        : `${grossCapacityOverride}`;

      form.setValue('grossCapacity', grossCapacity);
    },
    [],
  );

  const handleEquipmentTypeInput = useCallback(
    (_: React.SyntheticEvent, value: string) => {
      setNotFoundEquipmentTypeName(value);
    },
    [],
  );

  const handleEquipmentTypeSelect = useCallback(
    (equipmentType: EquipmentTypeItem) => {
      if (equipmentType) {
        fetchEquipmentType(equipmentType.id);
      }
    },
    [fetchEquipmentType],
  );

  useEffect(() => {
    if (shouldDisableMaterialUoMField) {
      const uom = unitOfMeasureOptions.find((uom) => {
        return _.snakeCase(uom.id) === watchedQuantityType?.id;
      });

      if (uom && uom.id !== OrderUnitOfMeasure.LOAD) {
        form.setValue('unitOfMeasure', uom);
      }
    }
  }, [shouldDisableMaterialUoMField, watchedQuantityType]);

  useEffect(() => {
    if (!order?.id && parent) {
      if (parent.equipmentType?.id) {
        fetchEquipmentType(parent.equipmentType.id ?? '');
      }

      const firstMaterial = (parent as Project).projectMaterialTotals?.[0];

      form.setValue('service', parent.service);
      form.setValue('serviceClass', parent.serviceClass);
      form.setValue('loadsPerTruck', parent.loadsPerTruck);
      form.setValue('unitsPerHour', parent.unitsPerHour);
      form.setValue('material', firstMaterial?.material);
      form.setValue('unitOfMeasure', firstMaterial?.unitOfMeasure);
    }
  }, [order?.id, parent, fetchEquipmentType]);

  useEffect(() => {
    if (companyId && !order?.id) {
      getServiceClassesByCompanyId(companyId);
      getMaterialsByCompanyId(companyId);
      form.setValue('material', null);
    }
  }, [companyId, order?.id]);

  useEffect(() => {
    if (order?.id) {
      const equipmentTypeId = order.equipmentType?.id;

      if (equipmentTypeId) {
        fetchEquipmentType(
          equipmentTypeId,
          order.equipmentTypeGrossCapacity ?? undefined,
        );
      }
    }
  }, [order, fetchEquipmentType]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AutocompleteFormField
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            isRequired
            label={`${t('form_fields.service_type')}`}
            list={companyAssetsStore.services}
            name="service"
          />
        </Grid>

        <Grid item xs={3}>
          <AutocompleteFormField
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            isRequired
            label={`${t('dispatch.order.order_details_v2.quantity_type')}`}
            list={quantityTypeOptions}
            name="quantityType"
          />
        </Grid>

        <Grid item xs={3}>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            isRequired
            label={`${t('form_fields.quantity')}`}
            name="serviceQuantity"
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteAsyncFormField
            asyncCallback={fetchEquipmentTypesByCompanyId}
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            label={`${t('dispatch.order.order_details_v2.truck_type')}`}
            name="equipmentType"
            noOptionsText={
              notFoundEquipmentTypeName ? (
                <Chip
                  color="primary"
                  disabled={isEquipmentTypeLoading}
                  label={t('common.add_new_name', {
                    name: notFoundEquipmentTypeName || '',
                  })}
                  onClick={createEquipmentType}
                  size="small"
                  variant="filled"
                  sx={{ cursor: 'pointer' }}
                />
              ) : (
                t('form_fields.no_options')
              )
            }
            onInput={handleEquipmentTypeInput}
            onSelect={handleEquipmentTypeSelect}
          />
        </Grid>

        <Grid item xs={3}>
          <TextFormField
            InputProps={{ endAdornment: requiredForEstimatesInputAdornment.component }}
            control={form.control}
            errors={form.formState.errors}
            label={`${t('dispatch.order.order_details_v2.truck_capacity')}`}
            name="grossCapacity"
            type="number"
            sx={{ ...requiredForEstimatesInputAdornment.textFormFieldSx }}
          />
        </Grid>

        <Grid item xs={3}>
          <AutocompleteFormField
            control={form.control}
            disabled={shouldDisableMaterialUoMField}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            isRequired={!isHiredTruckService}
            label={`${t('form_fields.units')}`}
            list={unitOfMeasureOptions}
            name="unitOfMeasure"
            clearable={isHiredTruckService}
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteAsyncFormField
            asyncCallback={getMaterialsByCompanyIdTypeahead}
            control={form.control}
            errors={form.formState.errors}
            extraRequestOptions={{ companyId }}
            getLabel={(material: Material) => {
              return `${material?.externalId ? `${material.externalId} - ` : ''}${material?.name ?? ''}`;
            }}
            getValue={(material: Material) => material?.id ?? ''}
            isRequired={!isHiredTruckService}
            label={`${t('form_fields.material')}`}
            name="material"
            clearable={isHiredTruckService}
            noOptionsText={
              notFoundMaterialName ? (
                <Chip
                  color="primary"
                  disabled={isMaterialLoading}
                  label={t('common.add_new_name', { name: notFoundMaterialName || '' })}
                  onClick={createMaterial}
                  size="small"
                  variant="filled"
                  sx={{ cursor: 'pointer' }}
                />
              ) : (
                t('form_fields.no_options')
              )
            }
            onInput={handleMaterialInput}
          />
        </Grid>

        <Grid item xs={6}>
          <AutocompleteFormField
            clearable
            control={form.control}
            errors={form.formState.errors}
            getLabel={(item) => item?.name ?? ''}
            getValue={(item) => item?.id ?? ''}
            label={`${t('form_fields.service_class')}`}
            list={companyAssetsStore.serviceClasses}
            name="serviceClass"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
