import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SxProps } from '@mui/system';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import { MenuHandler } from '~components/Menu/Menu';
import {
  DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS,
  NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
} from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

const SEARCH_BAR_MAX_WIDTH_IN_PX = 180;

interface OrdersDispatchFiltersBarProps {
  expandedState: string;
  isOrderFormDrawerMinimized?: boolean;
  onExpandedStateChange: (expandedState: string) => void;
  onNewOrderClick: () => void;
  sx?: SxProps;
}

interface SearchBarProps {
  isAlwaysExpanded?: boolean;
  sx?: SxProps;
}

export const SearchBar = observer(({ isAlwaysExpanded, sx }: SearchBarProps) => {
  const { userStore, ordersDispatchStore } = useStores();
  const inputRef = useRef<HTMLInputElement>();
  const [isExpanded, setIsExpanded] = useState(Boolean(isAlwaysExpanded));
  const [inputValue, setInputValue] = useState('');

  const companyId = userStore.currentCompanies[0].id || userStore.userCompany.id;
  const inputHasValue = inputValue.length > 0;

  const applyFilter = useCallback(
    _.debounce((newVal: string) => {
      ordersDispatchStore.setFilters({ search: newVal }, true);
    }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS),
    [ordersDispatchStore.setFilters],
  );

  useEffect(() => {
    if (companyId && !isAlwaysExpanded) {
      setInputValue('');
      setIsExpanded(false);
    }
  }, [companyId]);

  useEffect(() => {
    if (!isExpanded) {
      setInputValue('');
    }
  }, [isExpanded]);

  useEffect(() => {
    const searchDidChange = ordersDispatchStore.filters.search !== inputValue;
    if (searchDidChange) {
      applyFilter(inputValue);
    }

    return () => {
      applyFilter.cancel();
    };
  }, [inputValue]);

  return (
    <Box sx={sx}>
      <Collapse
        collapsedSize={`calc(18px + (${theme.spacing(1)} * 2)`}
        in={isExpanded}
        orientation="horizontal"
        sx={{ '& .MuiCollapse-wrapperInner': { width: '100%' } }}
      >
        <Box
          border={`solid 1px ${theme.brandV2.colors.treadGray7}`}
          borderRadius={theme.brandV2.borderRadius}
          maxWidth={SEARCH_BAR_MAX_WIDTH_IN_PX}
          width="100%"
          onClick={() => {
            if (!isAlwaysExpanded) {
              setIsExpanded(true);
              inputRef.current?.focus();
            }
          }}
        >
          <OutlinedInput
            inputRef={inputRef}
            fullWidth
            onBlur={() => {
              if (!isAlwaysExpanded && !inputHasValue) {
                setIsExpanded(false);
              }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Search orders"
            size="small"
            value={inputValue}
            startAdornment={
              <InputAdornment position="start">
                <Search
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end" onClick={() => setInputValue('')}>
                <Close
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    cursor: 'pointer',
                    fontSize: '16px',
                    visibility: inputHasValue ? 'visible' : 'hidden',
                  }}
                />
              </InputAdornment>
            }
            sx={{
              '&.MuiInputBase-root': {
                height: `calc(${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px - 2px)`,
                px: 1,
                py: 0.5,
                '.MuiInputBase-input': {
                  fontSize: '12px',
                  p: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              },
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
});

interface ExpandedStatesMenuProps
  extends Pick<
    OrdersDispatchFiltersBarProps,
    'expandedState' | 'onExpandedStateChange'
  > {}

export function ExpandedStatesMenu({
  expandedState,
  onExpandedStateChange,
}: ExpandedStatesMenuProps) {
  const menuHandler = useRef<MenuHandler>(null);

  const handleMenuItemClick = useCallback(
    (expandeState: string) => {
      onExpandedStateChange(expandeState);
      menuHandler.current?.onClose?.();
    },
    [onExpandedStateChange],
  );

  return (
    <IconButton
      onClick={() => {
        if (expandedState === 'open') {
          handleMenuItemClick('closed');
        } else {
          handleMenuItemClick('open');
        }
      }}
      size="small"
      sx={{
        '&.MuiIconButton-root': {
          border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
          borderRadius: theme.spacing(0.5),
          color: theme.brandV2.colors.treadBlack,
          p: '2px',
          width: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
        },
      }}
    >
      {expandedState === 'open' ? (
        <UnfoldLess sx={{ fontSize: '18px' }} />
      ) : (
        <UnfoldMore sx={{ fontSize: '18px' }} />
      )}
    </IconButton>
  );
}
