import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Equipment_Read_Nested, getV1SitesId } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { flatten } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { Company, useCompany } from '~hooks/useCompany';
import { Job } from '~hooks/useJob';
import { useLiveMap } from '~hooks/useLiveMap/useLiveMap';
import { useTruckLocations } from '~hooks/useLiveMap/useTruckLocations';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import { useNextBillionAssetLocationHistories } from '~hooks/useNextBillionAssetLocationHistories/useNextBillionAssetLocationHistories';
import { Order } from '~hooks/useOrders';
import { Site, WayPoint } from '~hooks/useSites/models';
import { BackToLiveBar } from '~pages/LiveMap/BackToLiveBar';
import { TimeIndicatorContextProvider } from '~pages/LiveMap/Timeline/components/hooks/useTimeIndicatorContext';
import { Timeline } from '~pages/LiveMap/Timeline/Timeline';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import { GeofenceType, MarkerType } from './constants';
import { MapV2 } from './MapV2';
import { TruckHoverPopoverContent } from './TruckHoverPopoverContent';
import { TruckOnClickPopoverContent } from './TruckOnClickPopoverContent';

const emDash = '—';

const getEquipmentLabel = (
  equipment: Nullable<Equipment_Read_Nested>,
  truckLocation: NextBillionAssetLocation,
) => {
  const truckIdentifier = equipment
    ? equipment.company_share?.external_id ?? equipment.name
    : '';
  const vendorName = truckLocation.vendorName ?? '';
  const fullLabel = !vendorName
    ? truckIdentifier
    : `${truckIdentifier}
  ${vendorName}`;
  return fullLabel;
};

const getSiteById = async (id: string) => {
  try {
    const resp = await getV1SitesId({ path: { id } });
    return Site.parse(resp.data.data);
  } catch (error) {
    // Do nothing. This data is not critical to the map.
  }
};

/*
 * Consider this the "opinionated" part of our mapping solution. This component transforms jobs,
 * orders, and local truck data into a more presentation-heavy BaseMap.
 */
const TreadLiveMapV2 = ({
  order,
  job,
  lazyLoadJobPings = false,
  onBackClick,
}: {
  order?: Order;
  job?: Job;
  lazyLoadJobPings?: boolean;
  onBackClick?: () => void;
}) => {
  const [truckLocationsById, setTruckLocationsById] = useState<
    Record<string, NextBillionAssetLocation>
  >({});
  const [siteDetailsBySiteId, setSiteDetailsBySiteId] = useState<Record<
    string,
    Site
  > | null>(null);
  const [jobRoutePings, setJobRoutePings] = useState<NextBillionAssetLocation[] | null>(
    null,
  );
  const [isLoadingRoutePings, setIsLoadingRoutePings] = useState(false);
  const [selectedJobRoutePingInd, setSelectedJobRoutePingInd] = useState<number | null>(
    null,
  );
  const { userStore } = useStores();
  const { getCompanyById } = useCompany();
  const [currentCompany, setCurrentCompany] = useState<Company | null>(null);

  const { getLatestLocations } = useNextBillionAssetLocationHistories();
  const { fetchTreadRoute } = useLiveMap();
  const { subscription, subscribeToLastLocationUpdates } = useTruckLocations();

  const currentCompanyId = getEffectiveUserCompanyId(userStore);
  const getLatestTruckLocations = useCallback(async () => {
    const truckLocations = [];
    let afterLink = null;
    let hasMore = true;

    while (hasMore) {
      const locationResponse = await getLatestLocations({
        job_id: job?.id,
        order_id: order?.id,
        linkType: 'after',
        link: afterLink,
      });

      if (locationResponse) {
        const { data, pagination } = locationResponse;

        truckLocations.push(data);
        if (pagination?.after && data.length !== 0) {
          afterLink = pagination.after;
        } else {
          hasMore = false;
        }
      } else {
        hasMore = false;
      }
    }

    return flatten(truckLocations).flatMap((item) => item);
  }, []);

  const SitePopover = useCallback(
    ({ wayPoint }: { wayPoint: WayPoint }) => {
      return (
        <Box
          sx={{
            p: 1,
            borderRadius: 1,
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: 0.5,
            maxWidth: '240px',
          }}
        >
          <Typography noWrap variant="h6" sx={{ gridColumn: 'span 2', fontSize: '14px' }}>
            {wayPoint.site?.name}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.address')}
          </Typography>
          <Typography variant="body2">
            {wayPoint.siteNested?.address?.thoroughfare ?? emDash}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.type')}
          </Typography>
          <Typography variant="body2">
            {siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].siteType ?? emDash}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.geofence_type')}
          </Typography>
          <Typography variant="body2">
            {wayPoint.site?.nextBillionGeofence?.geofenceType
              ? wayPoint.site?.nextBillionGeofence?.geofenceType.replace(
                  /^./,
                  wayPoint.site?.nextBillionGeofence?.geofenceType[0].toUpperCase(),
                )
              : emDash}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {t('live_map.pop_up.site.external_id')}
          </Typography>
          <Typography variant="body2">
            {siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].externalId?.length
              ? siteDetailsBySiteId?.[wayPoint.site?.id ?? ''].externalId
              : emDash}
          </Typography>
        </Box>
      );
    },
    [siteDetailsBySiteId],
  );

  useEffect(() => {
    if (currentCompanyId) {
      getCompanyById({
        id: currentCompanyId,
        callBack: setCurrentCompany,
      });
    }
  }, [currentCompanyId]);

  // Truck locations are primary data we always need. Load and then listen for changes.
  useEffect(() => {
    const loadAndSubscribeToTruckLocations = async () => {
      const data = await getLatestTruckLocations();
      setTruckLocationsById(
        data.reduce(
          (acc, location) => ({
            ...acc,
            [location.equipment?.id ?? location.id]: location,
          }),
          {},
        ),
      );
      if (!subscription) {
        subscribeToLastLocationUpdates({
          onMessageCallBack: (data: NextBillionAssetLocation) => {
            setTruckLocationsById((prev) => ({
              ...prev,
              [data.equipment?.id ?? data.id]: data,
            }));
          },
        });
      }
    };
    loadAndSubscribeToTruckLocations();
    return () => {
      subscription?.unsubscribe?.();
    };
  }, [getLatestTruckLocations, subscription, getEffectiveUserCompanyId(userStore)]);

  // Site details are important for popovers.
  useEffect(() => {
    const loadSiteDetails = async (siteId1: string, siteId2: string) => {
      const [site1, site2] = await Promise.all([
        getSiteById(siteId1),
        getSiteById(siteId2),
      ]);
      if (site1 && site2) {
        setSiteDetailsBySiteId((prev) => ({
          ...(prev ?? {}),
          [site1.id]: site1,
          [site2.id]: site2,
        }));
      }
    };

    if (order?.id && order.waypoints?.[0].site?.id && order.waypoints?.[1].site?.id) {
      loadSiteDetails(order.waypoints[0].site.id, order.waypoints[1].site.id);
    } else if (job?.id && job.waypoints?.[0]?.site?.id && job.waypoints?.[1]?.site?.id) {
      loadSiteDetails(job.waypoints[0].site.id, job.waypoints[1].site.id);
    }
  }, [order?.id, job?.id]);

  // Pings are secondary data we only ask for when a job is present.
  // We also only eagerly load if the lazyLoadJobPings flag is false or empty.
  useEffect(() => {
    if (!job?.id || lazyLoadJobPings) return;
    setIsLoadingRoutePings(true);
    fetchTreadRoute(job.id).then((data) => {
      setJobRoutePings(data);
      setIsLoadingRoutePings(false);
    });
  }, [job?.id, lazyLoadJobPings, setJobRoutePings, setIsLoadingRoutePings]);

  const mapCenter = useMemo(() => {
    return {
      lng: currentCompany?.defaultLon || TORONTO_OFFICE_COORDINATES.lng,
      lat: currentCompany?.defaultLat || TORONTO_OFFICE_COORDINATES.lat,
    };
  }, [currentCompany]);

  const truckAndMovingSiteMarkers = Object.values(truckLocationsById)
    // If order or job are provided, we only surface relevant markers
    .filter((truckLocation) => {
      if (order && truckLocation.orderId !== order.id) {
        return false;
      }
      if (job && truckLocation.jobId !== job.id) {
        return false;
      }
      return true;
    })
    .map((truckLocation) => {
      if (truckLocation.radii?.length) {
        return {
          id: truckLocation.equipment?.id ?? truckLocation.id,
          type: MarkerType.moving_site,
          lat: Number.parseFloat(truckLocation.lat),
          lng: Number.parseFloat(truckLocation.lon),
          radii: truckLocation.radii.map((r) => r.radiusMeters),
        };
      }
      return {
        id: truckLocation.equipment?.id ?? truckLocation.id,
        type: MarkerType.truck,
        label: getEquipmentLabel(truckLocation.equipment, truckLocation),
        clickPopoverContent: <TruckOnClickPopoverContent truckLocation={truckLocation} />,
        hoverPopoverContent: <TruckHoverPopoverContent truckLocation={truckLocation} />,
        lat: Number.parseFloat(truckLocation.lat),
        lng: Number.parseFloat(truckLocation.lon),
        bearing: truckLocation.bearing ? Math.round(Number(truckLocation.bearing)) : 0,
      };
    });

  const orderSiteMarkers = order?.waypoints
    ?.filter((wayPoint) => !!wayPoint && !!wayPoint?.site?.lat && !!wayPoint?.site?.lon)
    .map((wayPoint) => ({
      // Sorry for all the casting. Filter above protects us. Many nullable fields in the origin shape.
      id: wayPoint.id as string,
      type: MarkerType.site,
      lat: wayPoint.site?.lat as number,
      lng: wayPoint.site?.lon as number,
      // Conditionally add radius if a circle
      ...(wayPoint.site?.nextBillionGeofence?.geofenceType === 'circle'
        ? {
            geofenceType: GeofenceType.circle,
            radii: [wayPoint?.site?.nextBillionGeofence?.circleRadius],
          }
        : {}),
      // Conditionally add coordinates if a polygon
      ...(wayPoint.site?.nextBillionGeofence?.geofenceType === 'polygon'
        ? {
            geofenceType: GeofenceType.polygon,
            coordinates: wayPoint?.site?.nextBillionGeofence?.geojson?.coordinates,
          }
        : {}),
      clickPopoverContent: <SitePopover wayPoint={wayPoint} />,
    }));

  const orderMarkers = useMemo(() => {
    return [...(orderSiteMarkers ?? []), ...truckAndMovingSiteMarkers];
  }, [orderSiteMarkers, truckAndMovingSiteMarkers]);

  const jobSiteMarkers = useMemo(() => {
    return job?.waypoints
      ?.filter((wayPoint) => !!wayPoint && !!wayPoint?.site?.lat && !!wayPoint?.site?.lon)
      .map((wayPoint) => ({
        // Sorry for all the casting. Filter above protects us. Many nullable fields in the origin shape.
        id: wayPoint?.id as string,
        type: MarkerType.site,
        lat: wayPoint?.site?.lat as number,
        lng: wayPoint?.site?.lon as number,
        // Conditionally add radius if a circle
        ...(wayPoint?.site?.nextBillionGeofence?.geofenceType === 'circle'
          ? {
              geofenceType: GeofenceType.circle,
              radii: [wayPoint?.site?.nextBillionGeofence?.circleRadius],
            }
          : {}),
        // Conditionally add coordinates if a polygon
        ...(wayPoint?.site?.nextBillionGeofence?.geofenceType === 'polygon'
          ? {
              geofenceType: GeofenceType.polygon,
              coordinates: wayPoint?.site?.nextBillionGeofence?.geojson?.coordinates,
            }
          : {}),
        clickPopoverContent: <SitePopover wayPoint={wayPoint} />,
      }));
  }, [job]);

  const jobPingMarkers = useMemo(() => {
    return jobRoutePings
      ? jobRoutePings.map((ping) => ({
          id: ping.id,
          isActive:
            selectedJobRoutePingInd &&
            ping.id === jobRoutePings[selectedJobRoutePingInd].id,
          type: MarkerType.ping,
          lat: Number.parseFloat(ping.lat),
          lng: Number.parseFloat(ping.lon),
        }))
      : [];
  }, [selectedJobRoutePingInd, jobRoutePings]);

  const jobMarkers = useMemo(() => {
    return [...(jobSiteMarkers ?? []), ...jobPingMarkers, ...truckAndMovingSiteMarkers];
  }, [jobSiteMarkers, jobPingMarkers, truckAndMovingSiteMarkers]);

  return (
    <Box
      sx={{
        flex: 1, // To handle the case where the map is the only child of a flex container
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {(job || order) && onBackClick && (
          <BackToLiveBar
            bottom
            handleBackOnClick={() => {
              setJobRoutePings([]);
              onBackClick();
            }}
            selectedJob={job}
            selectedOrder={order}
          />
        )}
        <MapV2
          center={mapCenter}
          markers={
            (order
              ? orderMarkers
              : job
                ? jobMarkers
                : truckAndMovingSiteMarkers) as ComponentProps<typeof MapV2>['markers']
          }
          maxZoom={14}
        />
      </Box>

      <>
        <Box
          m={2}
          sx={{ display: isLoadingRoutePings ? 'flex' : 'none' }}
          alignItems={'center'}
          gap={2}
        >
          <Typography noWrap textOverflow={'visible'} variant="body1">
            {t('live_map.actions.loading_route_history')}
          </Typography>
          <LoadingSpinner isVisible={isLoadingRoutePings} sx={{ width: 'fit-content' }} />
        </Box>
        {!!jobRoutePings && jobRoutePings.length > 0 && (
          <TimeIndicatorContextProvider>
            <Timeline
              pings={jobRoutePings}
              onHover={(arrInd: number) => {
                setSelectedJobRoutePingInd(arrInd);
              }}
            />
          </TimeIndicatorContextProvider>
        )}
      </>
      {job?.id && lazyLoadJobPings && jobRoutePings === null && (
        <LoadingButton
          size="small"
          color="brandV2Yellow"
          loading={isLoadingRoutePings}
          onClick={() => {
            setIsLoadingRoutePings(true);
            fetchTreadRoute(job.id).then((data) => {
              setJobRoutePings(data);
              setIsLoadingRoutePings(false);
            });
          }}
          sx={{ justifySelf: 'start', fontSize: '12px', fontWeight: 'bold' }}
        >
          {t('live_map.actions.view_route_history')}
        </LoadingButton>
      )}
    </Box>
  );
};

export default observer(TreadLiveMapV2);
