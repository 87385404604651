import { useTheme } from '@mui/material/styles';
import { GeoJSONSourceSpecification, GeometryType } from '@nbai/nbmap-gl';
import type { Subscription } from '@rails/actioncable';
import {
  JobState,
  NextBillionAssetLocations_LatestLocation_Read,
} from '@treadinc/horizon-api-spec';
import circle from '@turf/circle';
import { Feature, Geometry } from '@turf/helpers';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { flatten } from 'lodash';
import { useCallback, useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useApprovals } from '~hooks//useApprovals/useApprovals';
import { useGeocode } from '~hooks//useGeocode';
import { Job, JobTripEvent } from '~hooks//useJob/models';
import { Geofence, useSites } from '~hooks//useSites';
import {
  LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
  LIVE_MAP_GEOFENCE_LINE_LAYER_ID,
  LIVE_MAP_GEOFENCE_SOURCE_ID,
  LIVE_MAP_HIGHLIGHT_HALO_LAYER_ID,
  LIVE_MAP_HIGHLIGHT_LAYER_ID,
  LIVE_MAP_HIGHLIGHT_SOURCE_ID,
  LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_LAYER_ID,
  LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID,
  LIVE_MAP_MOVING_SITE_PIN_LAYER_ID,
  LIVE_MAP_MOVING_SITE_SOURCE_ID,
  LIVE_MAP_PINS_SOURCE_ID,
  LIVE_MAP_ROUTE_LAYER_ID,
  LIVE_MAP_ROUTE_SOURCE_ID,
  LIVE_MAP_SITES_LAYER_ID,
  LIVE_MAP_SITES_SOURCE_ID,
  LIVE_MAP_TRUCK_SOURCE_ID,
} from '~hooks/useLiveMap/constants';
import {
  LiveMapFeature,
  LiveMapJobEventData,
  LiveMapRealTimeEventData,
} from '~hooks/useLiveMap/models';
import connection from '~services/connectionModule';
import { PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { dateFormat } from '~utils/dateTime';

import { NextBillionAssetLocation } from '../useNextBillionAssetLocationHistories/models';
import { Order } from '../useOrders';
import TruckMarkerFactory from './TruckMarkerFactory';

interface SubscribeToLiveLocationUpdatesProps {
  onMessageCallBack: (data: LiveMapFeature[]) => void;
}
interface SubscribeToLiveJobUpdatesProps {
  onMessageCallBack: (data: Job) => void;
}
export const useLiveMap = () => {
  const [subscription, setSubscription] = useState<Subscription>();
  const [jobStateSubscription, setJobStateSubscription] = useState<Subscription>();
  const theme = useTheme();
  const { userStore, liveMapStore } = useStores();
  const { getDriverRoute, getAssetId } = useGeocode();
  const { getSiteById } = useSites();
  const { getJobApprovalTripEvents } = useApprovals();

  const subscribeToJobStateUpdates = ({
    onMessageCallBack,
  }: SubscribeToLiveJobUpdatesProps) => {
    const cable = connection.realTimeConnection;
    const subscription = cable?.subscriptions?.create?.(
      {
        channel: realTimeChannels.CompanyJobUpdateChannel,
        // This should be the user's company_id _or_ the company_id scoped in a given
        // Page?
        company_id: userStore.userCompany?.id,
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        connected: () => () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        disconnected: () => () => {},
        received: (resp) => {
          const updatedJob = Job.parse(resp?.data);
          if (updatedJob.isActive) {
            const jobEvent = LiveMapJobEventData.parse('', '', updatedJob);
            liveMapStore.addActiveJobEventData(jobEvent);
          } else {
            liveMapStore.removeActiveJob(updatedJob.id);
          }
        },
      },
    );
    setJobStateSubscription(subscription);
  };
  const createLiveMapTrucksLayer = () => {
    return [TruckMarkerFactory.makeMarker().textField('equipmentTrimmed').toLayer()];
  };

  //Add type once we have it, use locations or you can send here already structured "Feature" items up to you
  const createLiveMapTrucksSource = () => {
    //Add type once we have it
    return {
      id: LIVE_MAP_TRUCK_SOURCE_ID,
      sourceData: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          //Add type once we have it
          features: [],
          // Features: locations?.length
          //   ? //@ts-ignore
          //     Locations?.map((location) => ({
          //       Type: 'Feature',
          //       Properties: {
          //         DriverLicencePlate: location.driver?.name || 'No driver name',
          //       },
          //       Geometry: {
          //         Type: 'Point',
          //         Coordinates: [
          //           //replace with correct ones once we have them
          //           Location.waypoints[0].site.lon,
          //           Location.waypoints[0].site.lat,
          //         ],
          //       },
          //     }))
          //   : [],
        },
      },
    };
  };
  const getFeatures = (jobEvents: JobTripEvent[]) =>
    jobEvents?.length
      ? jobEvents?.map((jobEvent, index) => ({
          type: 'Feature',
          properties: {
            description: `<strong>State: </strong><p>${
              t([`${`status.${jobEvent?.state}`}`]) || ''
            }</p><p>${dateFormat(jobEvent?.createdAt, 'hh:mm A')}</p>`,
            state: jobEvent.state || '',
            order: index + 1,
          },
          geometry: {
            type: 'Point',
            coordinates: [jobEvent.lng, jobEvent.lat],
          },
        }))
      : [];
  // Temp work to get the trucks to show up on the map
  const createJobEventTrucksSource = (jobEvents: JobTripEvent[]) => {
    const filteredJobEvents = jobEvents?.filter((jobEvent) => !!jobEvent.lat);
    const previousStates = filteredJobEvents?.slice(0, -1);
    const lastState = filteredJobEvents?.slice(-1)[0];

    return [
      {
        // Source for the last truck location
        id: LIVE_MAP_TRUCK_SOURCE_ID,
        sourceData: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: lastState && getFeatures([lastState]),
          },
        },
      },
      {
        // Source for the previous truck state locations
        id: LIVE_MAP_PINS_SOURCE_ID,
        sourceData: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: previousStates?.length ? getFeatures(previousStates) : [],
          },
        },
      },
    ];
  };

  const createLiveMapRouteLayer = () => {
    return [
      {
        id: LIVE_MAP_ROUTE_LAYER_ID,
        source: LIVE_MAP_ROUTE_SOURCE_ID,
        type: 'circle',
        paint: {
          'circle-color': 'gray',
          'circle-radius': 4,
        },
      },
    ];
  };

  /**
   * Retrieves the route features data for a given job.
   * This parses the response from the NextBillion getAssetLocations API and returns the route features.
   * Start and end time are calculated based on the day of the job's startAt or current time.
   *
   * Returns an empty array if the driver is not found or if the assetId in NB is not found.
   * @param job - The job object.
   */
  const fetchNBRoute = async (job: Job) => {
    const data: any[] = [];
    const driver = job.driver;
    if (!driver) {
      return data;
    }
    const assetId = await getAssetId({
      userId: job.driver.id,
    });
    if (!assetId) {
      return data;
    }

    const jobEvents = await getJobApprovalTripEvents({ id: job.id });

    // The first to_pickup event is the trip_event for when the driver starts the job
    const pendingWaypoints = jobEvents.find(
      (event) => event.state === JobState.TO_PICKUP,
    );
    const startTime = pendingWaypoints?.createdAt
      ? pendingWaypoints.createdAt
      : dayjs().tz().startOf('day');

    // The completed event is the trip_event for when the driver ends the job
    const completedJobEvent = jobEvents.find(
      (event) => event.state === JobState.COMPLETED,
    );
    const endTime = completedJobEvent?.createdAt
      ? completedJobEvent?.createdAt
      : // If the job is not completed, use the last event's time
        jobEvents.slice(-1)[0].createdAt.endOf('day');

    let more = true;
    let pageNumber = liveMapStore.driverRoutePageNumber;
    const secondsToMilliseconds = 1000;

    while (more && pageNumber <= 100) {
      const trackingData = await getDriverRoute({
        assetId: assetId,
        startTime: startTime.unix() * secondsToMilliseconds,
        endTime: endTime.unix() * secondsToMilliseconds,
        geometryType: GeometryType.POLYLINE_6,
        pageNumber: pageNumber,
      });

      more = trackingData.data.page.hasmore;
      if (more) {
        pageNumber += 1;
      }

      // Filter the data based on the job id
      data.push(trackingData.data);
    }

    // Set the page number for the new data checks in the future
    liveMapStore.setRoutePageNumber(pageNumber);

    const routeData = flatten(data)
      .flatMap((nbResponse) => nbResponse.list)
      .map((geojson) => geojson.location);

    return routeData;
  };

  const getAllLocationsByJobId = useCallback(
    async (jobId: string, completed: boolean) => {
      let routeData: NextBillionAssetLocation[] = [];
      let afterLink = '';
      let hasMore = true;

      while (hasMore) {
        const params: PaginationQuery = {
          // NOTE: this is the _only_ endpoint that has a page limit higher than 100
          'page[limit]': 1000,
          'filter[load][finished]': completed,
        };
        if (afterLink) {
          params[`page[after]`] = afterLink;
        }

        const { data, pagination } =
          await connection.getPaginated<NextBillionAssetLocations_LatestLocation_Read>(
            `${API_VERSION}/jobs/${jobId}/location_history`,
            { params },
            t(
              'error_messages.next_billion.failed_to_fetch_asset_locations_by_job',
            ) as string,
          );

        routeData = routeData.concat(data.map(NextBillionAssetLocation.parse));
        if (pagination?.after && data.length !== 0) {
          afterLink = pagination.after;
        } else {
          hasMore = false;
        }
      }

      return routeData;
    },
    [],
  );

  const fetchTreadRoute = useCallback(async (jobId: string) => {
    const completedLocations = getAllLocationsByJobId(jobId, true);
    const [completed] = await Promise.all([completedLocations]);

    const flattenedCompleted = flatten(completed).flatMap((item) => item);
    return flattenedCompleted;
  }, []);

  interface RouteData {
    lat: number;
    lon: number;
  }

  const getRouteFeaturesData = (trackedData: RouteData[]) => {
    const features: Feature<Geometry>[] = [];

    trackedData.forEach((geo) => {
      const feature: Feature<Geometry> = {
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: [geo.lon, geo.lat],
          type: 'Point',
        },
      };
      features.push(feature);
    });

    return features;
  };

  /**
   * Creates a GeoJSON source for route data that is stored in next billion.
   *
   * @param routeData - The NB route response data.
   * @param currentRouteFeatures - The current route features on the map that will be appended to the new route features.
   *
   * @returns The GeoJSON source specification object for the route data.
   */
  const createLiveMapRouteSource = (
    routeData: any[],
    currentRouteFeatures: any[],
  ): GeoJSONSourceSpecification => {
    const routeFeatures = getRouteFeaturesData(routeData);

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        // Remove the last feature from the current route features so since that data will be refetched to avoid duplicates
        features: [...currentRouteFeatures.slice(0, -1), ...routeFeatures],
      },
    };
  };

  const createLiveMapSitesLayer = () => [
    {
      id: LIVE_MAP_SITES_LAYER_ID,
      source: LIVE_MAP_SITES_SOURCE_ID,
      type: 'symbol',
      layout: {
        'icon-image': 'pin_icon',
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-size': 0.5,
        'icon-optional': false,
      },
    },
  ];

  /**
   * Creates a circle Polygon based on the provided site information to render on the map.
   * @param siteGeofence The Geofence object containing the circle center and radius.
   */
  const createCircleGeofence = (siteGeofence: Geofence) => {
    if (!siteGeofence.circleCenter || !siteGeofence.circleRadius) return;

    const center = [siteGeofence.circleCenter?.lon, siteGeofence.circleCenter?.lat];

    // All Geofences radii are in meters both on NextBillion and our database
    const geofenceDefault = circle(center, siteGeofence.circleRadius, {
      units: 'meters',
    });

    return geofenceDefault;
  };

  /**
   * Retrieves the site feature by site ID.
   * @param siteId The ID of the site.
   * @returns An array of site features to render on mapbox.
   */
  const getSiteFeatureBySiteId = async (siteId: string, color: string) => {
    const site = await getSiteById(siteId);

    const siteDetails = {
      name: site.name,
      address: site.address?.streetAddress,
      externalId: site.externalId,
      type: site.siteType,
      geofenceType: site.nextBillionGeofence
        ? site.nextBillionGeofence.geojson
          ? 'Polygon'
          : 'Circle'
        : '-',
    };

    const pinFeature = {
      type: 'Feature',
      properties: {
        color: color,
        size: 8,
        ...siteDetails,
      },
      geometry: {
        type: 'Point',
        coordinates: [site.lng, site.lat],
      },
    };

    // Geofence feature
    let geofenceFeature = null;
    if (site.nextBillionGeofence) {
      const fence = site.nextBillionGeofence?.geojson
        ? // If the site has a geofence, add it to the map
          site.nextBillionGeofence.geojson
        : // Otherwise its a circle geofence
          createCircleGeofence(site.nextBillionGeofence);

      geofenceFeature = {
        type: 'Feature',
        geometry: fence?.geometry,
        properties: siteDetails,
      };
    }

    return { pinFeature, geofenceFeature };
  };

  /**
   * Retrieves the site source features data for pick up and drop off sites for a job.
   * @param job - The job object.
   */
  const getSiteFeaturesData = async (item: Job | Order) => {
    const pinFeatures: any[] = [];
    const geofenceFeatures: any[] = [];

    const pickUpSiteId = item?.pickUpWayPoints?.[0].site?.id;
    if (pickUpSiteId) {
      // Site Pin feature
      const pickupSiteFeatures = await getSiteFeatureBySiteId(
        pickUpSiteId,
        theme.palette.warning.main,
      );

      pinFeatures.push(pickupSiteFeatures.pinFeature);
      // Sites may not have geofences
      if (pickupSiteFeatures.geofenceFeature) {
        geofenceFeatures.push(pickupSiteFeatures.geofenceFeature);
      }
    }

    const dropOffSiteId = item?.dropOffWayPoints?.[0]?.site?.id;
    if (dropOffSiteId) {
      const dropOffSiteFeatures = await getSiteFeatureBySiteId(
        dropOffSiteId,
        theme.palette.warning.main,
      );
      pinFeatures.push(dropOffSiteFeatures.pinFeature);
      // Sites may not have geofences
      if (dropOffSiteFeatures.geofenceFeature) {
        geofenceFeatures.push(dropOffSiteFeatures.geofenceFeature);
      }
    }

    return { pinFeatures, geofenceFeatures };
  };

  /**
   * Creates a GeoJSON source for the live map sites.
   * @param job - The job object.
   */
  const createLiveMapSitesSource = async (item: Job | Order) => {
    const siteFeatures = await getSiteFeaturesData(item);

    const pinFeaturesData = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: siteFeatures.pinFeatures,
      },
    };

    const geofenceFeaturesData = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: siteFeatures.geofenceFeatures,
      },
    };

    return { pinFeaturesData, geofenceFeaturesData };
  };

  const createLiveMapMovingPinLayer = () => [
    {
      id: LIVE_MAP_MOVING_SITE_PIN_LAYER_ID,
      source: LIVE_MAP_MOVING_SITE_SOURCE_ID,
      type: 'circle',
      paint: {
        'circle-color': '#132732',
        'circle-radius': 8,
        'circle-stroke-color': 'white',
        'circle-stroke-width': 2,
      },
    },
  ];

  const createLiveMapMovingSiteLayer = () => [
    {
      id: LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_LAYER_ID,
      type: 'fill',
      source: LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID,
      paint: {
        'fill-color': '#132732',
        'fill-opacity': ['get', 'opacity'],
      },
    },
  ];

  const createLiveMapGeofenceLayer = () => [
    // Defines the fill color for the geofence
    {
      id: LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
      type: 'fill',
      source: LIVE_MAP_GEOFENCE_SOURCE_ID,
      paint: {
        'fill-color': '#3bb2d0',
        'fill-opacity': 0.2,
      },
    },
    // Defines the line border for the geofence
    {
      id: LIVE_MAP_GEOFENCE_LINE_LAYER_ID,
      type: 'line',
      source: LIVE_MAP_GEOFENCE_SOURCE_ID,
      paint: {
        'line-color': '#3bb2d0',
        'line-width': 2,
      },
    },
  ];

  const liveMapHighlightLayer = () => {
    return [
      {
        id: LIVE_MAP_HIGHLIGHT_HALO_LAYER_ID,
        type: 'circle',
        source: LIVE_MAP_HIGHLIGHT_SOURCE_ID,
        paint: {
          'circle-color': ['get', 'haloColor'],
          'circle-radius': ['get', 'haloSize'],
          'circle-stroke-width': 0,
        },
      },
      {
        id: LIVE_MAP_HIGHLIGHT_LAYER_ID,
        type: 'circle',
        source: LIVE_MAP_HIGHLIGHT_SOURCE_ID,
        paint: {
          'circle-color': ['get', 'color'],
          'circle-radius': ['get', 'size'],
          'circle-stroke-width': 0.5,
          'circle-stroke-color': 'white',
        },
      },
    ];
  };

  return {
    createLiveMapTrucksLayer,
    createLiveMapTrucksSource,
    createJobEventTrucksSource,
    subscription,
    subscribeToJobStateUpdates,
    jobStateSubscription,
    createLiveMapRouteLayer,
    createLiveMapRouteSource,
    createLiveMapSitesLayer,
    createLiveMapSitesSource,
    createLiveMapGeofenceLayer,
    createLiveMapMovingPinLayer,
    createLiveMapMovingSiteLayer,
    liveMapHighlightLayer,
    fetchNBRoute,
    fetchTreadRoute,
  };
};
