import dayjs from 'dayjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DateRangePicker, {
  DateRangePickerDateSelectFunction,
  DateRangePickerProps,
  NullableDate,
} from '~components/Filters/DateRangePicker';
import { useStores } from '~store';
import { usePrevious } from '~utils/hooks/usePrevious';

import { DispatchView } from '../Dispatch';

interface SharedDatesFilterProps {
  dateRangePickerMode?: DateRangePickerProps['mode'];
  dateRangeUseTodayFormat?: DateRangePickerProps['useTodayFormat'];
  view: `${Exclude<DispatchView, DispatchView.CALENDAR>}`;
}

const SharedDatesFilter = observer(
  ({ dateRangePickerMode, dateRangeUseTodayFormat, view }: SharedDatesFilterProps) => {
    const { ordersDispatchStore, driverSchedulerStore } = useStores();

    const isOrdersView = view === 'orders';
    const [initialStartDate, initialEndDate]: NullableDate[] = useMemo(() => {
      if (isOrdersView) {
        const dateRange = [
          ordersDispatchStore.filters.startDate
            ? dayjs.tz(ordersDispatchStore.filters.startDate)
            : null,
          ordersDispatchStore.filters.endDate
            ? dayjs.tz(ordersDispatchStore.filters.endDate)
            : null,
        ];

        return dateRange;
      }

      if (driverSchedulerStore.dateFilters.startDate) {
        const dateRange = [
          dayjs.tz(driverSchedulerStore.dateFilters.startDate),
          dayjs.tz(driverSchedulerStore.dateFilters.startDate),
        ];

        return dateRange;
      }

      const storedFilters = ordersDispatchStore.retrieveStoredFilters();

      if (storedFilters.startDate) {
        const date = dayjs.tz(storedFilters.startDate);
        const formattedDate = date.format('YYYY-MM-DD');
        const dateRange = [date, date];

        driverSchedulerStore.setDateFilters({
          startDate: formattedDate,
          endDate: formattedDate,
        });

        return dateRange;
      }

      const date = dayjs.tz();
      const formattedDate = date.format('YYYY-MM-DD');
      const dateRange = [date, date];

      driverSchedulerStore.setDateFilters({
        startDate: formattedDate,
        endDate: formattedDate,
      });

      return dateRange;
    }, [
      isOrdersView,
      ordersDispatchStore.filters.startDate,
      ordersDispatchStore.filters.endDate,
      driverSchedulerStore.dateFilters.startDate,
    ]);

    const [dateRange, setDateRange] = useState({
      start: initialStartDate,
      end: initialEndDate,
    });

    const handleDateFilterChange = useCallback<DateRangePickerDateSelectFunction>(
      (startDate, endDate) => {
        const start = startDate;
        const end = endDate ?? startDate;

        setDateRange({ start, end });
      },
      [],
    );

    const handleDateFilterClose = useCallback(() => {
      const startDate = dateRange.start?.format('YYYY-MM-DD');
      const endDate = dateRange.end?.format('YYYY-MM-DD');

      let startDateDidChange = false;
      let endDateDidChange = false;

      if (isOrdersView) {
        startDateDidChange = ordersDispatchStore.filters.startDate !== startDate;
        endDateDidChange = ordersDispatchStore.filters.endDate !== endDate;
      } else {
        startDateDidChange = driverSchedulerStore.dateFilters.startDate !== startDate;
      }

      if (startDateDidChange || endDateDidChange) {
        ordersDispatchStore.setFilters({ startDate, endDate }, true);
        const driverStartDate = startDate ?? dayjs.tz().format('YYYY-MM-DD');
        driverSchedulerStore.setDateFilters({
          startDate: driverStartDate,
          endDate: driverStartDate,
        });
      }
    }, [
      dateRange.start,
      dateRange.end,
      ordersDispatchStore.filters.startDate,
      ordersDispatchStore.filters.endDate,
      driverSchedulerStore.dateFilters.startDate,
    ]);

    const previousOrdersSearch = usePrevious(ordersDispatchStore.filters.search);
    const currentOrdersSearch = ordersDispatchStore.filters.search;

    useEffect(() => {
      const isSearchingOrders = Boolean(currentOrdersSearch?.length);
      const isClearingPreviousOrdersSearch =
        !isSearchingOrders && Boolean(previousOrdersSearch?.length);

      if (isSearchingOrders) {
        setDateRange({ start: null, end: null });
      } else if (isClearingPreviousOrdersSearch) {
        setDateRange({ start: initialStartDate, end: initialEndDate });
      }
    }, [previousOrdersSearch, currentOrdersSearch, initialStartDate, initialEndDate]);

    return (
      <DateRangePicker
        allowAllDates={isOrdersView}
        endDate={dateRange.end}
        mode={dateRangePickerMode}
        onAnchorClose={handleDateFilterClose}
        onDateSelect={handleDateFilterChange}
        showShortcuts
        startDate={dateRange.start}
        useTodayFormat={dateRangeUseTodayFormat}
        variant={isOrdersView ? 'date_range' : 'single_date'}
      />
    );
  },
);

export default SharedDatesFilter;
