import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Refresh from '@mui/icons-material/Refresh';
import { svgIconClasses } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import IconButton, { iconButtonClasses } from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import axios from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { createElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useOrders } from '~hooks/useOrders';
import { Service } from '~hooks/useServices';
import theme from '~theme/AppTheme';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import {
  QuantityType,
  serviceIsHiredTruck,
  serviceIsStandingHaul,
} from '../newOrderFormSchema';
import {
  FlexColumn,
  FlexContainer,
  FlexRow,
  Hint,
  Label,
  Value,
} from './shared-components';
import useOrderEstimate from './useOrderEstimate';

export default function DeliveryEstimates() {
  const form = useFormContext();

  const { numberFormatter } = useCompanyCurrency();

  const isEditing = Boolean(form.watch('id'));
  const [isEditingMode, setIsEditingMode] = useState(false);
  const shouldDisableAllFields = isEditing && !isEditingMode;

  const { getOrderEstimate } = useOrders();
  const { getOrderEstimateArgs } = useOrderEstimate();

  const [isEstimating, setIsEstimating] = useState(false);

  const watchedQuantityType = form.watch('quantityType') as NameIdSchemaRequired | null;
  const watchedService = form.watch('service') as Service | null;
  const watchedServiceQuantity = form.watch('serviceQuantity') as string | number | null;
  const watchedUnitsPerHour = form.watch('unitsPerHour') as string | number | null;

  const isStandingHaulService = serviceIsStandingHaul(watchedService);
  const isHiredTruckService = serviceIsHiredTruck(watchedService);

  const formattedUnitsPerHour = useMemo(() => {
    if (nullableStringOrNumberIsValidNumber(watchedUnitsPerHour)) {
      return numberFormatter(Number(watchedUnitsPerHour));
    }

    return '-';
  }, [watchedUnitsPerHour]);

  const shouldDisableTruckCountField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    return selectedQuantityTypeId === QuantityType.TRUCKS;
  }, [watchedQuantityType]);

  const shouldDisableTotalLoadsField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    return selectedQuantityTypeId === QuantityType.LOAD;
  }, [watchedQuantityType]);

  const shouldDisableQuantityField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    const isTrucksQuantityTypeSelected = selectedQuantityTypeId === QuantityType.TRUCKS;
    const isLoadsQuantityTypeSelected = selectedQuantityTypeId === QuantityType.LOAD;

    if (isTrucksQuantityTypeSelected || isLoadsQuantityTypeSelected) {
      return false;
    }

    return true;
  }, [watchedQuantityType]);

  const handleRefreshEstimatesClick = useCallback(async () => {
    if (getOrderEstimateArgs) {
      setIsEstimating(true);
      form.clearErrors('jobTime');

      try {
        const estimate = await getOrderEstimate(getOrderEstimateArgs);

        if (estimate) {
          form.setValue('truckCount', `${estimate.truckQuantity ?? ''}`);
          form.setValue('loadsPerTruck', `${estimate.loadsPerTruck ?? ''}`);
          form.setValue('totalLoads', `${estimate.totalLoads ?? ''}`);
          form.setValue('jobQuantity', `${estimate.deliveredPerTruck ?? ''}`);
          form.setValue('quantity', `${estimate.totalUnits ?? ''}`);
          form.setValue('unitsPerHour', `${estimate.unitsPerHour ?? ''}`);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const field = error.response?.data?.error?.errors?.[0]?.field;

          if (field === 'job_duration_minutes') {
            const message = error.response?.data?.error?.errors?.[0]?.message;
            const { groups } = /(?<duration>[0-9]+)/.exec(message) ?? {};

            if (groups?.duration) {
              const updatedMessage = t(
                'dispatch.order.cycle.job_duration_minutes_greater_than_error_message',
                { value: (Number(groups.duration) / 60).toFixed(2) },
              );
              form.setError('jobTime', { message: updatedMessage, type: 'manual' });
            }
          }
        }
      } finally {
        setIsEstimating(false);
      }
    }
  }, [JSON.stringify(getOrderEstimateArgs)]);

  const handleEditClick = useCallback(() => {
    setIsEditingMode(true);
  }, []);

  const handleCancelClick = useCallback(() => {
    setIsEditingMode(false);
    form.resetField('truckCount');
    form.resetField('loadsPerTruck');
    form.resetField('totalLoads');
    form.resetField('jobQuantity');
    form.resetField('quantity');
    form.resetField('unitsPerHour');
  }, []);

  const handleConfirmClick = useCallback(() => {
    setIsEditingMode(false);
  }, []);

  useEffect(() => {
    if (shouldDisableTruckCountField) {
      form.setValue('truckCount', watchedServiceQuantity);
    }
  }, [shouldDisableTruckCountField, watchedQuantityType, watchedServiceQuantity]);

  useEffect(() => {
    if (shouldDisableTotalLoadsField) {
      form.setValue('totalLoads', watchedServiceQuantity);
    }
  }, [shouldDisableTotalLoadsField, watchedQuantityType, watchedServiceQuantity]);

  useEffect(() => {
    if (shouldDisableQuantityField) {
      form.setValue('quantity', watchedServiceQuantity);
    }
  }, [shouldDisableQuantityField, watchedQuantityType, watchedServiceQuantity]);

  return (
    <>
      <FlexRow sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <FormSectionTitle title={t('dispatch.order.delivery_estimates.title')} />

        {isEditing && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {isEditingMode ? (
              <>
                <IconCTA
                  color={theme.brandV2.colors.treadGreen}
                  icon={Check}
                  onClick={handleConfirmClick}
                  tooltip={t('dispatch.order.delivery_estimates.confirm_values')}
                />

                <IconCTA
                  color={theme.brandV2.colors.treadRed}
                  icon={Close}
                  onClick={handleCancelClick}
                  tooltip={t('dispatch.order.delivery_estimates.reset_values')}
                />
              </>
            ) : (
              <IconCTA
                color={theme.brandV2.colors.treadBlue}
                icon={EditOutlined}
                onClick={handleEditClick}
                tooltip={t('dispatch.order.delivery_estimates.edit_estimates')}
              />
            )}

            <IconCTA
              color={theme.brandV2.colors.treadOrangeDark}
              icon={Refresh}
              isDisabled={!getOrderEstimateArgs}
              isLoading={isEstimating}
              onClick={isEstimating ? undefined : handleRefreshEstimatesClick}
              tooltip={t('dispatch.order.delivery_estimates.edit_estimates')}
            />
          </Box>
        )}
      </FlexRow>

      <Label>{t('dispatch.order.delivery_estimates.estimates_based_on')}</Label>

      <FlexContainer sx={{ mt: 2 }}>
        <FlexRow>
          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.truck_qty')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableTruckCountField || shouldDisableAllFields}
              errors={form.formState.errors}
              isRequired
              name="truckCount"
              type="number"
            />
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.loads_per_truck')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableAllFields}
              errors={form.formState.errors}
              name="loadsPerTruck"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.loads_per_truck_hint')}</Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.total_loads')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableTotalLoadsField || shouldDisableAllFields}
              errors={form.formState.errors}
              name="totalLoads"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.total_loads_hint')}</Hint>
          </FlexColumn>
        </FlexRow>

        <FlexRow>
          <FlexColumn noGap>
            <Label isBold>
              {t('dispatch.order.delivery_estimates.delivered_per_truck')}
            </Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableAllFields}
              errors={form.formState.errors}
              isRequired={!(isStandingHaulService || isHiredTruckService)}
              name="jobQuantity"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.delivered_per_truck_hint')}</Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.total_units')}</Label>

            <TextFormField
              control={form.control}
              disabled={shouldDisableQuantityField || shouldDisableAllFields}
              errors={form.formState.errors}
              isRequired={!isHiredTruckService}
              name="quantity"
              type="number"
            />

            <Hint>{t('dispatch.order.delivery_estimates.total_units_hint')}</Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label isBold>{t('dispatch.order.delivery_estimates.units_per_hour')}</Label>

            <Value isLarge sx={{ minHeight: '46px', lineHeight: '46px' }}>
              {formattedUnitsPerHour}
            </Value>

            {/* Keep this one hidden, as it is for control registering purpose only */}
            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              name="unitsPerHour"
              sx={{ display: 'none' }}
            />

            <Hint>{t('dispatch.order.delivery_estimates.units_per_hour_hint')}</Hint>
          </FlexColumn>
        </FlexRow>
      </FlexContainer>
    </>
  );
}

interface IconCTAProps {
  color: string;
  icon: typeof SvgIcon;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip: string;
}

function IconCTA({ color, icon, isDisabled, isLoading, onClick, tooltip }: IconCTAProps) {
  return (
    <BasicTooltip title={tooltip}>
      <IconButton
        disabled={isDisabled}
        onClick={onClick}
        size="small"
        sx={{
          border: `solid 1px ${color}`,
          p: '3px',
          [`&.${iconButtonClasses.disabled}`]: {
            borderColor: (theme) => theme.brandV2.colors.treadGray5,
            cursor: 'not-allowed',
            pointerEvents: 'auto',
            [`& .${svgIconClasses.root}`]: {
              color: (theme) => theme.brandV2.colors.treadGray5,
            },
          },
        }}
      >
        {isLoading ? (
          <CircularProgress
            size="16px"
            sx={{ [`&.${circularProgressClasses.root}`]: { color } }}
          />
        ) : (
          createElement(icon, { sx: { color, fontSize: '16px' } })
        )}
      </IconButton>
    </BasicTooltip>
  );
}
