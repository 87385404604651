import Timeline, { TimelineProps } from '@mui/lab/Timeline';
import TimelineConnector, { TimelineConnectorProps } from '@mui/lab/TimelineConnector';
import TimelineContent, { TimelineContentProps } from '@mui/lab/TimelineContent';
import TimelineDot, { TimelineDotProps } from '@mui/lab/TimelineDot';
import TimelineItem, {
  timelineItemClasses,
  TimelineItemProps,
} from '@mui/lab/TimelineItem';
import TimelineSeparator, { TimelineSeparatorProps } from '@mui/lab/TimelineSeparator';
import Box, { BoxProps } from '@mui/material/Box';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { styled, TypographyProps } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { createElement } from 'react';

import theme from '~theme/AppTheme';

export const EVENT_TIMLINE_JOB_EVENT_TIME_FORMAT = 'h:mm A';
const EVENT_TIMLINE_JOB_DATE_TIME_FORMAT = 'ddd MMM DD ‘YY';

/**
 * EventTimeline
 */
export type EventTimelineProps = TimelineProps;

export const EventTimeline = styled(Timeline)<EventTimelineProps>(() => ({
  padding: 0,
  width: '100%',
  [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 },
}));

/**
 * EventTimelinePrimaryText
 */
export type EventTimelinePrimaryTextProps = TypographyProps;

export const EventTimelinePrimaryText = styled(Typography)<EventTimelinePrimaryTextProps>(
  () => ({
    fontSize: '12px',
    color: theme.brandV2.colors.treadBlack,
  }),
);

/**
 * EventTimelineSecondaryText
 */
export type EventTimelineSecondaryTextProps = TypographyProps;

export const EventTimelineSecondaryText = styled(
  Typography,
)<EventTimelineSecondaryTextProps>(() => ({
  fontSize: '10px',
  color: theme.brandV2.colors.treadGray3,
})) as typeof Typography;

/**
 * EventTimelineLabel
 */
type EventTimelineLabelProps = EventTimelinePrimaryTextProps;

const EventTimelineLabel = styled(EventTimelinePrimaryText)<EventTimelineLabelProps>(
  () => ({
    fontWeight: 600,
  }),
);

/**
 * EventTimelineItem
 */
export type EventTimelineItemProps = TimelineItemProps;

export function EventTimelineItem(props: EventTimelineItemProps) {
  return <TimelineItem {...props} sx={{ minHeight: 0, ...props.sx }} />;
}

/**
 * EventTimelineContentContainer
 */
export type EventTimelineContentContainerProps = TimelineContentProps & {
  hasDotIconAtLeft?: boolean;
};

export function EventTimelineContentContainer({
  hasDotIconAtLeft,
  ...rest
}: EventTimelineContentContainerProps) {
  return (
    <TimelineContent
      {...rest}
      sx={{ p: `6px 6px 6px ${hasDotIconAtLeft ? '6px' : '14px'}`, ...rest.sx }}
    />
  );
}

/**
 * EventTimelineContent
 */
export type EventTimelineContentProps = BoxProps & {
  date?: Dayjs;
  label?: string;
};

export function EventTimelineContent({
  children,
  date,
  label,
  sx,
}: EventTimelineContentProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mt: '3px', ...sx }}>
      <Box sx={{ flex: 0, minWidth: '80px' }}>
        {date ? (
          <>
            <EventTimelinePrimaryText>
              {dayjs.tz(date).format(EVENT_TIMLINE_JOB_EVENT_TIME_FORMAT)}
            </EventTimelinePrimaryText>

            <EventTimelineSecondaryText>
              {dayjs.tz(date).format(EVENT_TIMLINE_JOB_DATE_TIME_FORMAT)}
            </EventTimelineSecondaryText>
          </>
        ) : (
          <EventTimelineLabel>{label}</EventTimelineLabel>
        )}
      </Box>

      <Box sx={{ flex: 1 }}>{children}</Box>
    </Box>
  );
}

/**
 * EventTimelineSeparator
 */
export type EventTimelineSeparatorProps = TimelineSeparatorProps;

export function EventTimelineSeparator(props: EventTimelineSeparatorProps) {
  return <TimelineSeparator {...props} />;
}

/**
 * EventTimelineDot
 */
export type EventTimelineDotProps = TimelineDotProps & {
  innerColor: string;
  shape: 'dot' | 'square' | 'icon' | 'empty';
};

export const EventTimelineDot = styled(TimelineDot, {
  shouldForwardProp: (propName) =>
    !(['innerColor', 'shape'] as PropertyKey[]).includes(propName),
})<EventTimelineDotProps>(({ innerColor, shape }) => {
  const commonStyles = { boxShadow: 'none' };

  if (['dot', 'square'].includes(shape)) {
    const squareStyles = shape === 'square' ? { borderRadius: 0 } : {};

    return {
      ...commonStyles,
      ...squareStyles,
      backgroundColor: innerColor,
    };
  }

  if (shape === 'empty') {
    return {
      ...commonStyles,
      backgroundColor: 'white',
      paddingTop: 0,
      paddingBottom: 0,
      border: 0,
    };
  }

  return {
    ...commonStyles,
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: innerColor,
    borderWidth: 1,
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    transform: 'translateX(-4px)',
    width: '20px',
  };
});

/**
 * EventTimelineIcon
 */
export type EventTimelineIconProps = SvgIconProps & { icon: typeof SvgIcon };

export function EventTimelineIcon({ icon, ...rest }: EventTimelineIconProps) {
  return createElement(icon, {
    ...rest,
    sx: {
      color: theme.brandV2.colors.treadBlack,
      height: '20px',
      width: '20px',
      ...rest?.sx,
    },
  });
}

/**
 * EventTimelineConnector
 */
export type EventTimelineConnectorProps = TimelineConnectorProps;

export const EventTimelineConnector = styled(
  TimelineConnector,
)<EventTimelineConnectorProps>(() => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.brandV2.colors.treadGray7,
  margin: '-11.5px 0 -11.5px 5.25px',
}));
