import {
  deleteV1MaterialsId,
  getV1CompaniesCompanyIdMaterials,
  getV1CompaniesCompanyIdMaterialTypes,
  getV1Materials,
  Material_Read,
  patchV1MaterialsId,
  postV1Materials,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Material, MaterialTypeItem } from '~hooks/useMaterials/models';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';
interface CreateMaterialProps {
  material: Material;
  callBack?: (material: Material) => void;
}
interface GetMaterialTypesByCompanyIdProps {
  companyId: string;
  callBack?: (materialTypes: MaterialTypeItem[]) => void;
}

interface PostMaterialTypesByCompanyIdProps {
  companyId: string;
  material: Material;
  callBack?: (material: Material) => void;
}

interface DeleteMaterialProps {
  materialId: string;
  callBack?: () => void;
}
interface GetMaterialsTypeaheadProps {
  callback?: (materials: Material[]) => void;
  limit?: number;
  query?: string;
  cursor?: string;
}
interface GetMaterialsTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}

interface GetCompanyMaterialsTypeaheadProps extends GetMaterialsTypeaheadProps {
  companyId: string;
}

interface GetCompanyMaterialsTypeaheadQueryProps
  extends GetMaterialsTypeaheadQueryProps {}

export const useMaterials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { companyAssetsStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  const getAllMaterials = async (link?: PaginationLink, searchQuery?: string) => {
    setIsLoading(true);
    let query: PaginationQuery = {
      'page[limit]': companyAssetsStore.materialsPagination.limit,
    };
    query = addSearchHeaderParam({ searchValue: searchQuery, params: query });
    if (link && companyAssetsStore.materialsPagination[link]) {
      query[`page[${link}]`] = companyAssetsStore.materialsPagination[link];
    }

    try {
      const response = await getV1Materials({ query });
      const formatted = response.data.data.map(Material.parse);
      companyAssetsStore.setMaterials(formatted);
      const pagination = extractPagination(response);
      companyAssetsStore.setMaterialsPagination(pagination);
      companyAssetsStore.updateMaterialsPageNumber(link);

      return response;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.materials.failed_to_fetch') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };
  const createMaterial = async ({ material, callBack }: CreateMaterialProps) => {
    setIsLoading(true);
    try {
      const response = await postV1Materials({ body: Material.deparse(material) });
      const formatted = Material.parse(response.data.data);
      companyAssetsStore.addMaterial(formatted);
      callBack?.(formatted);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.materials.failed_to_create') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };
  const updateMaterial = async ({ material, callBack }: CreateMaterialProps) => {
    setIsUpdating(true);
    try {
      const response = await patchV1MaterialsId({
        path: { id: material.id },
        body: Material.deparseUpdate(material),
      });
      const formatted = Material.parse(response.data.data);
      companyAssetsStore.updateMaterial(formatted);
      callBack?.(formatted);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.materials.failed_to_update') as string,
      );
    } finally {
      setIsUpdating(false);
    }
  };
  const deleteMaterial = async ({ materialId, callBack }: DeleteMaterialProps) => {
    setIsLoading(true);
    try {
      await deleteV1MaterialsId({ path: { id: materialId } });
      companyAssetsStore.deleteMaterial(materialId);
      callBack?.();
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.materials.failed_to_delete') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: this should be a typeahead! since it's not paginated it'll break once
  // there are more than page[limit] MaterialTypes
  const getMaterialTypesByCompanyId = async ({
    companyId,
    callBack,
  }: GetMaterialTypesByCompanyIdProps) => {
    setIsLoading(true);
    const query: PaginationQuery = {
      'page[limit]': companyAssetsStore.materialsPagination.limit,
    };
    try {
      const response = await getV1CompaniesCompanyIdMaterialTypes({
        path: { 'company-id': companyId },
        query,
      });
      const formatted = response.data.data.map(MaterialTypeItem.parse);
      callBack?.(formatted);
      return formatted;
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.materials.failed_to_fetch_material_types') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: didn't port this to use postV1CompaniesCompanyIdMaterials because there are
  // multiple callers of this which expect a Material to be returned and aren't handling
  // the failure case where undefined is returned.
  const createMaterialByCompanyId = ({
    companyId,
    material,
    callBack,
  }: PostMaterialTypesByCompanyIdProps) => {
    setIsLoading(true);
    return connection
      .post<Material_Read>(
        `${API_VERSION}/companies/${companyId}/materials`,
        Material.deparse(material),
        {},
        $t('error_messages.materials.failed_to_create') as string,
      )
      .then((resp) => {
        const material = Material.parse(resp);
        // Todo: ensure this is correct
        companyAssetsStore.addMaterial(material);

        callBack?.(material);
        return material;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getMaterialsByCompanyId = async (
    companyId: string,
    link?: PaginationLink,
    searchQuery?: string,
  ) => {
    setIsLoading(true);
    let query: PaginationQuery = {
      'page[limit]': companyAssetsStore.materialsPagination.limit,
    };
    query = addSearchHeaderParam({ searchValue: searchQuery, params: query });
    if (link && companyAssetsStore.materialsPagination[link]) {
      query[`page[${link}]`] = companyAssetsStore.materialsPagination[link];
    }
    try {
      const response = await getV1CompaniesCompanyIdMaterials({
        path: { 'company-id': companyId },
        query,
      });

      const formatted = response.data.data.map(Material.parse);
      companyAssetsStore.setMaterials(formatted);
      const pagination = extractPagination(response);
      companyAssetsStore.setMaterialsPagination(pagination);
      companyAssetsStore.updateMaterialsPageNumber(link);
    } catch (error) {
      connection.handleRequestError(
        error,
        $t('error_messages.materials.failed_to_fetch') as string,
      );
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: i couldn't port this to use getV1CompaniesCompanyIdMaterialsTypeahead because
  // that endpoint returns an array of Material_Read_Typeahead but Material.parse expects
  // a Material_Read.
  const getMaterialsByCompanyIdTypeahead = async (
    options: GetCompanyMaterialsTypeaheadProps,
  ) => {
    const params: Partial<GetCompanyMaterialsTypeaheadQueryProps> = {
      'page[limit]': options?.limit || 100,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const { data, pagination } = await connection.getPaginated<Material_Read>(
        `${API_VERSION}/companies/${options.companyId}/materials/typeahead`,
        { params },
        $t('error_messages.materials.failed_to_fetch') as string,
      );
      const materials = data.map((material) => Material.parse(material));
      return { data: materials, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: i couldn't port this to use getV1MaterialsTypeahead because
  // that endpoint returns an array of Material_Read_Typeahead but Material.parse expects
  // a Material_Read.
  const getMaterialsTypeahead = async (options: GetMaterialsTypeaheadProps) => {
    const params: Partial<GetMaterialsTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 100,
    };

    if (options.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const { data, pagination } = await connection.getPaginated<Material_Read>(
        `${API_VERSION}/materials/typeahead`,
        { params },
        $t('error_messages.materials.failed_to_fetch') as string,
      );
      const materials = data.map((material) => Material.parse(material));
      return { data: materials, pagination };
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    isUpdating,
    createMaterial,
    deleteMaterial,
    updateMaterial,
    getMaterialTypesByCompanyId,
    createMaterialByCompanyId,
    getMaterialsByCompanyId,
    getMaterialsByCompanyIdTypeahead,
    getAllMaterials,
    getMaterialsTypeahead,
  };
};
