import { JobState } from '@treadinc/horizon-api-spec';

import { Job } from '~hooks/useJob';
import { nonEditableJobStates } from '~pages/Dispatch/constants/jobStates';

/**
 * Determines if an already sent job can be accepted
 * @param job Job instance
 * @returns `true` if the sent job is acceptable, `false` otherwhise
 */
const isSentJobAcceptable = (job: Job) => {
  const { status, driver, vendorJobAssignment, editable } = job;

  const isSent = status === JobState.SENT;
  const isNotAssigned = !driver && !vendorJobAssignment;

  return editable && isSent && isNotAssigned;
};

/**
 * Determines if a job can be sent to an assignee
 * @param job Job instance
 * @returns `true` if the job can be sent to an assignee, `false` otherwhise
 */
export const isJobSendable = (job: Job) => {
  const { editable: userCanEditJob, driver, vendorJobAssignment, status } = job;

  const isJobEligibleToBeSent =
    Boolean(driver || vendorJobAssignment) &&
    ![...nonEditableJobStates, JobState.ACCEPTED, JobState.REJECTED].includes(status);
  const isJobAssignedToVendor = Boolean(vendorJobAssignment);

  if (!isJobEligibleToBeSent) {
    return false;
  }

  if (userCanEditJob) {
    const userCanAccessDriver = Boolean(driver?.id);

    if (userCanAccessDriver || isJobAssignedToVendor) {
      return true;
    }
  }

  return false;
};

/**
 * Determines if a job can be assigned
 * @param job Job instance
 * @returns `true` if the job can be assigned, `false` otherwhise
 */
export const isJobAssignable = (job: Job) => {
  const hasDriver = Boolean(job.driver);
  const hasVendor = Boolean(job.vendorJobAssignment?.vendorAccount);
  const isAssigned = hasDriver || hasVendor;

  const isAcceptable = isSentJobAcceptable(job);
  const isAssignable = !isAcceptable && !isAssigned;

  return isAssignable;
};
