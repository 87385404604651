import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FilterList from '@mui/icons-material/FilterList';
import { buttonClasses, paperClasses } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AppliedFiltersChips, {
  AppliedFiltersChipsCollectedData,
} from '~components/Filters/AppliedFiltersChips';
import FilterMenuItem, { FilterMenuOption } from '~components/Filters/FilterMenuItem';
import Menu from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { SelectedFilter } from '~constants/enums';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import useOrdersDispatchFilters, {
  ordersDispatchFilterKeyBySelectedFilter,
} from '../../hooks/useOrdersDispatchFilters';
import {
  OrderDispatchQuery,
  useCustomersQuery,
  useDispatchNumbersQuery,
  useDriversQuery,
  useDropOffSitesQuery,
  useDummyQuery,
  usePickUpSitesQuery,
  useProjectExternalIdsQuery,
  useProjectsQuery,
  useRequestersQuery,
  useVendorsQuery,
} from './orders-dispatch-filters-queries/OrdersDispatchFiltersQueries';

const OrdersDispatchFilters = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>();

  const {
    availableOptions,
    updateAvailableOptions,
    appliedFilterChipsCollectedData,
    updateAppliedFilterChipsCollectedData,
  } = useOrdersDispatchFilters();

  const { userStore, ordersDispatchStore } = useStores();
  const companyId = getEffectiveUserCompanyId(userStore);

  const [searchQuery, setSearchQuery] = useState<{ input: string; debounced: string }>({
    input: '',
    debounced: '',
  });

  const debouncedSetSearchQuery = useRef(
    _.debounce((value: string) => {
      setSearchQuery((state) => ({ ...state, debounced: value }));
    }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS),
  ).current;

  const customersQuery = useCustomersQuery(companyId);
  const dispatchNumbersQuery = useDispatchNumbersQuery(companyId);
  const driversQuery = useDriversQuery(companyId);
  const dropOffSitesQuery = useDropOffSitesQuery(companyId, ordersDispatchStore.filters);
  const jobStatesQuery = useDummyQuery(availableOptions.jobStates);
  const pickUpSitesQuery = usePickUpSitesQuery(companyId, ordersDispatchStore.filters);
  const projectExternalIdsQuery = useProjectExternalIdsQuery();
  const projectsQuery = useProjectsQuery(companyId);
  const requestersQuery = useRequestersQuery(companyId);
  const siteTypesQuery = useDummyQuery(availableOptions.siteTypes);
  const vendorsQuery = useVendorsQuery(companyId);

  const activeQuery = useMemo(() => {
    if (!selectedFilter) {
      return null;
    }

    const queries: Record<SelectedFilter, OrderDispatchQuery> = {
      [SelectedFilter.CUSTOMERS]: customersQuery,
      [SelectedFilter.DISPATCH_NUMBERS]: dispatchNumbersQuery,
      [SelectedFilter.DRIVERS]: driversQuery,
      [SelectedFilter.DROP_OFF_SITES]: dropOffSitesQuery,
      [SelectedFilter.JOB_STATES]: jobStatesQuery,
      [SelectedFilter.PICK_UP_SITES]: pickUpSitesQuery,
      [SelectedFilter.PROJECTS]: projectsQuery,
      [SelectedFilter.PROJECTS_EXTERNAL_IDS]: projectExternalIdsQuery,
      [SelectedFilter.REQUESTERS]: requestersQuery,
      [SelectedFilter.SITE_TYPES]: siteTypesQuery,
      [SelectedFilter.VENDORS]: vendorsQuery,
    };

    return queries[selectedFilter];
  }, [
    selectedFilter,
    customersQuery,
    dispatchNumbersQuery,
    driversQuery,
    dropOffSitesQuery,
    jobStatesQuery,
    pickUpSitesQuery,
    projectExternalIdsQuery,
    projectsQuery,
    requestersQuery,
    siteTypesQuery,
    vendorsQuery,
  ]);

  const isDummyQuery = useMemo(() => {
    const isJobStatesFilter = selectedFilter === SelectedFilter.JOB_STATES;
    const isSiteTypesFilter = selectedFilter === SelectedFilter.SITE_TYPES;

    return isJobStatesFilter || isSiteTypesFilter;
  }, [selectedFilter]);

  const searchValueChangeCallback = useMemo(() => {
    if (isDummyQuery) {
      return undefined;
    }

    return (value: string) => setSearchQuery((state) => ({ ...state, input: value }));
  }, [isDummyQuery]);

  const fetchMoreCallback = useMemo(() => {
    return activeQuery?.fetchMore;
  }, [activeQuery?.fetchMore]);

  const selectedOptions = useMemo(() => {
    if (!selectedFilter) {
      return [];
    }

    const key = ordersDispatchFilterKeyBySelectedFilter[selectedFilter];
    const options = ordersDispatchStore.filters[key];

    return options ?? [];
  }, [ordersDispatchStore.filters, selectedFilter]);

  const filterChipsData = useMemo(() => {
    const appliedFilters = _.pick(
      ordersDispatchStore.filters,
      Object.values(ordersDispatchFilterKeyBySelectedFilter),
    );

    const collectedData = Object.entries(appliedFilterChipsCollectedData).reduce(
      (obj, [key, collectedValues]) => {
        const filter = ordersDispatchFilterKeyBySelectedFilter[key as SelectedFilter];

        obj[filter] = collectedValues;

        return obj;
      },
      {} as AppliedFiltersChipsCollectedData,
    );

    return { appliedFilters, collectedData };
  }, [JSON.stringify(ordersDispatchStore.filters), appliedFilterChipsCollectedData]);

  const hasFiltersApplied = useMemo(() => {
    const { appliedFilters } = filterChipsData;

    return Object.values(appliedFilters).filter((value) => Boolean(value)).length > 0;
  }, [JSON.stringify(filterChipsData.appliedFilters)]);

  const handleSelectedOptionsChange = useCallback(
    (selectedOptions: string[]) => {
      if (selectedFilter) {
        const key = ordersDispatchFilterKeyBySelectedFilter[selectedFilter];
        const options = selectedOptions.length > 0 ? selectedOptions : undefined;

        ordersDispatchStore.setFilters({ [key]: options }, true);
      }
    },
    [selectedFilter],
  );

  const handleRemoveAllFilters = useCallback((filters: string | string[]) => {
    const filtersArray = Array.isArray(filters) ? filters : [filters];
    const filtersObject = Object.fromEntries(
      filtersArray.map((filter) => [filter, undefined]),
    );

    ordersDispatchStore.setFilters(filtersObject, true);
  }, []);

  const updateAvailableOptionsAndFilterChips = useCallback(
    (key: SelectedFilter, data: FilterMenuOption[]) => {
      updateAvailableOptions((state) => {
        return { ...state, [key]: _.uniqBy([...state[key], ...data], (i) => i.value) };
      });

      updateAppliedFilterChipsCollectedData(key, data);
    },
    [],
  );

  useEffect(() => {
    activeQuery?.fetch(searchQuery.debounced);
  }, [activeQuery?.fetch, searchQuery.debounced]);

  useEffect(() => {
    if (!isDummyQuery && selectedFilter && activeQuery?.data) {
      updateAvailableOptionsAndFilterChips(selectedFilter, activeQuery?.data);
    }
  }, [
    updateAvailableOptionsAndFilterChips,
    selectedFilter,
    activeQuery?.data,
    isDummyQuery,
  ]);

  useEffect(() => {
    debouncedSetSearchQuery(searchQuery.input);

    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [debouncedSetSearchQuery, searchQuery.input]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedFilter(undefined);
      setSearchQuery((state) => ({ ...state, input: '' }));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!selectedFilter) {
      customersQuery.clear();
      vendorsQuery.clear();
      dispatchNumbersQuery.clear();
      driversQuery.clear();
      dropOffSitesQuery.clear();
      pickUpSitesQuery.clear();
      projectsQuery.clear();
      projectExternalIdsQuery.clear();
      requestersQuery.clear();
    }
  }, [
    selectedFilter,
    customersQuery.clear,
    vendorsQuery.clear,
    dispatchNumbersQuery.clear,
    driversQuery.clear,
    dropOffSitesQuery.clear,
    pickUpSitesQuery.clear,
    projectsQuery.clear,
    projectExternalIdsQuery.clear,
    requestersQuery.clear,
  ]);

  return (
    <Box flex={1} display="flex" alignItems="center">
      <Menu
        sx={{ [`& .${paperClasses.root}`]: { width: '250px' } }}
        onOpenStateChanged={setIsOpen}
        menuTrigger={
          <SmallButton
            size="small"
            startIcon={<FilterList />}
            sx={{
              [`&.${buttonClasses.root}`]: {
                backgroundColor: 'white',
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                ...(hasFiltersApplied
                  ? {
                      borderBottomLeftRadius: theme.brandV2.borderRadius,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: theme.brandV2.borderRadius,
                      borderTopRightRadius: 0,
                    }
                  : { borderRadius: theme.brandV2.borderRadius }),
              },
            }}
          >
            {t('common.filters')}
          </SmallButton>
        }
      >
        {selectedFilter && (
          <FilterMenuItem
            focusSearchFieldOnMount
            searchValue={searchQuery.input}
            onSearchValueChange={searchValueChangeCallback}
            loadingReason={activeQuery?.loadingReason}
            selectAllOptionLabel={`${t('dispatch.dispatch_v2.filters.all_entities', { entity: t(`dispatch.dispatch_v2.filters.${_.snakeCase(selectedFilter)}`) })}`}
            options={activeQuery?.data ?? []}
            selectedOptions={selectedOptions}
            onSelectedOptionsChange={handleSelectedOptionsChange}
            onFetchMore={fetchMoreCallback}
          />
        )}

        {!selectedFilter &&
          Object.values(SelectedFilter).map((filter) => (
            <MenuItem key={filter} onClick={() => setSelectedFilter(filter)}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                width="100%"
              >
                <Typography color={theme.brandV2.colors.treadBlack} variant="subtitle2">
                  {t(`dispatch.dispatch_v2.filters.${_.snakeCase(filter)}`)}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  {(
                    ordersDispatchStore.filters[
                      ordersDispatchFilterKeyBySelectedFilter[filter]
                    ] ?? []
                  ).length > 0 && (
                    <Check
                      sx={{ fontSize: '16px', color: theme.brandV2.colors.treadOrange }}
                    />
                  )}

                  <ChevronRight sx={{ fontSize: '16px' }} />
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Menu>

      <AppliedFiltersChips
        {...filterChipsData}
        onRemoveFilter={handleRemoveAllFilters}
        onRemoveAllFilters={handleRemoveAllFilters}
      />
    </Box>
  );
});

export default OrdersDispatchFilters;
