import TrafficIcon from '@mui/icons-material/Traffic';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useEffect, useRef, useState } from 'react';

type TrafficLayerToggleProps = {
  map: google.maps.Map;
};

export const TrafficLayerToggle = ({ map }: TrafficLayerToggleProps) => {
  const [trafficLayerVisible, setTrafficLayerVisible] = useState(false);
  const trafficLayerRef = useRef<google.maps.TrafficLayer | null>(null);

  useEffect(() => {
    if (map && !trafficLayerRef.current) {
      trafficLayerRef.current = new google.maps.TrafficLayer();
    }

    if (trafficLayerRef.current) {
      trafficLayerVisible
        ? trafficLayerRef.current.setMap(map)
        : trafficLayerRef.current.setMap(null);
    }
  }, [trafficLayerVisible]);

  const onClickHandler = () => {
    setTrafficLayerVisible((prev) => !prev);
  };

  return (
    <>
      <Box mx={2}>
        <IconButton
          color={trafficLayerVisible ? 'primary' : 'inherit'}
          onClick={onClickHandler}
          sx={{
            borderRadius: '0',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
            },
            border: '2px solid',
            borderColor: trafficLayerVisible ? 'primary' : 'inherit',
            boxShadow: '0 2px 6px rgba(0,0,0,.3)',
          }}
        >
          <TrafficIcon />
        </IconButton>
      </Box>
    </>
  );
};
