import AddIcon from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { SxProps } from '@mui/system';
import styled from '@mui/system/styled';
import { AddOnRateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { PhaseSelection } from '~components/Order/PhaseSelection';
import { FeatureFlags } from '~constants/featureFlags';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { AddOn, AddOnCharge, useAddOns } from '~hooks/useAddOns';
import { useEquipment } from '~hooks/useEquipment';
import { Phase } from '~hooks/useProjects/models';
import { ResourceUsageLog } from '~hooks/useResourceUsageLogs/models';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { Nullable } from '~types/Nullable';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';
import { usePrevious } from '~utils/hooks/usePrevious';

import { AddOnSelectOption } from '../../DriverPay/AddOnChargesTableRows';
import { appendAddOnRateTypeToName, schemaHelpers } from '../../DriverPay/helpers';

export type Charge = {
  id: string;
  description: string;
  quantity: number;
  phase?: Nullable<Phase>;
  phaseLabel?: string;
};
interface ChargesTableProps {
  sx?: SxProps;
  isEditing: boolean;
  resourceUsageLogs: ResourceUsageLog[];
  addOns: AddOnCharge[];
  phasesOptions: Phase[];
}

const deriveChargeFromResourceUsageLog = (resourceUsageLog: ResourceUsageLog): Charge => {
  return {
    id: resourceUsageLog.id,
    description: resourceUsageLog.equipment
      ? ` ${resourceUsageLog.equipment.name} - ${resourceUsageLog.equipment.externalId || ''}`
      : resourceUsageLog.driver
        ? `${resourceUsageLog.driver.fullName}${resourceUsageLog.driver.companyShare ? '-' + resourceUsageLog.driver.companyShare.senderCompany.legalName : ''}`
        : '',

    quantity: resourceUsageLog.usageMinutes / 60,
    phase: resourceUsageLog.phase,
    phaseLabel: resourceUsageLog.phase
      ? `${resourceUsageLog.phase.name + ' (' + resourceUsageLog.phase.code + ')'}`
      : '-',
  };
};

const deriveChargeFromAddOn = (addon: AddOnCharge): Charge => {
  return {
    id: addon.id ?? '',
    description: `AddOn: ${addon.name}`,
    quantity: addon.addOnRateType === AddOnRateType.RATE_FOR_EACH ? addon.quantity : 0,
    phaseLabel: '-',
  };
};

const TableRowStyled = styled(TableRow)<TableRowProps>(() => ({
  '&:not(.MuiTableBody-root tr:last-child)': {
    borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
  },
}));

const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (propName) => propName !== 'isHeader',
})<TableCellProps & { isHeader?: boolean }>(({ isHeader }) => ({
  borderBottom: 0,
  color: theme.brandV2.colors.treadBlack,
  fontSize: '12px',
  fontWeight: isHeader ? 600 : 400,
  padding: theme.spacing(),
  whiteSpace: 'nowrap',
}));

const ResourceUsageLogToCharges = ({
  resourceUsageLogs,
  isEditing,
  phasesOptions,
}: {
  resourceUsageLogs: ResourceUsageLog[];
  isEditing: boolean;
  phasesOptions: Phase[];
}) => {
  const showPhaseColumn = useFeatureFlag({
    featureFlagKey: FeatureFlags.showPhaseColumninChargesTable,
  });
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, update, remove } = useFieldArray({
    control,
    name: 'resourceUsageLogs',
  });
  const { userStore, companyAssetsStore } = useStores();
  const { getEquipmentByCompanyId, isLoading } = useEquipment();
  const equipmentOptions = companyAssetsStore.equipment;

  const watchedResourceUsageLogs = useWatch({ control, name: 'resourceUsageLogs' });

  const handleRemoveAddOnClick = useCallback(
    (index: number) => {
      const resourceUsageLog = watchedResourceUsageLogs?.[index];

      if (resourceUsageLog?.id) {
        update(index, { ...resourceUsageLog, _destroy: 1 });
      } else {
        remove(index);
      }
    },
    [update, watchedResourceUsageLogs],
  );

  useEffect(() => {
    getEquipmentByCompanyId({ companyId: userStore.userCompany.id });
  }, [userStore.userCompany.id]);

  const charges = useMemo(() => {
    return resourceUsageLogs.map((resourceUsageLog) => {
      return deriveChargeFromResourceUsageLog(resourceUsageLog);
    });
  }, [resourceUsageLogs]);

  if (isEditing) {
    return fields?.map((resource, index) => {
      const isDeleted = watchedResourceUsageLogs?.[index]?._destroy === 1;

      if (isDeleted) {
        return null;
      }
      const isEquipment = !!watchedResourceUsageLogs?.[index].equipment?.id;

      return (
        <TableRowStyled key={resource.id}>
          <TableCellStyled>{isEquipment ? 'Equipment' : 'Driver'}</TableCellStyled>

          <TableCellStyled>
            {/* {!isEquipment ? ( */}
            <OverflowAwareText>{charges[index]?.description}</OverflowAwareText>
            {/* ) : (
              <AutocompleteFormField
                clearable
                control={control}
                loading={isLoading}
                errors={errors}
                getLabel={(item) => item.name}
                getValue={(item) => item.id}
                isRequired
                list={equipmentOptions}
                name={`resourceUsageLogs[${index}].equipment`}
                sx={{
                  fontSize: '12px',
                  '& .MuiAutocomplete-inputRoot': { fontSize: '12px', width: '100%' },
                  '& .MuiAutocomplete-endAdornment': {
                    right: '5px !important',
                    top: 'calc(50% - 13px)',
                    width: 'fit-content',
                  },
                }}
              />
            )} */}
          </TableCellStyled>
          <TableCellStyled>
            <TextFormField
              control={control}
              errors={errors}
              name={`resourceUsageLogs[${index}].quantity`}
            />
          </TableCellStyled>
          {showPhaseColumn ? (
            <TableCellStyled>
              {phasesOptions.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <PhaseSelection
                    phaseOptions={phasesOptions.map((phase) => ({
                      id: phase.id,
                      name: phase.name,
                      code: phase.code,
                    }))}
                    canEdit={false}
                    hasLabels={false}
                    canAdd={false}
                    selectedPhase={watchedResourceUsageLogs[index].phase}
                    onChange={(phase) => {
                      update(index, { ...watchedResourceUsageLogs[index], phase: phase });
                    }}
                    inputStyle={{
                      width: '100%',
                    }}
                  />
                </Box>
              ) : (
                <OverflowAwareText>{charges[index]?.phaseLabel}</OverflowAwareText>
              )}
            </TableCellStyled>
          ) : (
            <TableCellStyled />
          )}

          <TableCellStyled />
          <TableCellStyled
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {!isEquipment ? null : (
              <IconButton
                size={'medium'}
                onClick={() => {
                  handleRemoveAddOnClick(index);
                }}
                sx={{
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <Close
                  fontSize={'medium'}
                  sx={{ color: theme.brandV2.colors.treadGray3 }}
                />
              </IconButton>
            )}
          </TableCellStyled>
        </TableRowStyled>
      );
    });
  }

  return charges?.map((charge, index) => {
    const isEquipment = resourceUsageLogs[index].equipment?.id;
    return (
      <TableRowStyled key={charge.id}>
        <TableCellStyled>
          {' '}
          <OverflowAwareText>
            {isEquipment
              ? t('approvals.driver_pay.charges_table.equipment')
              : t('approvals.driver_pay.charges_table.driver')}{' '}
          </OverflowAwareText>
        </TableCellStyled>

        <TableCellStyled>
          <OverflowAwareText>{charge.description}</OverflowAwareText>
        </TableCellStyled>
        <TableCellStyled>
          <OverflowAwareText>{charge.quantity.toFixed(2)}</OverflowAwareText>
        </TableCellStyled>
        {showPhaseColumn ? (
          <TableCellStyled>
            <OverflowAwareText>{charge?.phaseLabel}</OverflowAwareText>
          </TableCellStyled>
        ) : (
          <TableCellStyled />
        )}
      </TableRowStyled>
    );
  });
};

const AddOnsToCharges = ({
  addOns,
  isEditing,
}: {
  addOns: AddOnCharge[];
  isEditing: boolean;
}) => {
  const showPhaseColumn = useFeatureFlag({
    featureFlagKey: FeatureFlags.showPhaseColumninChargesTable,
  });
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'addOnCharges',
  });
  const { addOnsStore, userStore } = useStores();
  const companyId = userStore.userCompany.id;

  const watchedAddOnCharges = useWatch({ control, name: 'addOnCharges' });
  const previousWatchedAddOnCharges = usePrevious(watchedAddOnCharges);

  const { getAllAddOns } = useAddOns();

  const handleAddAddOnClick = useCallback(() => {
    const addOnCharge = new AddOnCharge(
      null,
      '',
      0,
      0,
      0,
      0,
      AddOnRateType.RATE_FOR_EACH,
      null,
      { id: '' },
    );

    append(schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
  }, [append]);

  const handleRemoveAddOnClick = useCallback(
    (index: number) => {
      const addOnChargeToDelete = watchedAddOnCharges?.[index];

      if (addOnChargeToDelete?.id) {
        update(index, { ...addOnChargeToDelete, _destroy: 1 });
      } else {
        remove(index);
      }
    },
    [update, watchedAddOnCharges],
  );

  const charges = useMemo(() => {
    return addOns.map((addOn) => {
      return deriveChargeFromAddOn(addOn);
    });
  }, [addOns]);

  const addOnSelectOptions = useMemo(() => {
    const options = addOnsStore.addOns.reduce((options, addOn) => {
      options.push({
        id: String(addOn.id),
        name: appendAddOnRateTypeToName(addOn.name, addOn.addOnRateType),
      });

      return options;
    }, [] as AddOnSelectOption[]);

    return options;
  }, [addOnsStore.addOns]);

  const addOnByAddOnId = useMemo(() => {
    const addOnByAddOnId = addOnsStore.addOns.reduce(
      (acc, addOn) => {
        acc[addOn.id ?? ''] = addOn;
        return acc;
      },
      {} as Record<string, AddOn>,
    );
    return addOnByAddOnId;
  }, [addOnsStore.addOns]);

  useEffect(() => {
    if (!addOnsStore.addOns.length) {
      getAllAddOns(companyId);
    }
  }, [companyId, addOnsStore.addOns]);

  useEffect(() => {
    if (!watchedAddOnCharges || !previousWatchedAddOnCharges) {
      return;
    }
    watchedAddOnCharges.forEach(
      (current: { addOn: { id: any }; id: Nullable<string> }, index: number) => {
        const currentAddOnId = current.addOn?.id;
        const previousAddOnIn = previousWatchedAddOnCharges[index]?.addOn?.id;
        const selectedAddOnDidChange =
          currentAddOnId && currentAddOnId !== previousAddOnIn;
        // If the selected add-on did change then use the newly selected add-on values
        // As the default values for the add-on charge DTO
        if (selectedAddOnDidChange && addOnByAddOnId[currentAddOnId]) {
          const newlySelectedAddOn = addOnByAddOnId[currentAddOnId];
          const isRateForEach =
            newlySelectedAddOn.addOnRateType === AddOnRateType.RATE_FOR_EACH;
          const addOnCharge = new AddOnCharge(
            current.id,
            newlySelectedAddOn.name,
            newlySelectedAddOn.percentage,
            isRateForEach ? newlySelectedAddOn.quantity : 0,
            newlySelectedAddOn.rate,
            0,
            newlySelectedAddOn.addOnRateType,
            newlySelectedAddOn.externalId,
            { id: currentAddOnId },
          );
          update(index, schemaHelpers.addOnChargeToAddOnChargeDTO(addOnCharge));
          clearErrors([`addOnCharges.${index}.quantity`]);
          clearErrors([`addOnCharges.${index}.addOn`]);
        }
      },
    );
  }, [
    watchedAddOnCharges,
    previousWatchedAddOnCharges,
    update,
    clearErrors,
    addOnByAddOnId,
  ]);

  if (isEditing) {
    return (
      <>
        {fields.map((addOn, index) => {
          const isDeleted = watchedAddOnCharges?.[index]?._destroy === 1;

          if (isDeleted) {
            return null;
          }

          const editedAddOnCharge = watchedAddOnCharges?.[index];
          const isRateForEach =
            editedAddOnCharge?.addOnRateType === AddOnRateType.RATE_FOR_EACH;

          return (
            <TableRowStyled key={addOn.id}>
              <TableCellStyled>
                {' '}
                <OverflowAwareText>
                  {t('approvals.driver_pay.charges_table.add_on')}{' '}
                </OverflowAwareText>
              </TableCellStyled>
              <TableCellStyled>
                <AutocompleteFormField
                  clearable
                  control={control}
                  errors={errors}
                  getLabel={(item) => item.name}
                  getValue={(item) => item.id}
                  list={addOnSelectOptions}
                  name={`addOnCharges[${index}].addOn`}
                  sx={{
                    fontSize: '12px',
                    '& .MuiAutocomplete-inputRoot': { fontSize: '12px', width: '100%' },
                    '& .MuiAutocomplete-endAdornment': {
                      right: '5px !important',
                      top: 'calc(50% - 13px)',
                      width: 'fit-content',
                    },
                  }}
                />
              </TableCellStyled>
              <TableCellStyled>
                {isRateForEach && (
                  <TextFormField
                    control={control}
                    errors={errors}
                    name={`addOnCharges[${index}].quantity`}
                  />
                )}
              </TableCellStyled>
              <TableCellStyled />
              <TableCellStyled />
              <TableCellStyled
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  size={'medium'}
                  onClick={() => handleRemoveAddOnClick(index)}
                  sx={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  <Close
                    fontSize={'medium'}
                    sx={{ color: theme.brandV2.colors.treadGray3 }}
                  />
                </IconButton>
              </TableCellStyled>
            </TableRowStyled>
          );
        })}

        <Box
          sx={{
            p: 1.5,
            pl: 1,
          }}
        >
          <SmallButton
            color="brandV2Yellow"
            sx={{
              '&.MuiButtonBase-root': {
                height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
              },
            }}
            onClick={() => {
              handleAddAddOnClick();
            }}
            startIcon={<AddIcon />}
          >
            {t('approvals.driver_pay.settlements.add_on')}
          </SmallButton>
        </Box>
      </>
    );
  }

  return charges?.map((charge, index) => {
    const isRateForEach = addOns[index].addOnRateType === AddOnRateType.RATE_FOR_EACH;
    return (
      <TableRowStyled key={charge.id}>
        <TableCellStyled>
          {' '}
          <OverflowAwareText>
            {t('approvals.driver_pay.charges_table.add_on')}{' '}
          </OverflowAwareText>
        </TableCellStyled>

        <TableCellStyled>
          <OverflowAwareText>{charge.description}</OverflowAwareText>
        </TableCellStyled>
        <TableCellStyled>
          {isRateForEach ? (
            <OverflowAwareText>{charge.quantity.toFixed(2)}</OverflowAwareText>
          ) : null}
        </TableCellStyled>
        {showPhaseColumn ? (
          <TableCellStyled>
            <OverflowAwareText>{charge?.phaseLabel}</OverflowAwareText>
          </TableCellStyled>
        ) : (
          <TableCellStyled />
        )}
      </TableRowStyled>
    );
  });
};

export default function ChargesTable({
  sx,
  isEditing,
  resourceUsageLogs,
  addOns,
  phasesOptions,
}: ChargesTableProps) {
  const showTableHeader = isEditing || resourceUsageLogs.length > 0 || addOns.length > 0;

  const showPhaseColumn = useFeatureFlag({
    featureFlagKey: FeatureFlags.showPhaseColumninChargesTable,
  });

  return (
    showTableHeader && (
      <TableContainer sx={{ px: 0.5, ...sx }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRowStyled>
              <TableCellStyled sx={{ width: '15%', fontWeight: 600 }}>
                {t('common.category')}
              </TableCellStyled>
              <TableCellStyled sx={{ width: '25%', fontWeight: 600 }}>
                {t('common.description')}
              </TableCellStyled>
              <TableCellStyled sx={{ width: '11%', fontWeight: 600 }}>
                {t('approvals.driver_day.add_on_charges_table.qty_header')}
              </TableCellStyled>
              {showPhaseColumn ? (
                <TableCellStyled sx={{ width: '42%', fontWeight: 600 }}>
                  {t('common.phase')}
                </TableCellStyled>
              ) : null}
            </TableRowStyled>
          </TableHead>

          <TableBody>
            <ResourceUsageLogToCharges
              resourceUsageLogs={resourceUsageLogs}
              isEditing={isEditing}
              phasesOptions={phasesOptions}
            />
            <AddOnsToCharges addOns={addOns} isEditing={isEditing} />
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
