import {
  getV1NextBillionAssetLocationHistoriesLatestLocations,
  NextBillionAssetLocations_Read,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { extractPagination, PaginationLink, PaginationQuery } from '~services/pagination';
import { Nullable } from '~types/Nullable';

import { NextBillionAssetLocation } from './models';
interface GetLatestLocationsParams {
  order_id?: string;
  job_id?: string;
  limit?: number;
  linkType?: PaginationLink;
  link: Nullable<string>;
}

export const useNextBillionAssetLocationHistories = () => {
  const [isLoading, setIsLoading] = useState(false);

  const getLatestLocations = async ({
    order_id,
    job_id,
    limit,
    linkType,
    link,
  }: GetLatestLocationsParams) => {
    setIsLoading(true);
    try {
      const response = await getV1NextBillionAssetLocationHistoriesLatestLocations({
        query: {
          'page[limit]': limit || 100,
          ...(linkType && link ? { [`page[${linkType}]`]: link } : {}),
          ...(order_id ? { 'filter[order_id]': order_id } : {}),
          ...(job_id ? { 'filter[job_id]': job_id } : {}),
        },
      });
      setIsLoading(false);
      return {
        data: response.data.data.map(NextBillionAssetLocation.parse),
        pagination: extractPagination(response),
      };
    } catch (error) {
      setIsLoading(false);
      connection.handleRequestError(
        error,
        t('error_messages.next_billion.failed_to_fetch') as string,
      );
    }
  };

  return {
    isLoading,
    getLatestLocations,
  };
};
