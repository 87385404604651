import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t as $t } from 'i18next';
import React from 'react';

import { DataValueItem } from '~components/Common/DataValueItem';
import { GroupTitle } from '~components/Common/GroupTitle';
import { SystemRoles } from '~constants/enums';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import { splitStringBySentenceChange } from '~utils/utilFunctions';

interface Props {
  details: Job | Order;
  sx?: SxProps<Theme>;
}

const isJob = (details: Job | Order): details is Job => {
  return (details as Job).vendorJobAssignment !== undefined;
};

const isOrder = (details: Job | Order): details is Order => {
  return (details as Order).vendorRateType !== undefined;
};

const getRateInfo = (
  details: Job | Order,
  type: 'vendor' | 'customer' | 'driver',
): { rateName: string; rateValue: number | null } => {
  let rateName = '-';
  let rateValue: number | null = null;

  if (isJob(details)) {
    if (type === 'vendor') {
      rateName =
        splitStringBySentenceChange(
          details.vendorJobAssignment?.rateType?.replace(/^Rate/, '') ?? '',
        ) || '-';
      rateValue = details.vendorJobAssignment?.rateValue ?? null;
    } else if (type === 'customer') {
      rateName =
        splitStringBySentenceChange(
          details.customerJobAssignment?.rateType?.replace(/^Rate/, '') ?? '',
        ) || '-';
      rateValue = details.customerJobAssignment?.rateValue ?? null;
    } else if (type === 'driver') {
      rateName =
        splitStringBySentenceChange(
          details.driverRate?.type?.replace(/^Rate/, '') ?? '',
        ) || '-';
      rateValue = details.driverRate?.rate ?? null;
    }
  } else if (isOrder(details)) {
    if (type === 'vendor') {
      rateName =
        splitStringBySentenceChange(details.vendorRateType?.replace(/^Rate/, '') ?? '') ||
        '-';
      rateValue = details.vendorRateValue ?? null;
    } else if (type === 'customer') {
      rateName =
        splitStringBySentenceChange(
          details.customerRateType?.replace(/^Rate/, '') ?? '',
        ) || '-';
      rateValue = details.customerRateValue ?? null;
    } else if (type === 'driver') {
      rateName =
        splitStringBySentenceChange(details.driverRateType?.replace(/^Rate/, '') ?? '') ||
        '-';
      rateValue = details.driverRateValue ?? null;
    }
  }

  return { rateName, rateValue };
};

const BillingInfo = ({ details, sx = {} }: Props) => {
  const { userStore } = useStores();
  const { currencyFormatter } = useCompanyCurrency();
  const isOrderType = isOrder(details);
  const userRoles = userStore.user.userRoles;
  const userIsOnlyDriverOrForeman = userRoles?.every(
    (role) => role.name === SystemRoles.FOREMAN || role.name === SystemRoles.DRIVER,
  );

  const renderRateSection = (
    label: string,
    rateValue: number | null,
    rateName: string,
    isOrder: boolean,
  ) => {
    // Always display rates for orders; Only display active rates for jobs
    if (!isOrder && !rateValue) return null;

    return (
      <Grid container spacing={2} sx={{ mb: 1.25 }}>
        <Grid item xs={6}>
          <DataValueItem
            label={$t(label)}
            value={rateValue ? currencyFormatter(rateValue) : 'N/A'}
          />
        </Grid>
        <Grid item xs={6}>
          <DataValueItem label={$t('form_fields.rate_type')} value={rateName} />
        </Grid>
      </Grid>
    );
  };

  const vendorRateInfo = getRateInfo(details, 'vendor');
  const customerRateInfo = getRateInfo(details, 'customer');
  const driverRateInfo = getRateInfo(details, 'driver');

  return (
    <Box sx={{ ...sx }}>
      <GroupTitle>{$t('form_fields.billing_information')}</GroupTitle>

      {/* Customer Rate */}
      {!userIsOnlyDriverOrForeman &&
        renderRateSection(
          'approvals.customer_rate_card',
          customerRateInfo.rateValue,
          customerRateInfo.rateName,
          isOrderType,
        )}

      {/* Vendor Rate */}
      {!userIsOnlyDriverOrForeman &&
        renderRateSection(
          'approvals.vendor_rate_card',
          vendorRateInfo.rateValue,
          vendorRateInfo.rateName,
          isOrderType,
        )}

      {/* Internal Driver Rate */}
      {renderRateSection(
        'approvals.internal_driver_rate_card',
        driverRateInfo.rateValue,
        driverRateInfo.rateName,
        isOrderType,
      )}
    </Box>
  );
};

export { BillingInfo };
