import { cloneDeep, uniqBy } from 'lodash';
import { makeObservable, observable, reaction, runInAction } from 'mobx';

import { Access } from '~hooks/useAccess';
import { Company, CompanyBasic } from '~hooks/useCompany';
import { Permission } from '~hooks/usePermissions';
import { RoleItem, User } from '~hooks/useUsers';
import { Pagination, PaginationLink } from '~services/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

const DEFAULT_PAGINATION_STATE: Pagination = { limit: 100, page: 1 };

class UserStore {
  user: User = {} as User;
  users: User[] = [];
  userRoles: RoleItem[] = [];
  userCompany: Company = {} as Company;
  userCompanies: CompanyBasic[] = [];
  userCompaniesPagination: Pagination = DEFAULT_PAGINATION_STATE;
  currentCompanies: CompanyBasic[] = [];
  hasSetInitialCompany = false;
  isCurrentCompanyActive = false;
  userManagementPagination: Pagination = DEFAULT_PAGINATION_STATE;
  userPermissions: Permission[] = [];
  userAccess: Access[] = [];
  constructor(readonly rootStore: RootStore) {
    makeObservable(this, {
      user: observable,
      users: observable,
      userRoles: observable,
      userCompany: observable,
      userCompanies: observable,
      currentCompanies: observable,
      userPermissions: observable,
      userAccess: observable,
      isCurrentCompanyActive: observable,
    });
    this.rootStore = rootStore;

    reaction(
      () => this.userCompanies,
      (companies) => {
        if (companies.length > 0 && !this.hasSetInitialCompany) {
          runInAction(() => {
            this.currentCompanies = [companies[0]]; // The user's company should be the default current company
            this.hasSetInitialCompany = true;
          });
        }
      },
    );

    reaction(
      () => [this.userCompany, this.currentCompanies],
      () => {
        runInAction(() => {
          this.isCurrentCompanyActive =
            this.userCompany.id === this.currentCompanies[0]?.id;
        });
      },
    );
  }

  clearUser() {
    runInAction(() => {
      this.user = {} as User;
    });
  }

  setUserManagementPagination(pagination: Pagination) {
    runInAction(() => {
      this.userManagementPagination = {
        ...this.userManagementPagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  updateUserManagementPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.userManagementPagination = {
        ...this.userManagementPagination,
        page: getPageNumber(this.userManagementPagination.page, link),
      };
    });
  }
  setUserCompany(company: Company) {
    runInAction(() => {
      this.userCompany = company;
    });
  }
  updateUserCompany(company: Company) {
    runInAction(() => {
      this.setUserCompany(company);
    });
  }
  setUserCompanies(companies: CompanyBasic[], pagination?: Pagination) {
    runInAction(() => {
      this.userCompanies = uniqBy(
        [...this.userCompanies, ...companies],
        (company) => company.id,
      );
      this.userCompaniesPagination = {
        limit: DEFAULT_PAGINATION_STATE.limit,
        after: pagination?.after ?? '',
        page: DEFAULT_PAGINATION_STATE.page,
      };
    });
  }
  clearUserCompanies() {
    runInAction(() => {
      this.userCompanies = [];
      this.userCompaniesPagination = DEFAULT_PAGINATION_STATE;
      this.hasSetInitialCompany = false;
    });
  }
  setCurrentCompanies(companies: CompanyBasic[]) {
    runInAction(() => {
      this.currentCompanies = [...companies];
    });
  }
  // Public get userInitials(): string {
  //   Return `${this.user?.firstName?.charAt(0)}${this.user?.lastName?.charAt(0)}` || '';
  // }
  setUserRoles(userRoles: RoleItem[]) {
    runInAction(() => {
      this.userRoles = [...userRoles];
    });
  }

  setUsers(users: User[]) {
    runInAction(() => {
      this.users = [...users];
    });
  }

  setUser(user: User) {
    runInAction(() => {
      this.user = cloneDeep(user);
    });
  }

  findUser(userId: string) {
    runInAction(() => {
      return this.users.find((user) => user.id === userId);
    });
  }

  addUser(user: User) {
    this.users = [...this.users, user];
  }
  updateUser(user: User) {
    runInAction(() => {
      this.users = this.users.map((u) => (u.id === user.id ? user : u));

      // Update if it's current user
      if (user.id === this.user.id) {
        this.setUser(user);
      }
    });
  }

  deleteUser(id: string) {
    runInAction(() => {
      this.users = this.users.filter((u) => u.id !== id);
    });
  }
  setPermissions(permissions: Permission[]) {
    runInAction(() => {
      this.userPermissions = [...permissions];
    });
  }
  getPermissions() {
    return {
      // User Permissions
      canEditUser: this.userPermissions.some((perm) => perm.name === 'edit_user'),
      canDeleteUser: this.userPermissions.some((perm) => perm.name === 'delete_user'),
      canCreateUser: this.userPermissions.some((perm) => perm.name === 'create_user'),

      // Project Permissions
      canEditProject: this.userPermissions.some((perm) => perm.name === 'edit_project'),
      canDeleteProject: this.userPermissions.some(
        (perm) => perm.name === 'delete_project',
      ),
      canCreateProject: this.userPermissions.some(
        (perm) => perm.name === 'create_project',
      ),

      // Order Permissions
      canEditOrder: this.userPermissions.some((perm) => perm.name === 'edit_order'),
      canDeleteOrder: this.userPermissions.some((perm) => perm.name === 'delete_order'),
      canCreateOrder: this.userPermissions.some((perm) => perm.name === 'create_order'),

      // Site Permissions
      canEditSite: this.userPermissions.some((perm) => perm.name === 'edit_site'),
      canDeleteSite: this.userPermissions.some((perm) => perm.name === 'delete_site'),
      canCreateSite: this.userPermissions.some((perm) => perm.name === 'create_site'),

      // Account Permissions
      canEditAccount: this.userPermissions.some((perm) => perm.name === 'edit_account'),
      canDeleteAccount: this.userPermissions.some(
        (perm) => perm.name === 'delete_account',
      ),
      canCreateAccount: this.userPermissions.some(
        (perm) => perm.name === 'create_account',
      ),
      //Rates Permissions
      canEditRate: this.userPermissions.some((perm) => perm.name === 'edit_rate'),
      canDeleteRate: this.userPermissions.some((perm) => perm.name === 'delete_rate'),
      canCreateRate: this.userPermissions.some((perm) => perm.name === 'create_rate'),
      //Company Permissions
      canEditCompany: this.userPermissions.some((perm) => perm.name === 'edit_company'),
      canDeleteCompany: this.userPermissions.some(
        (perm) => perm.name === 'delete_company',
      ),
      canCreateCompany: this.userPermissions.some(
        (perm) => perm.name === 'create_company',
      ),
      canConnectCompany: this.userPermissions.some(
        (perm) => perm.name === 'connect_companies',
      ),

      // Job permissions
      canCancelJob: this.userPermissions.some((perm) => perm.name === 'cancel_job'),
      canCreateJob: this.userPermissions.some((perm) => perm.name === 'create_job'),
      canDeleteJob: this.userPermissions.some((perm) => perm.name === 'delete_job'),
      canEditJob: this.userPermissions.some((perm) => perm.name === 'edit_job'),

      // Loads permissions
      canCreateLoad: this.userPermissions.some((perm) => perm.name === 'create_load'),
      canEditLoad: this.userPermissions.some((perm) => perm.name === 'edit_load'),
      canDeleteLoad: this.userPermissions.some((perm) => perm.name === 'delete_load'),

      // Equipment Permissions
      canEditEquipment: this.userPermissions.some(
        (perm) => perm.name === 'edit_equipment',
      ),
      canDeleteEquipment: this.userPermissions.some(
        (perm) => perm.name === 'delete_equipment',
      ),
      canCreateEquipment: this.userPermissions.some(
        (perm) => perm.name === 'create_equipment',
      ),

      // Loads permissions

      // Department Permissions
      canEditDepartment: this.userPermissions.some(
        (perm) => perm.name === 'edit_department',
      ),
      canDeleteDepartment: this.userPermissions.some(
        (perm) => perm.name === 'delete_department',
      ),
      canCreateDepartment: this.userPermissions.some(
        (perm) => perm.name === 'create_department',
      ),

      // Equipment Type Permissions
      canEditEquipmentType: this.userPermissions.some(
        (perm) => perm.name === 'edit_equipment_type',
      ),
      canDeleteEquipmentType: this.userPermissions.some(
        (perm) => perm.name === 'delete_equipment_type',
      ),
      canCreateEquipmentType: this.userPermissions.some(
        (perm) => perm.name === 'create_equipment_type',
      ),

      // Service Class Permissions
      canEditServiceClass: this.userPermissions.some(
        (perm) => perm.name === 'edit_service_class',
      ),
      canDeleteServiceClass: this.userPermissions.some(
        (perm) => perm.name === 'delete_service_class',
      ),
      canCreateServiceClass: this.userPermissions.some(
        (perm) => perm.name === 'create_service_class',
      ),

      // Telematics Permissions
      canEditTelematicsRemoteVehicle: this.userPermissions.some(
        (perm) => perm.name === 'edit_telematics_remote_vehicle',
      ),

      // Accounting Integration
      canManageAccountingIntegration: this.userPermissions.some(
        (perm) => perm.name === 'manage_accounting_integration',
      ),

      canImpersonate: this.userPermissions.some((perm) => perm.name === 'impersonate'),
    } as const;
  }
  setAccess(access: Access[]) {
    runInAction(() => {
      this.userAccess = [...access];
    });
  }
  getAccess() {
    return {
      manageCompanies: this.userAccess.some(
        (access) => access.name === 'manage_companies',
      ),
      manageUser: this.userAccess.some((access) => access.name === 'manage_users'),
      manageAccounts: this.userAccess.some((access) => access.name === 'manage_accounts'),
      manageEquipment: this.userAccess.some((perm) => perm.name === 'manage_equipment'),
      manageMaterials: this.userAccess.some((perm) => perm.name === 'manage_materials'),
      manageSites: this.userAccess.some((perm) => perm.name === 'manage_sites'),
      manageRates: this.userAccess.some((perm) => perm.name === 'manage_rates'),
      manageDepartments: this.userAccess.some(
        (perm) => perm.name === 'manage_departments',
      ),
      manageServiceClasses: this.userAccess.some(
        (perm) => perm.name === 'manage_service_classes',
      ),
      manageTelematics: this.userAccess.some((perm) => perm.name === 'manage_telematics'),
    };
  }
}

export default UserStore;
