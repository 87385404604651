import { Subscription } from '@rails/actioncable';
import { NextBillionAssetLocations_LatestLocation_Read } from '@treadinc/horizon-api-spec';
import { useState } from 'react';

import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import connection from '~services/connectionModule';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';

interface SubscribeToLastLocationUpdatesProps {
  onMessageCallBack: (data: NextBillionAssetLocation) => void;
}

export const useTruckLocations = () => {
  const [subscription, setSubscription] = useState<Subscription>();
  const { userStore, liveMapStore } = useStores();

  const subscribeToLastLocationUpdates = ({
    onMessageCallBack,
  }: SubscribeToLastLocationUpdatesProps) => {
    const consumer = connection.realTimeConnection;
    const channel = realTimeChannels.NextBillionLastLocationChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = consumer?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = consumer?.subscriptions?.create?.(
        {
          channel: channel,
          company_id: userStore.userCompany?.id,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          connected: () => () => {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          disconnected: () => () => {},
          received: ({
            data,
          }: {
            data: NextBillionAssetLocations_LatestLocation_Read;
          }) => {
            const locationData = NextBillionAssetLocation.parse(data);
            liveMapStore.addTruckLocation(locationData);
            onMessageCallBack(locationData);
          },
        },
      );
    }

    setSubscription(subscription);
  };

  return { subscription, subscribeToLastLocationUpdates };
};
